import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "../TablePagination";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import api from "../../helper/api";
import SingleError from "../../helper/SingleError";
import { getServerURL } from "../../helper/envConfig";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
const requireField = ["title", "descriptions"];

const HelpSupportManagement = ({ temp, search, searchTemp, setCountHelp }) => {

  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [loading, setLoading] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)
  const [errors, setErrors] = useState({})
  const [mainLoader, setMainLoader] = useState(true);

  const [helpSupportData, setHelpSupportData] = useState()
  const [editData, setEditData] = useState({})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getWorkingList();
  }, []);

  useEffect(() => {
    getWorkingList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getWorkingList();

    }
    if (searchTemp && !search) {
      getWorkingList();

    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const getWorkingList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}ui-page-manage`,
          { action: "help-support-list" }
        )
        .then(async (res) => {
          // console.log(res);
          setMainLoader(false);
          if (res?.data?.success === true) {
            if (res?.data?.data?.length) setCountHelp(1)
            setHelpSupportData(res?.data?.data);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setHelpSupportData([]);
            if (Boolean(page != 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
      setMainLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editData, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }

    if (name === "address_title" || name === "address_description" || name === "address_contact") {
      setEditData((prevValues) => ({
        ...prevValues,
        address: [
          {
            ...prevValues.address[0], // Spread the existing address object
            title: name === "address_title" ? value : prevValues?.address[0]?.title ? prevValues?.address[0]?.title : "",
            description: name === "address_description" ? value : prevValues?.address[0]?.description ? prevValues?.address[0]?.description : "",
            contact: name === "address_contact" ? value : prevValues?.address[0]?.contact ? prevValues?.address[0]?.contact : "",
          }
        ]
      }));
    } else {
      setEditData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (editData?.address && editData?.address[0]?.contact) {
      if (!/^\+?\d+$/.test(editData?.address[0]?.contact)) {
        setErrors((prevValues) => ({
          ...prevValues,
          contact: "Please valid contact number",
        }));
      }
    }

  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      handleSubmit();
    }
  };

  const appendObjectToFormData = (obj, formData, prefix = '') => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === 'object') {
          appendObjectToFormData(obj[key], formData, propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  };

  const handleSubmit = () => {
    // setTemp(false);
    const updatedValues = { ...editData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (editData?.address && editData?.address[0]?.contact) {
      if (!/^\+?\d+$/.test(editData?.address[0]?.contact)) {
        setErrors((prevValues) => ({
          ...prevValues,
          contact: "Please valid contact number",
        }));
      }
    }

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors)?.length === 0 && Object.keys(errors)?.length === 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append("action", "help-support-update");
      for (const key in editData) {
        if (editData.hasOwnProperty(key)) {
          if (typeof editData[key] === 'object') {
            // If the property is an object, stringify it before appending
            appendObjectToFormData(editData[key], formData, key);
          } else {
            formData.append(key, editData[key]);
          }
        }
      }

      try {
        api
          .postWithToken(
            `${serverURL}ui-page-manage`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              editModelCloseFun();
              getWorkingList()
              setEditData({})
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Help & support detail updated successfully!");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {Boolean(mainLoader) ?
        <Loader /> :
        Boolean(helpSupportData?.length) ?
          helpSupportData?.map((item, i) =>
            <div className="card p-3 my-3">
              <div className="d-flex justify-content-between align-items-center gap-2">
                <div className="explore-head col-10">
                  {item?.title}
                </div>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    editModelOpenFun();
                    setEditData({ id: item?._id, title: item?.title, description: item?.description, address: item?.address })
                  }}
                >
                  <img src="/images/svg/edit.svg" title="Edit" />
                </button>
              </div>
              <div className="explore-description col-10">
                {item?.description}
              </div>

              {Boolean(item?.address?.length) &&
                <>
                  <div className="explore-head col-10 mt-4">
                    Address
                  </div>
                  {item?.address?.map((subItem, i) =>
                    <div className="d-flex ">
                      <div className="my-4">
                        {Boolean(subItem?.title) &&
                          <tr>
                            <td className="help-label">Title :</td>
                            &nbsp;
                            <td className="explore-description fullTextShow">
                              {subItem?.title}
                            </td>
                          </tr>
                        }
                        {Boolean(subItem?.description) &&
                          <tr>
                            <td className="help-label">Description :</td>
                            &nbsp;
                            <td className="explore-description fullTextShow">
                              {subItem?.description}
                            </td>
                          </tr>
                        }
                        {Boolean(subItem?.contact) &&
                          <tr>
                            <td className="help-label">Contact No :</td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <td className="explore-description fullTextShow">
                              {subItem?.contact}
                            </td>
                          </tr>
                        }
                      </div>
                    </div>
                  )}
                </>
              }
            </div>
          )
          :
          <h4 className="text-center p-5">No Records Founds.</h4>
      }


      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Working</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="title"
                value={editData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="description"
                value={editData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

          </Row>
          <p className="help-label">Address</p>
          <Row>
            <Col md={12}>
              <label>
                Title
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="address_title"
                value={editData?.address && editData?.address[0]?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label>
                Description
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="address_description"
                value={editData?.address && editData?.address[0]?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
            <Col md={12}>
              <label>
                Contact No.
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="address_contact"
                value={editData?.address && editData?.address[0]?.contact || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.contact} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                handleSubmit();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpSupportManagement;
