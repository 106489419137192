import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TrackingContent from "../components/TrackingContent";
import Main from "../hoc/Main";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import SingleError from "../helper/SingleError";
const requireField = ["image", "title", "type","subscriptionStatus"];
const requireField1 = ["image", "title", "type", "subType","subscriptionStatus"];

const TrackingManagement = () => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({description:""});
    setSelectedImage(null);
    setAddModel(true);
  };
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("");
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, state?.type === "tracking" ? requireField1 : requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, state?.type === "tracking" ? requireField1 : requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const addTracking = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, state?.type === "tracking" ? requireField1 : requireField);
    setErrors(validationErrors);

    if (state?.description && state?.description?.trim()?.length === 0) {
      validationErrors.description="Description cannot be contain only spaces.";
    }

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    console.log(validationErrors,errors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      // formData.append("action", "create");
      formData.append("title", state?.title);
      formData.append("description", state?.description);
      formData.append("image", state?.image);
      formData.append("type", state?.type);
      formData.append("subType", state?.subType);
      formData.append("subscriptionStatus", state?.subscriptionStatus);
      try {
        api
          .postWithToken(
            `${serverURL}tracking-add`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModelCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.data?.message === "string")
                toast.success(res?.data?.data?.message);
              else toast.success("Tracking created successfully");
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  const disableAutocomplete = (e) => {
    e.target.setAttribute("autocomplete", "off");
  };

  return (
    <>

      {/* <Button variant="primary" onClick={() => setShow(true)}>
        Daily Menu Plan
      </Button> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        className="modal-main info-modal"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="d-block position-relative border-0 pt-4 px-4">
          <Modal.Title
            className="text-center"
            id="example-custom-modal-styling-title"
          >
            Daily Menu Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <div className="menu d-flex justify-content-between">
            <p>
              Not sure what to make? follow this daily menu plan with recipe
              links
            </p>
            <button className="menuBtn border-0 px-2 py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  d="M17.1969 1.11329C20.276 1.98281 22.7618 3.73209 24.5827 6.37134C24.6236 6.42249 24.6646 6.47364 24.7975 6.64754C24.7975 5.98261 24.7975 5.44044 24.7975 4.9085C24.7975 4.51977 24.7771 4.12081 24.818 3.74232C24.8794 3.24106 25.2579 2.90348 25.7284 2.87279C26.2297 2.8421 26.7207 3.10808 26.7514 3.58887C26.823 5.10286 27.1094 6.65777 26.5264 8.13085C26.066 9.29703 24.951 10.054 23.703 10.1154C22.6595 10.1665 21.6059 10.1359 20.5522 10.1256C19.8771 10.1154 19.4474 9.70621 19.4577 9.11289C19.4577 8.51957 19.8873 8.11039 20.5625 8.10016C21.4217 8.0797 22.2913 8.10016 23.2529 7.9774C22.6595 7.27155 22.1481 6.48387 21.4729 5.87009C18.3528 2.93417 14.6804 1.94189 10.5681 3.20014C6.48643 4.45839 3.93924 7.30224 3.0288 11.4657C1.73986 17.3682 5.60668 23.2196 11.5501 24.4472C12.5731 24.662 13.637 24.6211 14.6906 24.662C15.376 24.6927 15.7954 24.9893 15.8568 25.552C15.9182 26.1862 15.509 26.6772 14.7827 26.6875C13.8723 26.7079 12.9414 26.7284 12.0411 26.5852C7.24342 25.8486 3.81648 23.2298 1.84216 18.8106C1.33067 17.6956 1.167 16.4373 0.860109 15.2507C0.819191 15.087 0.788502 14.9131 0.757812 14.7494C0.757812 14.0436 0.757812 13.348 0.757812 12.6319C0.819191 12.2841 0.880569 11.9261 0.941947 11.5782C1.84216 6.76007 4.56325 3.39451 9.09499 1.5634C9.80084 1.27697 10.5578 1.11329 11.3148 0.959845C13.0948 0.591577 14.9361 0.581348 16.7059 0.990534C16.8695 1.02122 17.0332 1.06214 17.1969 1.11329Z"
                  fill="white"
                />
                <path
                  d="M14.8126 9.62432C14.8126 10.8928 14.8433 12.1715 14.7921 13.44C14.7819 13.8083 14.6182 14.2584 14.3625 14.5243C13.1349 15.8133 11.8664 17.0613 10.598 18.3195C10.1376 18.7696 9.65684 18.8413 9.22719 18.565C8.83846 18.3093 8.6441 17.8387 8.818 17.4193C8.9203 17.1738 9.10443 16.959 9.28857 16.7646C10.3525 15.6905 11.4266 14.6369 12.4802 13.5423C12.6439 13.3684 12.7667 13.0819 12.7667 12.8467C12.7871 10.5552 12.7769 8.26377 12.7769 5.97232C12.7769 5.83934 12.7667 5.69612 12.7769 5.56314C12.828 4.93913 13.2577 4.50948 13.8101 4.51971C14.3523 4.52994 14.7717 4.94936 14.8024 5.55291C14.8228 5.99278 14.8126 6.43266 14.8126 6.87253C14.8126 7.7932 14.8126 8.71388 14.8126 9.62432Z"
                  fill="white"
                />
                <path
                  d="M20.7062 24.1999C20.6653 24.9876 20.0106 25.1615 19.4684 25.4479C19.0388 25.6729 18.6091 25.765 18.1795 25.4479C17.6475 25.0592 17.6271 24.2613 18.1692 23.8828C18.5682 23.6066 19.0183 23.3815 19.4684 23.2281C20.0822 23.0235 20.7164 23.5554 20.7062 24.1999Z"
                  fill="white"
                />
                <path
                  d="M24.3473 16.999C24.4291 16.733 24.5212 16.2829 24.6849 15.8635C24.869 15.3724 25.36 15.1576 25.8408 15.2906C26.3216 15.4236 26.6592 15.8532 26.5774 16.3545C26.5058 16.8046 26.3932 17.2445 26.2296 17.6639C26.0556 18.1242 25.5442 18.339 25.1043 18.2265C24.6235 18.0935 24.3064 17.6639 24.3473 16.999Z"
                  fill="white"
                />
                <path
                  d="M24.6136 20.7754C24.4908 21.4301 23.6111 22.5758 23.0485 22.6065C22.7416 22.6269 22.3631 22.4735 22.1073 22.2689C21.8004 22.0234 21.7288 21.5938 21.9539 21.2459C22.2301 20.8265 22.5472 20.4173 22.9052 20.0593C23.1917 19.7729 23.6111 19.7319 23.9384 19.9672C24.2044 20.1821 24.3885 20.5094 24.6136 20.7754Z"
                  fill="white"
                />
              </svg>
              &nbsp; 3 min
            </button>
          </div>
          <div className="d-flex justify-content-center m-4">
            <img src="/images/png/dailymenu.png" className="dailyMenuIcon" />
          </div>
          <p className="menuST">
            you might be wondering, what if you get hungry in between meals?
            what do you do? dont worry. here are some snack ideas that you can
            easily munch when your tummy needs it:
          </p>

          <Row className="menu">
            <Col className="pb-3">
              {/* <br/> */}
              <span
                style={{ fontSize: "19px", fontWeight: 600, color: "#fff" }}
              >
                Day 1
              </span>
              <div className="card p-3 pb-0 menuPlanDiv">
                <ul>
                  <li className="">A handful of assorted nuts</li>
                  <li>Fruits of any kind</li>
                  <li>
                    A fresh fruit smoothie(or simply try frozen raspberries
                    eaten from a bowl)
                  </li>
                  <li>Banana pops</li>
                  <li>Watermalon agua fresca</li>
                  <li>
                    Avocado toast (simply slice half an avocado on some toast or
                    try this avocado mayo recipe)
                  </li>
                  <li style={{ color: "white" }}>abc</li>
                </ul>
              </div>
            </Col>
            <Col>
              <span
                style={{ fontSize: "19px", fontWeight: 600, color: "#000" }}
              >
                Day 1
              </span>
              <div className="card p-3 pb-0 menuPlanDiv">
                <ul>
                  <li>
                    Breakfast oatmeel cereal with a cinnamon stick or a teaspoon
                    of stevia or maple syrup. Add fresh such as sliced bananas
                    or berries{" "}
                  </li>
                  <li>Or fresh fruit smoothie </li>
                  <li>Lunch fasolada (baked bean soup) </li>
                  <li>
                    Dinner large chopped salad with any dark leafy greens like
                    romanie, lettuce, spanich, kale, etc.{" "}
                  </li>
                  <li>
                    Dinner large chopped salad with any dark leafy greens like
                    romanie, lettuce, spanich, kale, etc.{" "}
                  </li>
                  <li>
                    Optional: Add crumbled nafsika’s garden vegan feta or
                    parmesan
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <div className="d-flex gap-4">
            <p>Create your own plan</p>
            <div>
              <input type="radio" id="radioBtn" checked />
              &nbsp;
              <label for="radioBtn">Breakfast</label>
            </div>
            <div>
              <input type="radio" id="radioBtn" />
              &nbsp;
              <label for="s">Dinner</label>
            </div>
            <div>
              <input type="radio" />
              &nbsp;
              <label for="m">Lunch</label>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Main name="Tracking Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Tracking Management</h1>
          </Col>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            {/* <Form> */}
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Enter search text here"
                  type="text"
                  name="search"
                  autoComplete="off"
                  onFocus={disableAutocomplete}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setSearchTemp(true);
                  }}
                />
              </div>
              <div onClick={() => addModelOpenFun()} className="cursor-pointer">
                <img
                  src="/images/svg/add.svg"
                  className="add-btn"
                  alt=""
                  title="Add"
                />
              </div>
            </div>
            {/* </Form> */}
          </Col>
        </div>
        <TrackingContent temp={temp} search={search} searchTemp={searchTemp} />
      </Main>

      {/* Add Model  */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => setAddModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Tracking</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : state?.image
                        ? url + state?.image
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : state?.image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                />
              </div>

              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">Title <span className="star">*</span></label>
              <input
                className="form-control px-3"
                placeholder="Enter tracking title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">Description</label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter tracking description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
            <Col md={12}>
              <label id="type">
                Type <span className="star">*</span>
              </label>
              <select className="form-select"
                value={state?.type || ""}
                name="type"
                onChange={handleChange}
                style={{ color: state?.type ? "#000" : "" }}>
                <option value={""} selected disabled>Select type</option>
                <option value={"tracking"}>Tracking</option>
                <option value={"learning"}>Learning</option>
              </select>
              <SingleError error={errors?.type} />
            </Col>
            {state?.type === "tracking" &&
              <Col md={12}>
                <label id="subtype">
                  SubType <span className="star">*</span>
                </label>
                <select className="form-select"
                  value={state?.subType || ""}
                  name="subType"
                  onChange={handleChange}
                  style={{ color: state?.subType ? "#000" : "" }}>
                  <option value={""} selected disabled>Select sub type</option>
                  <option value={"weight"}>Weight</option>
                  <option value={"stress"}>Stress</option>
                  <option value={"menstrual-cycle"}>Menstrual Cycle</option>
                  <option value={"water-glass"}>Water Glass</option>
                  <option value={"push-up"}>Push Up</option>
                  <option value={"sugar-fast"}>Sugar Fast</option>
                  <option value={"plant-powered"}>Plant Powered</option>
                  <option value={"virtual-fitness"}>Virtual Fitness</option>
                  <option value={"fitness-counter"}>Fitness Counter</option>
                </select>
                <SingleError error={errors?.subType} />
              </Col>
            }
                 <Col md={12}>
              <label id="subscriptionStatus">
              Subscription Status <span className="star">*</span>
              </label>
              <select className="form-select"
                value={state?.subscriptionStatus || ""}
                name="subscriptionStatus"
                onChange={handleChange}
                style={{ color: state?.subscriptionStatus ? "#000" : "" }}>
                <option value={""} selected disabled>Select Subscription Status</option>
                <option value={"paid"}>Paid</option>
                <option value={"unpaid"}>Unpaid</option>
              </select>
              <SingleError error={errors?.subscriptionStatus} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addTracking();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrackingManagement;
