import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getServerURL } from "../helper/envConfig";
import api from "../helper/api";
import { errorResponse } from "../helper/error";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import TablePagination from "./TablePagination";
import { toast } from "react-toastify";
import Loader from "./loader/Loader";
import SingleError from "../helper/SingleError";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { debounce } from "lodash";
import CustomUploadAdapter from "../components/adapterclass/CustomUploadAdapter";
import { useDispatch } from "react-redux";
import { setChapterIndex } from "../redux/action/IndexAction";

const requireField = ["title", "description", "duration", "content"];

const ChapterList = ({
  temp,
  search,
  searchTemp,
  lectureData,
  lecturePage,
}) => {

  const navigate = useNavigate();
  const serverURL = getServerURL();
  const dispatch = useDispatch();
  const modalType = useRef(null);

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);

  const [chapterList, setChapterList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editChapterData, setEditChapterData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteDataID, setDeleteDataID] = useState("");

  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    getChapterList();
    getChapterAllList();
  }, []);

  useEffect(() => {
    getChapterList();
    getChapterAllList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getChapterList();
      getChapterAllList();
    }
    if (searchTemp && !search) {
      getChapterList();
      getChapterAllList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editChapterData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditChapterData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getChapterList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}chapter-manage?page=${page}&limit=${limit}&search=${search}&lecture_id=${lectureData?.id}`,
          { action: "list" }
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            setChapterList(
              res?.data?.data?.sort(
                (a, b) => a?.chapter_index - b?.chapter_index
              )
            );
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setChapterList([]);
            if (Boolean(page != 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) { setMainLoader(false); }
  };

  const getChapterAllList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}chapter-manage?lecture_id=${lectureData?.id}`,
          { action: "list" }
        )
        .then(async (res) => {
          // console.log("====",res);
          setMainLoader(false);
          // const sortedData = res?.data?.data.sort((a, b) => a.chapter_index - b.chapter_index);
          if (res?.data?.success === true) {
            dispatch(setChapterIndex(res?.data?.data));
          }
        })
        .catch((e) => {
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            dispatch(setChapterIndex([]));
          }
        });
    } catch (error) { }
  };

  const getChapterDetail = (id) => {
    try {
      api
        .postWithToken(`${serverURL}chapter-manage?chapterId=${id}`, {
          action: "detail",
        })
        .then(async (res) => {
          setMainLoader(false);
          if (modalType?.current?.view) {
            viewModelOpenFun();
          } else if (modalType?.current?.edit) {
            editModelOpenFun();
          }
          // console.log("======",res);
          if (res?.data?.success === true) {
            setEditChapterData(res?.data?.data);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (modalType?.current?.view) {
            viewModelOpenFun();
          } else if (modalType?.current?.edit) {
            editModelOpenFun();
          }
          // errorResponse(e);
        });
    } catch (error) { }
  };

  const appendObjectToFormData = (obj, formData, prefix = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === "object") {
          appendObjectToFormData(obj[key], formData, propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  };

  const editChapter = () => {
    const { _id, __v, created_at, updated_at, ...filteredObject } =
      editChapterData;
    const updatedValues = { ...editChapterData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("chapterId", editChapterData?._id);
      for (const key in filteredObject) {
        if (editChapterData.hasOwnProperty(key)) {
          if (typeof editChapterData[key] === "object") {
            // If the property is an object, stringify it before appending
            appendObjectToFormData(editChapterData[key], formData, key);
          } else {
            formData.append(key, editChapterData[key]);
          }
        }
      }
      if (Array.isArray(editChapterData?.chapter_images)) {
        editChapterData?.chapter_images?.forEach((subImage, index) => {
          formData.append(`chapter_images`, subImage);
        });
      } else {
        formData.append("chapter_images", editChapterData?.chapter_images);
      }
      try {
        api
          .postWithToken(
            `${serverURL}chapter-manage?page=${page}&limit=${limit}&search=${search}`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              getChapterList();
              getChapterAllList();
              editModelCloseFun();
              setEditChapterData({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Chapter Updated successfully");
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  const deleteChapter = () => {
    try {
      api
        .postWithToken(`${serverURL}chapter-manage`, {
          action: "delete",
          chapterId: deleteDataID,
        })
        .then(async (res) => {
          // console.log(res);
          setDeleteDataID("");
          getChapterList();
          getChapterAllList();
          if (chapterList?.length == 1) {
            setChapterList([]);
            dispatch(setChapterIndex([]));
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Chapter deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const editorConfiguration = {
    extraPlugins: [CustomAdapterPlugin],
    mediaEmbed: {
      previewsInData: true,
    },

    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "|",
        "imageUpload",
        "|",
        "mediaEmbed",
        "|",
        "imageTextAlternative",
        // ... any other toolbar items except 'insertTable'
      ],
    },

    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
      resizeUnit: "px",
    },
  };

  function CustomAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader); // Ensure this matches your import
    };
  }

  const finalContent = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
     
      <style>
      .custom-html body {
          padding: 20px;
      }
      .custom-html figure img {
          width: 100%;
          height: 100%;
      }
      .custom-html p {
          word-break: break-all;
          font-size: 18.300px;
          color: #555;
          font-weight: 500;
      }
      .custom-html ul {
          padding: 0px;
      }
      .custom-html ul {
        padding: 0px;
    }
    .custom-html blockquote {
      border-left: 5px solid #ccc;
      font-style: italic;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      padding-left: 1.5em;
      padding-right: 1.5em;
  }
    .custom-html strong {
      font-weight: 900;
    }
       .custom-html h1,
       .custom-html h2,
       .custom-html h3,
       .custom-html h4,
       .custom-html h5,
       .custom-html h6 {
          margin-top: 15px;
      }
      
      .custom-html ul li {
        font-size: 18.300px;
        color: #555;
        font-weight: 500;
        margin-left: 1.5rem !important;
      }

      .custom-html body::-webkit-scrollbar{
          display:none;
      }
  </style>

  </head>
  <body>
  ${editChapterData?.content}
  </body>
  </html>
  `;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      editChapter();
    }
  };

  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) : Boolean(chapterList?.length) ? (
        <>
          <div className="row">
            {Array.isArray(chapterList) &&
              Boolean(chapterList?.length) &&
              chapterList?.map((item, i) => (
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3"
                  key={i + item?._id + "chapterList"}
                >
                  <div className="user-layout p-3">
                    <p className="firstChapterHeading text-truncate">
                      Chapter {item?.chapter_index}
                    </p>
                    <p className="secondChapterHeading text-truncate">
                      {item?.title}
                    </p>
                    <p className="thirdChapterHeading text-truncate">
                      {item?.description}
                    </p>
                    <div
                      className="d-flex justify-content-end align-items-center"
                      style={{ marginTop: "-30px" }}
                    >
                      <img
                        src="/images/svg/showLight.svg"
                        alt="view"
                        title="View"
                        className="pl-2 cursor-pointer"
                        onClick={() => {
                          modalType.current = { view: true };
                          setMainLoader(true);
                          getChapterDetail(item?._id);
                        }}
                      />
                      <img
                        src="/images/svg/editLight.svg"
                        alt="edit"
                        title="Edit"
                        className="pl-2 cursor-pointer"
                        onClick={() => {
                          modalType.current = { edit: true };
                          setMainLoader(true);
                          getChapterDetail(item?._id);
                        }}
                      />
                      <img
                        src="/images/svg/deleteLight.svg"
                        alt="delete"
                        title="Delete"
                        className="pl-2 cursor-pointer"
                        onClick={(e) => {
                          deleteModelOpenFun(item?._id);
                          setDeleteDataID(item?._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <div className="mt-5 w-100 p-5 d-flex justify-content-center">
          <h4 className="test-center">No Records Found.</h4>
        </div>
      )}

      <div className="d-md-flex justify-content-between align-items-center mt-4 mb-4">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() =>
            navigate("/learn-management", { state: { lecturePage } })
          }
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        {Boolean(chapterList?.length) && paginationData?.totalPages > 1 && (
          <TablePagination
            currentPage={paginationData?.currentPage}
            totalPages={paginationData?.totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        )}
      </div>

      {/* View chapter modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal pl-0"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View Chapter</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="s-img" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="justify-content-center">
            <Col className="col-11 px-0">
              <div className="d-flex justify-content-center">
                <div className="my-4">
                  <tr>
                    <td className="userDetailsLabel">Title :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {editChapterData?.title || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Description :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {editChapterData?.description || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Lecture Name :</td>
                    &nbsp;
                    <td className="userDetailsText one-line-text">
                      {lectureData?.lectureList?.filter(
                        (item) => item?._id == editChapterData?.lecture_id
                      )[0]?.title ||
                        editChapterData?.lecture_id ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Lecture Index :</td>
                    &nbsp;
                    <td className="userDetailsText one-line-text">
                      {lectureData?.lectureList?.filter(
                        (item) => item?._id == editChapterData?.lecture_id
                      )[0]?.lecture_index || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Chapter Index :</td>
                    &nbsp;
                    <td className="userDetailsText one-line-text">
                      {editChapterData?.chapter_index || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Time Duration :</td>
                    &nbsp;
                    <td className="userDetailsText one-line-text">
                      {editChapterData?.duration || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Content :</td>
                  </tr>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{ __html: finalContent }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Chapter</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter chapter title here"
                type="text"
                name="title"
                value={editChapterData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>

            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                className="form-control px-3"
                placeholder="Enter chapter description here"
                type="text"
                row={3}
                name="description"
                value={editChapterData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

            <Col md={12}>
              <label>Lecture Index </label>
              <input
                className="form-control px-3"
                placeholder="Enter chapter description here"
                type="text"
                disabled
                name="lecture_index"
                value={
                  lectureData?.lectureList?.filter(
                    (item) => item?._id == editChapterData?.lecture_id
                  )[0]?.lecture_index || "-"
                }
                onKeyPress={handleKeyPress}
                onChange={handleChange}
              />
              <SingleError error={errors?.lecture_index} />
            </Col>

            <Col md={12}>
              <label id="duration">
                Time <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter chapter time duration here (e.g., 10 min)"
                type="text"
                name="duration"
                value={editChapterData?.duration || ""}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
              />
              <SingleError error={errors?.duration} />
            </Col>

            <Col md={12}>
              <label id="content">
                Content <span className="star">*</span>
              </label>
              <CKEditor
                name="content"
                editor={ClassicEditor}
                data={editChapterData?.content || ""}
                onChange={debounce((event, editor) => {
                  if (submitCount > 0) {
                    let validationErrors = ValidateFields({
                      ...editChapterData,
                      content: editor?.getData() || "",
                    });
                    validationErrors = ErrorFilter(
                      validationErrors,
                      requireField
                    );
                    setErrors(validationErrors);

                    if (Object.keys(validationErrors)?.length === 0) {
                      delete errors["content"];
                    }
                  }
                  setEditChapterData((prevState) => ({
                    ...prevState,
                    content: editor?.getData() || "",
                  }));
                }, 300)}
                config={editorConfiguration}
              />
              <SingleError error={errors?.content} mb={false} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center gap-3 mt-3">
            <button
              className={`modelBtn text-uppercase border-0 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                editChapter();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Chapter</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn border-0"
              onClick={() => deleteChapter()}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChapterList;
