import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NutrientsRichFoodsContent from "../components/NutrientsRichFoodsContent";
import Main from "../hoc/Main";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import SingleError from "../helper/SingleError";
import { useLocation } from 'react-router-dom';
const requireField = ["image", "title", "description", "type", "duration"];

const NutrientsRichFoods = () => {
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [states, setStates] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("");
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...states, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setStates((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...states, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setStates({
        ...states,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const addTracking = () => {
    setTemp(false);
    const updatedValues = { ...states };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("tracking_id", state?.item?._id);
      formData.append("title", states?.title);
      formData.append("description", states?.description);
      formData.append("image", states?.image);
      formData.append("type", states?.type);
      formData.append("duration", states?.duration);
      try {
        api
          .postWithToken(
            `${serverURL}tracking-category-add`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModelCloseFun();
              setTemp(true);
              setSelectedImage("");
              setStates({});
              if (typeof res?.data?.data?.message === "string")
                toast.success(res?.data?.data?.message);
              else toast.success("Tracking created successfully");
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      addTracking();
    }
  };

  return (
    <>
      <Main name="Nutrients Rich Food">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Nutrients Rich Foods</h1>
          </Col>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <Form>
              <div className="d-flex align-items-center gap-3">
                <div className="form-control d-flex align-items-center px-3">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                      fill="black"
                    />
                  </svg>
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Search"
                    type="text"
                  />
                </div>
                <div
                  onClick={() => addModelOpenFun()}
                  className="cursor-pointer"
                >
                  <img
                    src="/images/svg/add.svg"
                    className="add-btn"
                    alt=""
                    title="Add"
                  />
                </div>
              </div>
            </Form>
          </Col>
        </div>
        <NutrientsRichFoodsContent />
      </Main>

      {/* Add Model  */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => setAddModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Add Nutrients Rich Foods
          </Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                  <img
                    src={
                      selectedImage
                        ? selectedImage
                        : states?.image
                          ? url + states?.image
                          : "/images/png/duser.png"
                    }
                    onClick={handleImageClick}
                    className={`cursor-pointer ${selectedImage
                        ? "editprofileImage"
                        : states?.image
                          ? "editprofileImage"
                          : "profileImageSize"
                      } `}
                  />
                </div>

                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  name="image"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />

                <img
                  onClick={handleImageClick}
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
              {Boolean(errors?.image) && (
                <div className="d-flex justify-content-center">
                  <SingleError error={errors?.image} />
                </div>
              )}
            </Row>
            <Row>
              <Col md={12}>
                <label>Title <span className="star">*</span></label>
                <input
                  className="form-control px-3"
                  placeholder="Enter tracking title here"
                  type="text"
                  name="title"
                  value={states?.title || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.title} />
              </Col>
              <Col md={12}>
                <label>Description <span className="star">*</span></label>
                <textarea
                  rows={3}
                  className="form-control px-3"
                  placeholder="Enter tracking description here"
                  type="text"
                  name="description"
                  value={states?.description || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.description} />

              </Col>
              <Col md={12}>
                <label id="type">
                  Type <span className="star">*</span>
                </label>
                <input
                  className="form-control px-3"
                  placeholder="Enter tracking category type here"
                  type="text"
                  name="type"
                  value={states?.type || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <SingleError error={errors?.duration} />
              </Col>
              <Col md={12}>
                <label id="duration">
                  Time <span className="star">*</span>
                </label>
                <input
                  className="form-control px-3"
                  placeholder="Enter tracking category time duration here (e.g., 10 min)"
                  type="text"
                  name="duration"
                  value={states?.duration || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <SingleError error={errors?.duration} />
              </Col>
            </Row>
            <div className="d-flex  justify-content-center">
              <button
                className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                  }`}
                disabled={Boolean(loading)}
                onClick={() => {
                  addTracking();
                  setSubmitCount(1);
                }}
              >
                {Boolean(loading) ? "Loading..." : "Add"}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NutrientsRichFoods;
