import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";

const Main = (props) => {

  useEffect(() => {
    document.title = Boolean(props?.name) ? props?.name + " - The Plant Base Switch" : "The Plant Base Switch";
  }, []);
  
  return (
    <>
      <section>
        <Container fluid>
          <Row className="w-100">
            <Col
              xs={12}
              md={12}
              lg={4}
              xl={2}
              className="shadow-right d-none d-lg-block px-lg-0"
            >
              <Sidebar />
            </Col>
            <Col
              xs={12}
              lg={8}
              xl={10}
              className="position-relative px-md-3 px-lg-3 px-xl-5 border-start"
            >
              <Header name={props?.name} />
              <div>{props?.children}</div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Main;
