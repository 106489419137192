import Login from "../auth/Login";
import Dashboard from "../pages/Dashboard";
import { UserManagement } from "../pages/UserManagement";
import TrackingManagement from "../pages/TrackingManagement";
import ContentManagement from "../pages/ContentManagement";
import Community from "../pages/Community";
import Revenue from "../pages/Revenue";
import Analysis from "../pages/Analysis";
import Chapter from "../pages/Chapter";
import Weight from "../pages/Weight";
import StressLevelManagement from "../pages/StressLevelManagement";
import FoodsProteins from "../pages/FoodsProteins";
import Abs from "../pages/Abs";
import MenstrualCycleTracker from "../pages/MenstrualCycleTracker";
import NutrientsRichFoods from "../pages/NutrientsRichFoods";
import BodyExercise from "../pages/BodyExercise";
import DetoxesCleanses from "../pages/DetoxesCleanses";
import QuickSnacks from "../pages/QuickSnacks";
import Profile from "../pages/Profile";
import WaterCycleTracker from "../pages/WaterCycleTracker";
import VirtualFitness from "../pages/VirtualFitness";
import MyRegimen from "../pages/MyRegimen";
import Challenges from "../pages/Challenges";
import ShowGraph from "../pages/ShowGraph";
import TrackingManagement2 from "../pages/Tracking2";
import { SubscriptionManagement } from "../pages/SubscriptionManagement";
import { QuoteManagement } from "../pages/QuoteManagement";
import Verification from "../auth/Verification";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import ProtectLoginRoute from "./ProtectLoginRoute";
import PushUp from "../pages/PushUp";
import Glutes from "../pages/Glutes";
import Chest from "../pages/Chest";
import Back from "../pages/Back";
import Legs from "../pages/Legs";
import Shoulders from "../pages/Shoulders";
import Arms from "../pages/Arms";
import FoodsCalcium from "../pages/FoodsCalcium";
import FoodsB12 from "../pages/FoodsB12";
import FoodsIron from "../pages/FoodsIron";
import FoodsMagnesium from "../pages/FoodsMagnesium";
import FoodsVitaminD from "../pages/FoodsVitaminD";
import ChapterCreate from "../pages/ChapterCreate";
import { VideoLink } from "../pages/VideoLink";
import ChangePassword from "../auth/ChangePassword";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import LandingManagement from "../pages/LandingManagement";
import { LearnManagement } from "../pages/LearnManagement";
import UIPagesManagement from "../pages/UIPagesManagement";
import Settings from "../pages/Settings";

export default [
  {
    path: "/login",
    element: (
      <ProtectLoginRoute>
        <Login />
      </ProtectLoginRoute>
    ),
  },
  {
    path: "/change-password",
    element: (
      <PrivateRoute>
        <ChangePassword />
      </PrivateRoute>
    ),
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/user-management",
    element: (
      <PrivateRoute>
        <UserManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/learn-management",
    element: (
      <PrivateRoute>
        <LearnManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/chapter-management",
    element: (
      <PrivateRoute>
        <Chapter />
      </PrivateRoute>
    ),
  },
  {
    path: "/chapter-create",
    element: (
      <PrivateRoute>
        <ChapterCreate />
      </PrivateRoute>
    ),
  },
  {
    path: "/tracking-management",
    element: (
      <PrivateRoute>
        <TrackingManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/myregimen",
    element: (
      <PrivateRoute>
        <MyRegimen />
      </PrivateRoute>
    ),
  },
  {
    path: "/content-management",
    element: (
      <PrivateRoute>
        <ContentManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/community",
    element: (
      <PrivateRoute>
        <Community />
      </PrivateRoute>
    ),
  },
  {
    path: "/revenue",
    element: (
      <PrivateRoute>
        <Revenue />
      </PrivateRoute>
    ),
  },
  {
    path: "/analysis",
    element: (
      <PrivateRoute>
        <Analysis />
      </PrivateRoute>
    ),
  },
  {
    path: "/quote-management",
    element: (
      <PrivateRoute>
        <QuoteManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/subscription-management",
    element: (
      <PrivateRoute>
        <SubscriptionManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <ProtectLoginRoute>
        <ForgotPassword />
      </ProtectLoginRoute>
    ),
  },
  {
    path: "/verification",
    element: (
      <ProtectedRoute data={"resetpass"}>
        <Verification />
      </ProtectedRoute>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <ProtectedRoute data="verify">
        <ResetPassword />
      </ProtectedRoute>
    ),
  },
  {
    path: "/weight",
    element: (
      <PrivateRoute>
        <Weight />
      </PrivateRoute>
    ),
  },
  {
    path: "/stresslevel",
    element: (
      <PrivateRoute>
        <StressLevelManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/menstrualcycletracker",
    element: (
      <PrivateRoute>
        <MenstrualCycleTracker />
      </PrivateRoute>
    ),
  },
  {
    path: "/watercycleracker",
    element: (
      <PrivateRoute>
        <WaterCycleTracker />
      </PrivateRoute>
    ),
  },
  {
    path: "/foodsproteins",
    element: (
      <PrivateRoute>
        <FoodsProteins />
      </PrivateRoute>
    ),
  },
  {
    path: "/nutrientsfichfoods",
    element: (
      <PrivateRoute>
        <NutrientsRichFoods />
      </PrivateRoute>
    ),
  },
  {
    path: "/bodyexercise",
    element: (
      <PrivateRoute>
        <BodyExercise />
      </PrivateRoute>
    ),
  },
  {
    path: "/detoxescleansess",
    element: (
      <PrivateRoute>
        <DetoxesCleanses />
      </PrivateRoute>
    ),
  },
  {
    path: "/quicksnacks",
    element: (
      <PrivateRoute>
        <QuickSnacks />
      </PrivateRoute>
    ),
  },
  {
    path: "/abs",
    element: (
      <PrivateRoute>
        <Abs />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/virtualfitness",
    element: (
      <PrivateRoute>
        <VirtualFitness />
      </PrivateRoute>
    ),
  },
  {
    path: "/challenges",
    element: (
      <PrivateRoute>
        <Challenges />
      </PrivateRoute>
    ),
  },
  {
    path: "/showgraph",
    element: (
      <PrivateRoute>
        <ShowGraph />
      </PrivateRoute>
    ),
  },
  {
    path: "/tracking2",
    element: (
      <PrivateRoute>
        <TrackingManagement2 />
      </PrivateRoute>
    ),
  },
  {
    path: "/push-up",
    element: (
      <PrivateRoute>
        <PushUp />
      </PrivateRoute>
    ),
  },
  {
    path: "/glutes",
    element: (
      <PrivateRoute>
        <Glutes />
      </PrivateRoute>
    ),
  },
  {
    path: "/chest",
    element: (
      <PrivateRoute>
        <Chest />
      </PrivateRoute>
    ),
  },
  {
    path: "/back",
    element: (
      <PrivateRoute>
        <Back />
      </PrivateRoute>
    ),
  },
  {
    path: "/legs",
    element: (
      <PrivateRoute>
        <Legs />
      </PrivateRoute>
    ),
  },
  {
    path: "/shoulders",
    element: (
      <PrivateRoute>
        <Shoulders />
      </PrivateRoute>
    ),
  },
  {
    path: "/arms",
    element: (
      <PrivateRoute>
        <Arms />
      </PrivateRoute>
    ),
  },
  {
    path: "/foodscalcium",
    element: (
      <PrivateRoute>
        <FoodsCalcium />
      </PrivateRoute>
    ),
  },
  {
    path: "/foodsb12",
    element: (
      <PrivateRoute>
        <FoodsB12 />
      </PrivateRoute>
    ),
  },
  {
    path: "/foodsmagnesium",
    element: (
      <PrivateRoute>
        <FoodsMagnesium />
      </PrivateRoute>
    ),
  },
  {
    path: "/foodsiron",
    element: (
      <PrivateRoute>
        <FoodsIron />
      </PrivateRoute>
    ),
  },
  {
    path: "/foodsvitamind",
    element: (
      <PrivateRoute>
        <FoodsVitaminD />
      </PrivateRoute>
    ),
  },
  {
    path: "/video-management",
    element: (
      <PrivateRoute>
        <VideoLink />
      </PrivateRoute>
    ),
  },
  // {
  //   path: "/home-management",
  //   element: (
  //     <PrivateRoute>
  //       <HomeManagement />
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: "/ui-management",
    element: (
      <PrivateRoute>
        <UIPagesManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/landing-management",
    element: (
      <PrivateRoute>
        <LandingManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/Settings",
    element: (
      <PrivateRoute>
        <Settings />
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];
