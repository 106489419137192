import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "./TablePagination";
import api from "../helper/api";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import Loader from "./loader/Loader";
import SingleError from "../helper/SingleError";
import slugify from '../helper/slugify'
import formatDate from '../helper/formatDate'
import formatTime from '../helper/formatTime'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { debounce } from "lodash";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapter from "../components/adapterclass/CustomUploadAdapter";


const requireField = ["image", "title", "show_sub_category"];
const requireField1 = ["image", "title", "show_sub_category", "duration", "content"];

const TrackingCategoryContent = ({ temp, search, searchTemp, tracking }) => {

  const tracking_id = tracking?._id
  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [viewLearnModel, setViewLearnModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const viewLearnModelOpenFun = () => setViewLearnModel(true);
  const viewLearnModelCloseFun = () => setViewLearnModel(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalPages, SetTotalPages] = useState();

  const [learningList, setLearningList] = useState([]);
  const [trackingList, setTrackingList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editLearningData, setEditLearningData] = useState({});
  const [editTrackingData, setEditTrackingData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [viewData, setViewData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    if (tracking?.type === "tracking")
      getTrackingList();
    else
      getLearningList();
  }, []);

  useEffect(() => {
    if (tracking?.type === "tracking")
      getTrackingList();
    else
      getLearningList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      if (tracking?.type === "tracking")
        getTrackingList();
      else
        getLearningList();
    }
    if (searchTemp && !search) {
      if (tracking?.type === "tracking")
        getTrackingList();
      else
        getLearningList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const boolValue = value === "true";

    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editLearningData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, editLearningData?.show_sub_category === true ? requireField : requireField1);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditLearningData((prevValues) => ({
      ...prevValues,
      [name]: name === "show_sub_category" ? boolValue : value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditLearningData({
        ...editLearningData,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const getLearningList = () => {
    try {
      api
        .getWithToken(
          `${serverURL}tracking-category-get/${tracking_id}/?page=${page}&limit=${limit}&search=${search}`
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            setLearningList(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          // console.log(e);
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setLearningList([]);
          }
        });
    } catch (error) {
      setMainLoader(false);
      // console.log(error);
    }
  };

  const getTrackingList = () => {
    try {
      api
        .getWithToken(
          `${serverURL}get-tracking-manage?page=${page}&limit=${limit}&search=${search}&trackingType=${tracking?.subType}`
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            setTrackingList(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          // console.log(e);
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setLearningList([]);
          }
        });
    } catch (error) {
      setMainLoader(false);
      // console.log(error);
    }
  };

  const deleteLearning = () => {
    try {
      api
        .deleteWithToken(
          `${serverURL}/tracking-category-delete/${deleteDataID}`,
        )
        .then(async (res) => {
          // console.log(res);
          setDeleteDataID("");
          getLearningList();
          if (learningList?.length == 1) {
            setLearningList([]);
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Tracking deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const deleteTracking = () => {
    try {
      api
        .deleteWithToken(
          `${serverURL}/delete-tracking-manage?id=${deleteDataID}&trackingType=${tracking?.subType}`,
        )
        .then(async (res) => {
          // console.log(res);
          setDeleteDataID("");
          getTrackingList();
          if (trackingList?.length == 1) {
            setTrackingList([]);
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success(`${tracking?.subType} deleted successfully`);
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const editLearning = () => {
    const updatedValues = { ...editLearningData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, editLearningData?.show_sub_category === true ? requireField : requireField1);
    setErrors(validationErrors);


    if (editLearningData?.description && editLearningData?.description?.trim()?.length === 0) {
      validationErrors.description = "Description cannot be empty or contain only spaces.";
    }

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", editLearningData?.title);
      formData.append("description", editLearningData?.description);
      formData.append("image", editLearningData?.image);
      formData.append("tracking_id", tracking_id);
      formData.append("show_sub_category", editLearningData?.show_sub_category);
      formData.append("duration", editLearningData?.duration);
      formData.append("content", editLearningData?.content);
      try {
        api
          .patchWithToken(
            `${serverURL}tracking-category-update/${editLearningData?._id}`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            // console.log(res);
            if (res?.data?.success === true) {
              getLearningList();
              editModelCloseFun();
              setSelectedImage("");
              if (res?.data?.success === true) {
                if (typeof res?.data?.message === "string")
                  toast.success(res?.data?.message);
                else toast?.success("Lecture Updated successfully");
              }
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  function handleNavigate(title, item) {
    navigate(`/tracking/${slugify(tracking?.title)}/${slugify(title)}`, {
      state: { title, item, tracking },
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      editLearning();
    }
  };

  const finalContent = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
     
      <style>
      .custom-html body {
          padding: 20px;
      }
      .custom-html figure img {
          width: 100%;
          height: 100%;
      }
      .custom-html p {
          word-break: break-all;
          font-size: 18.300px;
          color: #555;
          font-weight: 500;
      }
      .custom-html ul {
          padding: 0px;
      }
      .custom-html ul {
        padding: 0px;
    }
    .custom-html blockquote {
      border-left: 5px solid #ccc;
      font-style: italic;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      padding-left: 1.5em;
      padding-right: 1.5em;
  }
    .custom-html strong {
      font-weight: 900;
    }
       .custom-html h1,
       .custom-html h2,
       .custom-html h3,
       .custom-html h4,
       .custom-html h5,
       .custom-html h6 {
          margin-top: 15px;
      }
      
      .custom-html ul li {
        font-size: 18.300px;
        color: #555;
        font-weight: 500;
        margin-left: 1.5rem !important;
      }

      .custom-html body::-webkit-scrollbar{
          display:none;
      }
  </style>

  </head>
  <body>
  ${viewData?.content}
  </body>
  </html>
  `;

  const handleEditorChange = debounce((event, editor) => {
    const data = editor.getData();
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editLearningData, content: data });
      validationErrors = ErrorFilter(validationErrors, editLearningData?.show_sub_category === true ? requireField : requireField1);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors["content"];
      }
    }
    setEditLearningData({ ...editLearningData, content: data });
  }, 300);

  const editorConfiguration = {
    extraPlugins: [CustomAdapterPlugin],
    mediaEmbed: {
      previewsInData: true,
    },

    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        // Ensure "link" is not included here
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "|",
        "imageUpload",
        "|",
        "mediaEmbed",
      ],
    },

    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
      resizeUnit: "px",
    },
  };

  function CustomAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
    };
  }
  console.log(trackingList);
  let newData = [];

  const filterHead = () => {
    let uniqueData = new Set();

    if (trackingList) {
      trackingList.forEach((item) => {
        if (item.images) {
          uniqueData.add("Image");
        }
        if (item.step) {
          uniqueData.add("Step");
        }
        if (item?.value) {
          uniqueData.add("Check-In");
        }
        if (item?.note) {
          uniqueData.add("Description");
        }
        if (item.daily_goal) {
          uniqueData.add("Daily Goal");
        }
        if (item.notification) {
          if (item.notification.start_time || item?.notification?.hasOwnProperty('start_time')) {
            uniqueData.add("Start Time");
          }
          if (item.notification.end_time || item?.notification?.hasOwnProperty('end_time')) {
            uniqueData.add("End Time");
          }
          if (item.notification.interval_time) {
            uniqueData.add("Interval Time");
          }
        }
      });
    }

    // Convert Set back to array if necessary
    newData = Array.from(uniqueData);
    return newData;
  };

  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) :
        tracking?.type === "learning" ?
          Boolean(learningList?.length) ?
            <>
              <div className="card mt-3 px-4 pb-4 pt-2">
                <div className="row">
                  {Array.isArray(learningList) &&
                    Boolean(learningList?.length) &&
                    learningList?.map((item, index) =>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3 cursor-pointer" key={index + "tracking"}
                        onClick={() => {
                          if (item?.show_sub_category) {
                            handleNavigate(item?.title, item)
                          } else {
                            setViewData(item);
                            viewLearnModelOpenFun();
                          }
                        }}>
                        <div className="card lectureView border-0">
                          <img
                            src={url + item?.image}
                            alt="img"
                            height={"345.512px"}
                          />
                          <div className="lecture-box">
                            <div className="lectureBtn">
                              <div className="w-100">
                                <div className="d-flex justify-content-between">
                                  <div className="trackingTitle wordBreak1 pb-0" title={item?.title}>
                                    {item?.title}
                                  </div>
                                  <div className="lectureIcon">
                                    <img
                                      src="/images/svg/editWhite.svg" title="Edit"
                                      className="px-2 cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        editModelOpenFun();
                                        setEditLearningData(item);
                                      }}
                                    />
                                    <img
                                      src="/images/svg/deleteWhite.svg" title="Delete"
                                      className="px-2 cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        deleteModelOpenFun(item?._id);
                                        setDeleteDataID(item?._id);
                                      }}
                                    />
                                    {
                                      item?.show_sub_category ?
                                        <img
                                          src="/images/svg/addWhite.svg" title="Add"
                                          className="pl-2 cursor-pointer"
                                          onClick={() =>
                                            handleNavigate(item?._id, item)
                                          }
                                        />
                                        :
                                        <img
                                          src="/images/svg/view.svg" title="Add"
                                          className="pl-2 cursor-pointer"
                                          onClick={(e) => { e.stopPropagation(); setViewData(item); viewLearnModelOpenFun(); }
                                          }
                                        />
                                    }
                                  </div>
                                </div>
                              </div>
                              {/* <div className="lectureText">{item?.title}</div> */}

                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </>
            : (
              <div className="mt-5 w-100 p-5 d-flex justify-content-center">
                <h4 className="test-center">No Records Found.</h4>
              </div>
            )
          :
          tracking?.type === "tracking" &&
          <Col className="ctmTable">
            <Table bordered responsive className="text-center mb-0 custom-table">
              <thead className="tableHeader">
                <tr>
                  <th className="number-width border">No</th>
                  {/* {Boolean(filterHead()?.length) &&
                  filterHead()?.map((item)=>
                    <th>Icon</th>
                  )
                  } */}
                  {filterHead()?.includes("Image") && <th>Icon</th>}
                  <th>Name</th>
                  {filterHead()?.includes("Step") && <th>Step</th>}
                  {filterHead()?.includes("Check-In") && <th>Check-In</th>}
                  {filterHead()?.includes("Description") && <th className="w-25">Descriptions</th>}
                  {filterHead()?.includes("Daily Goal") && <th className="w-25">Daily Goal</th>}
                  {filterHead()?.includes("Start Time") && <th>Start Time</th>}
                  {filterHead()?.includes("End Time") && <th>End Time</th>}
                  {filterHead()?.includes("Interval Time") && <th>Interval Time</th>}
                  <th>Created Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="tableBody ">
                {Boolean(trackingList?.length) ?
                  trackingList?.map((item, i) => (
                    <tr key={i}>
                      <td>{(limit * (page - 1)) + (i + 1)}</td>
                      {item?.images &&
                        <td>
                          <img src={item?.images ? url + item?.images[0] : ""} className="subTypeImage" />
                        </td>
                      }
                      <td>{item?.user_id?.username || item?.user_id?.email || "-"} </td>
                      {item?.value && <td>{item?.value || "-"}</td>}
                      {(filterHead()?.includes("Description") || item?.note) && <td className="w-25">
                        {item?.note || "-"}
                      </td>
                      }
                      {(filterHead()?.includes("Step") || item?.step) && <td>{item?.step || "-"}</td>}
                      {(filterHead()?.includes("Start Time") || item?.daily_goal) && <td>{item?.daily_goal || "-"}</td>}
                      {(filterHead()?.includes("Start Time") || item?.notification?.start_time) && <td>{formatTime(item?.notification?.start_time) || "-"}</td>}
                      {(filterHead()?.includes("End Time") || item?.notification?.end_time) && <td>{formatTime(item?.notification?.end_time) || "-"}</td>}
                      {(filterHead()?.includes("Interval Time") || item?.notification?.interval_time) && <td>{item?.notification?.interval_time || "-"}</td>}

                      <td>{formatDate(item?.date || item?.created_at) || "-"}</td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center gap-1">
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => { setEditTrackingData(item); viewModelOpenFun() }}
                          >
                            <img src="/images/svg/show.svg" title="View" />
                          </button>
                          {/* <button
                            className="border-0 bg-transparent"
                            onClick={editModelOpenFun}
                          >
                            <img src="/images/svg/edit.svg" title="Edit" />
                          </button> */}
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => { setDeleteDataID(item?._id); deleteModelOpenFun() }}
                          >
                            <img src="/images/svg/delete.svg" title="Delete" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                  : <tr><td colSpan={filterHead()?.length + 4}>No Records Found.</td></tr>}
              </tbody>
            </Table>
          </Col>
      }

      <div className="d-md-flex justify-content-between align-items-center mt-4 mb-3">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() => navigate("/tracking-management")}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        {Boolean(learningList?.length) && paginationData?.totalPages > 1 && (
          <TablePagination
            currentPage={paginationData?.currentPage}
            totalPages={paginationData?.totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        )}

        {Boolean(trackingList?.length) && paginationData?.totalPages > 1 && (
          <TablePagination
            currentPage={paginationData?.currentPage}
            totalPages={paginationData?.totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        )}

      </div>

      {/* View  modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal pl-0"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center text-capitalize">View {tracking?.title}</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="s-img" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="justify-content-center">
            <Col className="col-11 px-0">
              <div className="d-flex justify-content-center">
                <div className="my-4 w-100">
                  <tr>
                    <td className="userDetailsLabel">Name :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow text-capitalize">
                      {editTrackingData?.user_id?.username || editTrackingData?.user_id?.email || "-"}
                    </td>
                  </tr>
                  {editTrackingData?.value &&
                    <tr>
                      <td className="userDetailsLabel">Check In :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {editTrackingData?.value || "-"}
                      </td>
                    </tr>
                  }
                  {editTrackingData?.note &&
                    <tr>
                      <td className="userDetailsLabel">Description :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {editTrackingData?.note || "-"}
                      </td>
                    </tr>
                  }
                  {editTrackingData?.daily_goal &&
                    <tr>
                      <td className="userDetailsLabel">Daily Goal :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {editTrackingData?.daily_goal || "-"}
                      </td>
                    </tr>
                  }
                  {(filterHead()?.includes("Step") || editTrackingData?.step) &&
                    <tr>
                      <td className="userDetailsLabel">Step :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {formatTime(editTrackingData?.step) || "-"}
                      </td>
                    </tr>
                  }
                  {(filterHead()?.includes("Start Time") || editTrackingData?.notification?.start_time) &&
                    <tr>
                      <td className="userDetailsLabel">Start Time :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {formatTime(editTrackingData?.notification?.start_time) || "-"}
                      </td>
                    </tr>
                  }
                  {(filterHead()?.includes("End Time") || editTrackingData?.notification?.end_time) &&
                    <tr>
                      <td className="userDetailsLabel">End time :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {formatTime(editTrackingData?.notification?.end_time) || "-"}
                      </td>
                    </tr>
                  }
                  {(filterHead()?.includes("Interval Time") || editTrackingData?.notification?.interval_time) &&
                    <tr>
                      <td className="userDetailsLabel">Interval time :</td>
                      &nbsp;
                      <td className="userDetailsText fullTextShow">
                        {editTrackingData?.notification?.interval_time || "-"}
                      </td>
                    </tr>
                  }

                  <tr>
                    <td className="userDetailsLabel">Created Date :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {formatDate(editTrackingData?.date || editTrackingData?.created_at) || "-"}
                    </td>
                  </tr>
                  {editTrackingData?.images &&
                    <>
                      <tr>
                        <td className="userDetailsLabel">Images :</td>
                      </tr>
                      <Row className="my-4">
                        {editTrackingData?.images &&
                          editTrackingData?.images?.map((item) =>
                            <Col className="col-lg-3 col-md-4 col-sm-2 mb-3">
                              <img
                                src={url + item}
                                className={`table-view-image`}
                              />
                            </Col>
                          )}
                      </Row>
                    </>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center text-capitalize">Edit {tracking?.title}</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="position-relative my-4">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : editLearningData?.image
                        ? url + editLearningData?.image
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : editLearningData?.image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                // className="editprofileImage cursor-pointer"
                />
              </div>

              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">Title <span className="star">*</span></label>
              <input
                className="form-control px-3 mb-3"
                placeholder="Enter tracking title here"
                type="text"
                name="title"
                value={editLearningData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <div className="error text-danger mb-3">{errors?.title}</div>
            </Col>
            <Col md={12}>
              <label id="description">Description</label>
              <textarea
                rows={3}
                className="form-control px-3 mb-3 fs-20 text-black"
                placeholder="Description"
                type="text"
                name="description"
                value={editLearningData?.description || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <div className="error text-danger mb-3">
                {errors?.description}
              </div>
            </Col>
            <Col md={12}>
              <label id="show_sub_category">Show Sub Category <span className="star">*</span></label>
              <select className="form-select"
                name="show_sub_category"
                value={editLearningData?.show_sub_category}
                onChange={handleChange}
                style={{ color: (editLearningData?.show_sub_category === true || editLearningData?.show_sub_category === false) ? "#000" : "" }}>
                <option selected disabled value={""}>Select show sub category</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
              <SingleError error={errors?.show_sub_category} />
            </Col>

            {Boolean(editLearningData?.show_sub_category === false) &&
              <>
                <Col md={12}>
                  <label id="duration">
                    Time <span className="star">*</span>
                  </label>
                  <input
                    className="form-control px-3"
                    placeholder="Enter sub tracking category time duration here (e.g., 10 min)"
                    type="text"
                    name="duration"
                    value={editLearningData?.duration || ""}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                  <SingleError error={errors?.duration} />
                </Col>

                <Col md={12}>
                  <label id="content">
                    Content <span className="star">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editLearningData?.content || ""}
                    onChange={handleEditorChange}
                    config={editorConfiguration}
                  />
                  <SingleError error={errors?.content} />
                </Col>
              </>
            }

            {/* <Col md={12}>
                <label id="type">
                  Type <span className="star">*</span>
                </label>
                <select className="form-select"
                  value={editLearningData?.type || ""}
                  name="type"
                  onChange={handleChange}
                  style={{ color: editLearningData?.type ? "#000" : "" }}>
                  <option value={""}>Select type</option>
                  <option value={"exercises"}>Exercises</option>
                  <option value={"quick_snacks"}>Quick Snacks</option>
                  <option value={"detoxes"}>Detoxes</option>
                </select>
                <SingleError error={errors?.type} />
              </Col> */}
            {/* <Col md={12}>
                <label id="duration">
                  Time <span className="star">*</span>
                </label>
                <input
                  className="form-control px-3"
                  placeholder="Enter tracking category time duration here (e.g., 10 min)"
                  type="text"
                  name="duration"
                  value={editLearningData?.duration || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <SingleError error={errors?.duration} />
              </Col> */}
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                editLearning();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Update"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-capitalize delete-title text-center">Delete {tracking?.title}</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn border-0"
              onClick={() => {
                if (tracking?.type === "tracking")
                  deleteTracking()
                else
                  deleteLearning()
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* View  modal */}
      <Modal
        size="lg"
        show={viewLearnModel}
        onHide={viewLearnModelCloseFun}
        centered
        className="modal-main info-modal pl-0"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View {tracking?.title}</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="s-img" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="lecture_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={url + viewData?.image}
                  className={`editprofileImage`}
                />
              </div>
            </div>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-11 px-0">
              <div className="d-flex justify-content-center">
                <div className="my-4">
                  <tr>
                    <td className="userDetailsLabel">Title :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {viewData?.title || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Description :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {viewData?.description || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Time Duration :</td>
                    &nbsp;
                    <td className="userDetailsText one-line-text">
                      {viewData?.duration || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Content :</td>
                  </tr>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{ __html: finalContent }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrackingCategoryContent;
