import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { errorResponse } from "../helper/error";
import { toast } from "react-toastify";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { useNavigate } from "react-router-dom";
import api from "../helper/api";
import SingleError from "../helper/SingleError";
import Loader from "./loader/Loader";
const requireField = ["title", "date", "description"];

const QuoteTable = ({ temp, search, searchTemp }) => {

  const navigate = useNavigate();
  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setSelectedImage(null);
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalPages, setTotalPages] = useState(1);

  const [quoteList, setQuoteList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editQuoteData, setEditQuoteData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    getQuoteList();
  }, [page, temp, search, searchTemp]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editQuoteData,
        [name]: value,
      });
  
      // Additional date validation
      if (name === 'date') {
        const today = formatDate(new Date());
        const selectedDate = value;
        if (selectedDate) {
          const formattedSelectedDate = selectedDate.split('-').reverse().join('-'); // Convert dd-mm-yyyy to yyyy-mm-dd
  
          if (formattedSelectedDate < today) {
            validationErrors.date = "The date must be today or a future date.";
          } else {
            delete validationErrors.date; // Remove the error if the date is valid
          }
        } else {
          validationErrors.date = "Date is required.";
        }
      }
  
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditQuoteData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getQuoteList = () => {
    setMainLoader(true);
    api
      .postWithToken(
        `${serverURL}quote-manage?page=${page}&limit=${limit}&search=${search}`,
        { action: "list" }
      )
      .then((res) => {
        setMainLoader(false);
        if (res?.data?.success) {
          setQuoteList(res?.data?.data || []);
          setPaginationData(res?.data?.pagination || {});
          
        } else {
          setQuoteList([]);
        }
      })
      .catch((e) => {
        setMainLoader(false);
        if (e?.response?.status === 404) {
         
          setQuoteList([]);
        } else {
        
          errorResponse(e);
        }
      });
  };
  

  const deleteQuote = () => {
    setLoading(true); // Optional: Set a loading state to indicate that a request is in progress
  
    // Append the quote_id as a query parameter to the URL
    const url = `${serverURL}quote-manage?id=${deleteDataID}`;
  
    api
      .postWithToken(url, { action: "delete" }) // Send action as part of the body if needed
      .then((res) => {
        setLoading(false); // Reset the loading state
        if (res?.data?.success) {
          toast.success(res?.data?.message || "Quote deleted successfully");
          setDeleteDataID(""); // Clear the delete ID
          getQuoteList(); // Refresh the list of quotes
          deleteModelCloseFun(); // Close the delete modal
        } else {
          toast.error(res?.data?.message || "Failed to delete the quote");
        }
      })
      .catch((e) => {
        setLoading(false); // Reset the loading state
        errorResponse(e); // Handle the error appropriately
      });
  };
  
  

  const editQuote = () => {
    const updatedValues = { ...editQuoteData };
    let validationErrors = ValidateFields(updatedValues);
  
    // Additional date validation
    const today = formatDate(new Date());
    const selectedDate = updatedValues.date;
    if (selectedDate) {
      const formattedSelectedDate = selectedDate.split('-').reverse().join('-'); // Convert dd-mm-yyyy to yyyy-mm-dd
  
      if (formattedSelectedDate < today) {
        validationErrors.date = "The date must be today or a future date.";
      }
    } else {
      validationErrors.date = "Date is required.";
    }
  
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
  
      const payload = {
        quote_id: editQuoteData?._id || "",
        action: "update",
        title: editQuoteData?.title || "",
        date: editQuoteData?.date || "",
        description: editQuoteData?.description || ""
      };
  
      api
        .postWithToken(`${serverURL}quote-manage`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            getQuoteList();
            editModelCloseFun();
            setSelectedImage(null);
            toast.success(res?.data?.message || "Quote updated successfully");
          } else {
            toast.error(res?.data?.message || "Failed to update the quote");
          }
        })
        .catch((e) => {
          setLoading(false);
          errorResponse(e);
        });
    }
  };
  

  // Helper function to format the date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
  
    // Get the day, month, and year
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    // Format as DD-MM-YYYY
    return `${day}-${month}-${year}`;
  };
  const EditformatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
  };

  return (
    <>
    {mainLoader ? (
            <div className="loading-indicator">
               <Loader/>
            </div>
        ):(
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Title</th>
              <th>Description</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {quoteList.length > 0 ? (
              quoteList.map((quote, index) => (
                <tr key={quote._id}>
                <td>{(page - 1) * limit + index + 1}</td>
                  <td>{quote.title}</td>
                  <td>{quote.description}</td>
                  <td>{formatDate(quote.date)}</td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center gap-1">
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          setEditQuoteData(quote);
                          viewModelOpenFun();
                        }}
                      >
                        <img src="/images/svg/show.svg" title="View" />
                      </button>
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          setEditQuoteData(quote);
                          editModelOpenFun();
                        }}
                      >
                        <img src="/images/svg/edit.svg" title="Edit" />
                      </button>
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          setDeleteDataID(quote._id);
                          deleteModelOpenFun();
                        }}
                      >
                        <img src="/images/svg/delete.svg" title="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">No quotes available</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          <TablePagination
            currentPage={paginationData?.currentPage}
            totalPages={paginationData?.totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      </Col>
    )}

      {/* View user modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Quote Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
        
            <Row>
              <div className="d-flex justify-content-center">
                <div>
                  
                  <div className="mb-3">
                    <tr>
                      <td className="userDetailsLabel">Title:</td>
                      &nbsp;
                      <td className="userDetailsText">{editQuoteData?.title}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Date:</td>
                      &nbsp;
                      <td className="userDetailsText">{formatDate(editQuoteData?.date || "")}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Description:</td>
                      &nbsp;
                      <td className="userDetailsText">{editQuoteData?.description || ""}</td>
                    </tr>
                    
               

                  </div>
                </div>
              </div>
            </Row>
          
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={editQuoteData.title || ""}
                    onChange={handleChange}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={EditformatDate(editQuoteData?.date || "")}
                    onChange={handleChange}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={editQuoteData.description || ""}
                    onChange={handleChange}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              </Row>
          </Form>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${loading ? "btnDisable" : ""}`}
              disabled={loading}
              onClick={editQuote}
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="sm"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Are you sure you want to delete this quote?
          </p>
          <div className="text-center">
            <button
              className="btn btn-danger"
              onClick={deleteQuote}
              disabled={loading}
            >
              {loading ? "Deleting..." : "Delete"}
            </button>
            <button
              className="btn btn-secondary ms-2"
              onClick={deleteModelCloseFun}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuoteTable;
