import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { logout } from "../helper/auth";
import { decryptFromStoreLoc } from "../helper/localStorage";
import { toast } from "react-toastify";
import api from "../helper/api";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { errorResponse } from "../helper/error";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setLogout } from "../redux/action/LoginAction";

function Header() {
  const navigate = useNavigate();
  const serverURL = getServerURL();
  const userName = decryptFromStoreLoc("username");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeSideBarFunction = () => {
    document.getElementById("closeBtn").click();
  };

  const url = getImageURL();
  const dispatch = useDispatch()

  const profileData = useSelector((state) => state?.login);

  useEffect(() => {
    if (!Boolean(Object.keys(profileData)?.length)) { getProfileData(); }
  }, []);

  const getProfileData = () => {
    api
      .postWithToken(`${serverURL}profile-view`)
      .then(async (res) => {
        if (res?.data?.success === true) {
          dispatch(setLogin(res?.data?.data))
          // setProfileData(res?.data?.data);
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  const handleLogout = () => {
    try {
      api
        .postWithToken(`${serverURL}logout`)
        .then(async (res) => {
          // console.log(res);
          if (res?.data?.success === true) {
            dispatch(setLogout());
            logout();
            navigate("/");
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast.success("Logout Successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  return (
    <>
      <header className="d-flex justify-content-between align-items-center pt-4 ctm-drop">
        <div onClick={handleShow}>
          <img
            src="/images/svg/menu.svg"
            className="toggle"
            width={35}
            height={35}
            alt="menu"
          />
        </div>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='user-main bg-transparent border-0 p-0 d-flex align-items-center gap-3'>
            <div className='d-flex align-items-center gap-2'>
              <img src={profileData?.profile_image ? url + profileData?.profile_image : "/images/jpg/user.jpg"} className='rounded-3 overflow-hidden' width={50} height={50} alt="user" />
              <div>
                <h6 className='text-start text-dark fs-18 mb-0'>Hello {profileData?.first_name || "Admin"}</h6>              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{ fill: "rgba(0, 0, 0, 1)", transform: "", msfilter: "" }}
            >
              <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" className='border-0 shadow mt-2'>
            <Dropdown.Item className='d-flex align-items-center gap-2 py-2' onClick={() => navigate("/profile")}>
              <img
                src={"/images/svg/profile.svg"}
                alt=""
                className="menuIcon"
                sty
              />
              &nbsp;
              <span>My Profile</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className='d-flex align-items-center gap-2 py-2' onClick={() => navigate("/change-password")}>
              <img
                src="/images/svg/changepassword.svg"
                alt=""
                className="menuIcon pr-0"
              />
              &nbsp;
              <span>Change Password</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className='d-flex align-items-center gap-2 py-2' onClick={() => handleLogout()}>
              <img
                src="/images/svg/logout.svg"
                alt=""
                className="menuIcon pr-0"
                style={{ marginLeft: "3px" }}
              />
              &nbsp;
              <span>Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="user-main bg-transparent border-0 p-0 d-flex align-items-center gap-3 pt-2"
          >
            <div className="d-flex align-items-center justify-content-end gap-2">
              <img
                src={profileData?.profile_image ? url + profileData?.profile_image : "/images/jpg/user.jpg"}
                alt=""
                className="rounded-3 overflow-hidden profile-user"
              />
              <span className="userHead">Hello {profileData?.first_name || "Admin"}</span>
              <img src="/images/svg/arrow.svg" alt="arrow" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-home">
            <Dropdown.Item className="w-100" onClick={() => navigate("/profile")}>
              <img
                src={"/images/svg/profile.svg"}
                alt=""
                className="menuIcon"
                sty
              />
              &nbsp;
              <span>My Profile</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="w-100" onClick={() => navigate("/change-password")} >
              <img
                src="/images/svg/changepassword.svg"
                alt=""
                className="menuIcon pr-0"
              />
              &nbsp;
              <span>Change Password</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="w-100" onClick={() => handleLogout()}>
              <img
                src="/images/svg/logout.svg"
                alt=""
                className="menuIcon"
                style={{ marginLeft: "3px" }}
              />
              <span>Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

      </header>
      <div>
      </div>

      <Offcanvas className="offcanvas-sidbar" show={show} onHide={handleClose}>
        <Offcanvas.Header className="d-flex justify-content-end" closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Sidebar />
        </Offcanvas.Body>
      </Offcanvas>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header d-flex justify-content-end">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            id="closeBtn"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body pt-0">
          <Sidebar closeSideBarFunction={closeSideBarFunction} />
        </div>
      </div>
    </>
  );
}

export default Header;
