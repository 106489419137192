import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import api from "../helper/api";
import { getServerURL, getImageURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../redux/action/LoginAction";
import Loader from "./loader/Loader";
import io from "socket.io-client";
import { decryptFromStoreLoc } from "../helper/localStorage";
const ChannelContent = ({
  temp,
  search,
  searchTemp,
  setSearch,
  setSearchTemp,
}) => {
  const [editModel, setEditModel] = useState(false);
  const [imageModel, setImageModel] = useState(false);
  const [currentImage, setCurrentImage] = React.useState(null);
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [channelList, setChannelList] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const serverURL = getServerURL();
  const [selectedImage, setSelectedImage] = useState(null);
  const [messselectedImage, setMessSelectedImage] = useState(null);

  const [refreshData, setRefreshData] = useState(false);
  const profile = useSelector((item) => item?.login);
  const [profileData, setProfileData] = useState(profile || {});

  const [state, setState] = useState({
    name: "",
    description: "",
    image: null, // Changed to handle file input
  });
  const imageUrl = getImageURL();
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState({});
  const requiredFields = ["image", "name", "description"];
  const dispatch = useDispatch();

  //   socket strt
  const token = localStorage.getItem("token");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [replies, setReplies] = useState({}); // State to manage replies visibility
  const [replyData, setReplyData] = useState({}); // State to store reply data
  const adminId = decryptFromStoreLoc("user");
  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL_PRODUCTION);
    setSocket(newSocket);

    // Handle incoming messages
    const handleIncomingMessages = (data) => {
      console.log("Incoming message data:", data);
      if (selectedItem && selectedItem._id) {
        setLoadingMessages(true);
        const filteredMessages = data.data.filter(
          (msg) => msg.groupId === selectedItem._id
        );
        setMessages(filteredMessages);
      }
      setLoadingMessages(false);
    };

    // Handle incoming replies
    const handleIncomingReplies = (data) => {
      console.log("Incoming reply data:", data);
      data.data.forEach((reply) => {
        console.log("Message ID:", reply.messageId); // Log each reply's messageId
        setReplyData((prev) => {
          const existingReplies = prev[reply.messageId] || [];
          const isDuplicate = existingReplies.some((r) => r._id === reply._id); // Check for duplicates

          if (!isDuplicate) {
            // Only add reply if it's not already in the state
            return {
              ...prev,
              [reply.messageId]: [...existingReplies, reply],
            };
          }
          return prev; // Return previous state if duplicate
        });
      });
    };

    // Listen for 'getGroupMessages' and 'getGroupMessagesReplies' events
    newSocket.on("getGroupMessages", handleIncomingMessages);
    newSocket.on("getGroupMessagesReplies", handleIncomingReplies);

    // Fetch initial messages when component mounts or selectedItem changes
    if (selectedItem && selectedItem._id) {
      setMessages([]);
      newSocket.emit("getGroupMessages", { token, groupId: selectedItem._id });
    }

    return () => {
      newSocket.off("getGroupMessages", handleIncomingMessages); // Clean up event listener
      newSocket.off("getGroupMessagesReplies", handleIncomingReplies); // Clean up event listener
      newSocket.close();
    };
  }, [selectedItem, token]);

  const handleReplyToggle = (messageId) => {
    if (replies[messageId]) {
      setReplies((prev) => ({
        ...prev,
        [messageId]: !prev[messageId],
      }));
    } else {
      // Fetch replies if not already fetched
      socket.emit("getGroupMessagesReplies", {
        token,
        groupId: selectedItem._id,
        messageId,
      });
      setReplies((prev) => ({
        ...prev,
        [messageId]: true,
      }));
    }
  };

  const handleSubmitMessage = () => {
    if (socket && message && selectedItem) {
      const currentDate = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newMessage = {
        message: message,
        groupId: selectedItem?._id,
        created_at: currentDate,
        token: token,
        image: messselectedImage ? [messselectedImage] : [],
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      socket.emit("sendGroupMessage", newMessage);
      setMessage("");
      setMessSelectedImage(null);
    }
  };
  const [isReplying, setIsReplying] = useState(false);
  const [replyMessageId, setReplyMessageId] = useState(null);

  const handleReplyButtonClick = (messageId) => {
    setReplyMessageId(messageId);
    setIsReplying(true);
  };
  const handleSubmitReplyMessage = () => {
    if (socket && message && selectedItem) {
      const currentDate = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newMessage = {
        message,
        groupId: selectedItem?._id,
        created_at: currentDate,
        token,
        image: messselectedImage ? [messselectedImage] : [],
        messageId: replyMessageId,
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      socket.emit("sendGroupMessage", newMessage);
      setMessage("");
      setMessSelectedImage(null);
      setIsReplying(false);
      setReplyMessageId(null);
    }
  };

  const editModelOpenFun = (item) => {
    setSelectedItem(item);
    const updatedState = {
      name: item.name || "",
      description: item.description || "",
      image: item.image || "",
    };
    setState(updatedState);
    setEditModel(true);
  };
  const imageModelOpenFun = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageModel(true);
  };

  const [loading, setLoading] = useState(false);
  const editModelCloseFun = () => setEditModel(false);
  const imageModelCloseFun = () => setImageModel(false);

  const deleteModelOpenFun = (item) => {
    setSelectedItem(item);
    setDeleteModel(true);
  };

  const deleteModelCloseFun = () => setDeleteModel(false);
  const [id, setId] = useState(null);
  const getProducts = async () => {
    setMainLoader(true);
    try {
      const response = await api.getWithToken(
        `${serverURL}admin-list-channels?page=${page}&limit=${limit}&search=${search}`
      );
      if (response?.data?.success) {
        setChannelList(response.data.data || []);
        if (response.data.data.length > 0) {
          setId(response.data.data[0]._id);
        }
      } else {
        setChannelList([]);
      }
    } catch (error) {
      console.error(
        "Error fetching products:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setMainLoader(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [page, limit, refreshData, temp, search, searchTemp]);
  const deleteItem = async (itemId) => {
    try {
      const response = await api.deleteWithToken(
        `${serverURL}admin-delete-channels?id=${itemId}`
      );
      getProducts();
      deleteModelCloseFun();
      const successMessage =
        typeof response?.data?.data?.message === "string"
          ? response?.data?.data?.message
          : "Channel Deleted successfully";
      toast.success(successMessage);
    } catch (error) {
      console.error("Error deleting item:", error.response || error.message);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };
  const validateFields = (updatedValues) => {
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requiredFields);

    if (updatedValues?.description?.trim().length === 0) {
      validationErrors.description = "Description cannot contain only spaces.";
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const fileInputRef = useRef(null);
  const messfileInputRef = useRef(null);

  const handleImageClick = () => fileInputRef.current.click();
  const handleMessImageClick = () => messfileInputRef.current.click();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
      setState((prev) => ({ ...prev, image: file }));
    }
  };
  const MesshandleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setMessSelectedImage(reader.result);
      reader.readAsDataURL(file);
      setState((prev) => ({ ...prev, image: file }));
    }
  };
  const handleFormSubmit = async () => {
    setSubmitCount((count) => count + 1);
    const validationErrors = validateFields(state);

    if (Object.keys(validationErrors).length === 0) {
      await submitRegimen(); // Ensure submitRegimen is awaited to handle asynchronous submission
    } else {
      const firstErrorKey = Object.keys(validationErrors)[0];
      document
        .getElementById(firstErrorKey)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitRegimen = async () => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
      formData.append(key, state[key]);
    });

    try {
      const response = await api.patchWithToken(
        `${serverURL}admin-update-channels?id=${selectedItem._id}`,
        formData
      );
      if (response?.data?.success) {
        handleSuccess(response);
      }
    } catch (error) {
      errorResponse(error); // Assuming errorResponse is your error handling function
    } finally {
      setLoading(false);
    }
  };
  const handleSuccess = (response) => {
    editModelCloseFun();
    setRefreshData(!refreshData);

    const successMessage =
      typeof response?.data?.data?.message === "string"
        ? response?.data?.data?.message
        : "Channel Updated successfully";
    toast.success(successMessage);
  };
  useEffect(() => {
    if (channelList.length > 0) {
      setSelectedItem(channelList[0]);
      setLoadingMessages(true);
    }
  }, [channelList]);

  return (
    <>
      <Row>
        <Col md={6} sm={12} lg={3}>
          <div className="card p-2">
            <div className="form-control d-flex align-items-center px-3 mb-3 input-bg">
              <img
                src="/images/svg/search.svg"
                className="imput-img"
                alt="search.svg"
              />
              <input
                className="form-control border-0 shadow-none pl-black"
                placeholder="Enter search text here"
                type="text"
                name="search"
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setSearchTemp(true);
                }}
              />
            </div>

            {channelList.length > 0 ? (
              channelList.map((item) => {
                return (
                  <div
                    className="card p-2 mb-2"
                    key={item?._id}
                    onClick={(e) => setSelectedItem(item)}
                  >
                    <div className="user-chat-box d-flex gap-3">
                      <img
                        src={`${imageUrl}${item.image}`}
                        alt=""
                        className="ml-2 channelUser"
                      />
                      <div style={{ width: "80%" }}>
                        <div className="d-flex align-content-center justify-content-between gap-3">
                          <h5 className="mb-0">{item?.name} </h5>
                          <span className="channel">
                            <img
                              src="/images/svg/channelEdit.svg"
                              className="channelIcon"
                              title="Edit"
                              alt=""
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents click event from bubbling up
                                editModelOpenFun(item);
                              }}
                            />
                            <img
                              src="/images/svg/channelDelete.svg"
                              className="channelIcon"
                              title="Delete"
                              alt=""
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents click event from bubbling up
                                deleteModelOpenFun(item);
                              }}
                            />
                          </span>
                        </div>
                        <h6 className="channelText mb-0 wordBreak">
                          {item?.description}
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p colSpan="3">No data available</p>
            )}
          </div>
        </Col>
        <Col sm={12} md={6} lg={9} className="mt-4 mt-md-0">
          <div className="card p-2 vh-100">
            {selectedItem && (
              <div className="d-flex align-items-center p-2 gap-2 msgBox">
                <img
                  src={`${imageUrl}${selectedItem?.image}`}
                  alt=""
                  className="msguser"
                />
                <span className="fs-20 fw-600">{selectedItem?.name}</span>
              </div>
            )}

            <div className="messages-container">
              {loadingMessages ? (
                <Loader />
              ) : messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div key={index} className="card my-2">
                    <div className="d-flex p-3">
                      <img
                        src={
                          msg.profile_image
                            ? imageUrl + msg.profile_image
                            : "/images/png/duser.png"
                        }
                        className="channelUser"
                        alt=""
                      />
                      <div className="w-100">
                        <div className="d-flex justify-content-between pl-2">
                          <span className="msgHead">{msg.username || "-"}</span>
                          <span>
                            {(() => {
                              const date = new Date(msg.created_at);
                              const day = String(date.getDate()).padStart(
                                2,
                                "0"
                              );
                              const month = String(
                                date.getMonth() + 1
                              ).padStart(2, "0"); // Months are 0-indexed
                              const year = date.getFullYear();
                              const hours = String(date.getHours()).padStart(
                                2,
                                "0"
                              );
                              const minutes = String(
                                date.getMinutes()
                              ).padStart(2, "0");
                              const ampm = hours >= 12 ? "PM" : "AM";
                              const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

                              return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                            })() || "Date not available"}
                          </span>
                        </div>

                        <div className="d-flex align-items-start" key={msg._id}>
                          {msg.image.length > 0 && msg.image ? (
                            <img
                              src={`${imageUrl}${msg.image}`}
                              onClick={() =>
                                imageModelOpenFun(`${imageUrl}${msg.image}`)
                              }
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                marginRight: "10px",
                              }}
                              alt=""
                            />
                          ) : (
                            <></>
                          )}

                          <span className="msgText pl-2 wordBreak1">
                            {msg.message}
                          </span>
                          <div className="d-flex justify-content-end pl-2 gap-2 align-items-center">
                            <div
                              className="reply-chip"
                              onClick={() => handleReplyToggle(msg._id)}
                            >
                              <span className="msgreplyText">
                                <span className="replyCount">
                                  {msg.totalReplies} reply
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        {replies[msg._id] && (
                          <div className="replies-section mt-2">
                            {replyData[msg._id]?.length > 0 ? (
                              replyData[msg._id].map((reply, index) => (
                                <div key={index} className="reply-card my-2">
                                  <div className="d-flex p-3">
                                    <img
                                      src={
                                        reply.sender_user.profile_image
                                          ? imageUrl +
                                            reply.sender_user.profile_image
                                          : "/images/png/duser.png"
                                      }
                                      className="channelUser"
                                      alt=""
                                    />
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between pl-2">
                                        <span className="msgHead">
                                          {reply.sender_user.username || "-"}
                                        </span>
                                        <span>
                                          {(() => {
                                            const date = new Date(
                                              reply.created_at
                                            );
                                            const day = String(
                                              date.getDate()
                                            ).padStart(2, "0");
                                            const month = String(
                                              date.getMonth() + 1
                                            ).padStart(2, "0"); // Months are 0-indexed
                                            const year = date.getFullYear();
                                            const hours = String(
                                              date.getHours()
                                            ).padStart(2, "0");
                                            const minutes = String(
                                              date.getMinutes()
                                            ).padStart(2, "0");
                                            const ampm =
                                              hours >= 12 ? "PM" : "AM";
                                            const formattedHours =
                                              hours % 12 || 12; // Convert 24-hour time to 12-hour time

                                            return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                                          })() || "Date not available"}
                                        </span>
                                      </div>
                                      <div className="reply-mess">
                                      {reply.image.length > 0 && reply.image ? (
                                        <img
                                          src={`${imageUrl}${reply.image}`}
                                          onClick={() =>
                                            imageModelOpenFun(
                                              `${imageUrl}${reply.image}`
                                            )
                                          }
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            marginRight: "10px",
                                          }}
                                          alt=""
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <span className="msgText pl-2 wordBreak1">
                                        {reply.message}
                                      </span>
                                      </div>
                                     
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                No replies available
                              </div>
                            )}
                            <div
                              className="reply-chip set-chip"
                              onClick={() => handleReplyButtonClick(msg._id)}
                            >
                              <span className="msgreplyText">
                                <span className="replyCount">Reply</span>
                              </span>
                            </div>
                            {isReplying && (
                              <>
                                <div className="d-flex justify-content-between replymsgView px-3 mb-2">
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src="/images/svg/pin.svg"
                                      alt=""
                                      onClick={handleMessImageClick}
                                    />
                                    <input
                                      type="file"
                                      ref={messfileInputRef}
                                      style={{ display: "none" }}
                                      accept="image/*"
                                      onChange={MesshandleFileChange}
                                    />
                                  </div>
                                  <input
                                    className="p-1 w-100 text-white fs-16 fw-500 bgMsg border-0"
                                    type="text"
                                    placeholder="Message"
                                    name="channel"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                  />
                                  <div className="d-flex align-items-center gap-3">
                                    {messselectedImage && (
                                      <img
                                        src={messselectedImage}
                                        alt="Selected Preview"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                    <span className="text-white fs-22 fw-500">
                                      @
                                    </span>
                                    <img
                                      src="/images/svg/send.svg"
                                      alt=""
                                      onClick={handleSubmitReplyMessage}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center mt-5">No messages available</div>
              )}
            </div>

            <div className="d-flex justify-content-between msgView px-3 mb-2">
              <div className="d-flex align-items-center gap-2">
                <img
                  src="/images/svg/pin.svg"
                  alt=""
                  onClick={handleMessImageClick}
                />
                <input
                  type="file"
                  ref={messfileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={MesshandleFileChange}
                />
              </div>
              <input
                className="p-1 w-100 text-white fs-16 fw-500 bgMsg border-0"
                type="text"
                placeholder="Message"
                name="channel"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="d-flex align-items-center gap-3">
                {messselectedImage && (
                  <img
                    src={messselectedImage}
                    alt="Selected Preview"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <span className="text-white fs-22 fw-500">@</span>
                <img
                  src="/images/svg/send.svg"
                  alt=""
                  onClick={handleSubmitMessage}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Edit user modal */}
      <Modal
        Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Channel</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="position-relative my-4">
              <div className="d-flex justify-content-center align-items-center m-auto">
                <img
                  src={
                    selectedImage ||
                    (state?.image && imageUrl + state?.image) ||
                    "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className="editprofileImage cursor-pointer"
                  alt=""
                />
              </div>

              <input
                type="file"
                accept="image/*"
                name="lecture_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <label>Name</label>
              <input
                className="form-control px-3"
                placeholder="Name"
                type="text"
                name="name"
                value={state?.name || ""}
                onChange={handleChange}
              />
              <div className="error text-danger mb-3">{errors?.name}</div>
            </Col>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Description"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <div className="error text-danger mb-3">
                {errors?.description}
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${
                Boolean(loading) && "btnDisable"
              }`}
              disabled={Boolean(loading)}
              onClick={handleFormSubmit}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={imageModel}
        onHide={() => setImageModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          {currentImage && (
            <img
              src={currentImage}
              alt="Modal Image"
              style={{ width: "100%", height: "600px" }}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Channel</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn border-0"
              onClick={() => deleteItem(selectedItem?._id)}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChannelContent;
