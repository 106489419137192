import React, { useState } from "react";
import { Col } from "react-bootstrap";
import MenstrualCycleTable from "../components/MenstrualCycleTable";
import Search from "../components/Search";
import Main from "../hoc/Main";

const MenstrualCycleTracker = () => {
  const [viewModel, setViewModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);

  return (
    <>
      <Main name="Menstrual Cycle Tracker">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Menstrual Cycle Tracker</h1>
          </Col>
          <Search />
        </div>
        <MenstrualCycleTable />
      </Main>
    </>
  );
};

export default MenstrualCycleTracker;
