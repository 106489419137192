import React, { useEffect, useRef, useState } from "react";
import Main from "../hoc/Main";
import { Col, Modal, Row } from "react-bootstrap";
import ReviewManagement from "../components/landing/ReviewManagement";
import ContactManagement from "../components/landing/ContactManagement";
import ExploreManagement from "../components/landing/ExploreManagement";
import BestForYou from "../components/landing/BestForYou";
import Working from "../components/landing/HowItWork";
import GetPlantBaseRecipes from "../components/landing/GetPlantBaseRecipes";
import SingleError from "../helper/SingleError";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getServerURL } from "../helper/envConfig";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import api from "../helper/api";
import HomeManage from "../components/ui-pages/HomeManage";
import AboutUsManagement from "../components/ui-pages/AboutUs";
import HelpSupportManagement from "../components/ui-pages/HelpSupport";
const homeRequireField = ["home_page_image", "title", "description"];
const aboutRequireField = ["description"];
const helpRequireField = ["title", "description"];
let activeRequire;

const UIPagesManagement = () => {

  const serverURL = getServerURL();
  const fileInputRef = useRef(null);

  const [homeModal, setHomeModal] = useState(false);
  const [aboutUsModal, setAboutUsModal] = useState(false);
  const [helpSupportModal, setHelpSupportModal] = useState(false);

  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const [activeTab, setActiveTab] = useState("home");
  const [countHome, setCountHome] = useState(0)
  const [countAbout, setCountAbout] = useState(0)
  const [countHelp, setCountHelp] = useState(0)


  // useEffect(()=>{
  //   console.log(countHome);
  // },[countHome])

  const handleTabChange = (tabName) => {
    setSearch("");
    setSearchTemp("")
    setTemp();
    setState({})
    setActiveTab(tabName);
  };

  const addModalOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({});
    setSelectedImage(null);
    if (activeTab === "home") {
      setHomeModal(true);
      activeRequire = homeRequireField;
    } else if (activeTab === "about") {
      setAboutUsModal(true);
      activeRequire = aboutRequireField;
    } else if (activeTab === "help") {
      setHelpSupportModal(true);
      activeRequire = helpRequireField
    }
  };

  const addModalCloseFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({});
    setSelectedImage(null);
    if (activeTab === "home") {
      setHomeModal(false);
    } else if (activeTab === "about") {
      setAboutUsModal(false);
    } else if (activeTab === "help") {
      setHelpSupportModal(false);
    }
  };

  const disableAutocomplete = (e) => {
    e.target.setAttribute("autocomplete", "off");
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, activeRequire);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    if (name === "address_title" || name === "address_description" || name === "address_contact") {
      setState((prevValues) => ({
        ...prevValues,
        address: {
          ...prevValues.address, // Spread the existing address values
          title: name === "address_title" ? value : prevValues?.address?.title ? prevValues?.address?.title : "",
          description: name === "address_description" ? value : prevValues?.address?.description ? prevValues?.address?.description : "",
          contact: name === "address_contact" ? value : prevValues?.address?.contact ? prevValues?.address?.contact : "",
        }
      }));
    } else {
      setState((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (state?.address?.contact) {
      if (!/^\+?\d+$/.test(state?.address?.contact)) {
        setErrors((prevValues) => ({
          ...prevValues,
          contact: "Please valid contact number",
        }));
      }
    }
  };

  const handleImagesChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, activeRequire);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files;
    if (file?.length) {
      const existingFiles = state?.home_page_image ? state[e.target.name] : [];
      const updatedFiles = existingFiles.concat(Array.from(file));
      setState({
        ...state,
        [e.target.name]: updatedFiles,
      });
    }
  };

  const removeSelectedImages = (index) => {
    const updatedImages = state?.home_page_image.filter((_, i) => i !== index);
    setState({
      ...state,
      ["home_page_image"]: updatedImages,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      if (activeTab === "home") {
        addHomeManagement();
      } else if (activeTab === "about") {
        addAboutUs();
      } else if (activeTab === "help") {
        // addWorking()
      }
    }
  };

  const addHomeManagement = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, homeRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "home-create");
      formData.append("title", state?.title);
      formData.append("description", state?.description);
      // formData.append("home_page_image", state?.home_page_image);
      if (Array.isArray(state?.home_page_image)) {
        state?.home_page_image?.forEach((subImage, index) => {
          formData.append(`home_page_image`, subImage);
        });
      } else {
        formData.append("home_page_image", state?.home_page_image);
      }
      try {
        api
          .postWithToken(
            `${serverURL}ui-page-manage`,
            formData
          )
          .then(async (res) => {
            // console.log(res);
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.data?.message === "string")
                toast.success(res?.data?.data?.message);
              else toast.success("Home created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  const addAboutUs = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, aboutRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "about-us-create");
      formData.append("description", state?.description);
      try {
        api
          .postWithToken(`${serverURL}ui-page-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("AboutUs detail created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const appendObjectToFormData = (obj, formData, prefix = '') => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === 'object') {
          appendObjectToFormData(obj[key], formData, propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  };

  const addHelpSupportManagement = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, helpRequireField);
    setErrors(validationErrors);

    if (state?.address?.contact) {
      if (!/^\+?\d+$/.test(state?.address?.contact)) {
        setErrors((prevValues) => ({
          ...prevValues,
          contact: "Please valid contact number",
        }));
      }
    }

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0 && Object.keys(errors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "help-support-create");
      // formData.append("title", state?.title);
      // formData.append("description", state?.description);
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          if (typeof state[key] === 'object') {
            // If the property is an object, stringify it before appending
            appendObjectToFormData(state[key], formData, key);
          } else {
            formData.append(key, state[key]);
          }
        }
      }

      try {
        api
          .postWithToken(
            `${serverURL}ui-page-manage`,
            formData
          )
          .then(async (res) => {
            // console.log(res);
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("HelpSupport entry created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  return (
    <>

      <Main name="UI Management">
        <div className="communityPage d-sm-block d-md-flex d-lg-flex justify-content-between mt-3">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => handleTabChange("home")}
              >
                Home Management
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-about-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-about"
                type="button"
                role="tab"
                aria-controls="pills-about"
                aria-selected="false"
                onClick={() => handleTabChange("about")}
              >
                About Us
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-help-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-help"
                type="button"
                role="tab"
                aria-controls="pills-help"
                aria-selected="false"
                onClick={() => handleTabChange("help")}
              >
                Help & Support
              </button>
            </li>

          </ul>

          <Col md={4} lg={4} xl={4} sm={12} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3 justify-content-end">
              {/* {Boolean(activeTab === "home") &&
                <div className="form-control d-flex align-items-center px-3">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                      fill="black"
                    />
                  </svg>
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Enter search text here"
                    type="text"
                    name="search"
                    value={search || ""}
                    autoComplete="off"
                    onFocus={disableAutocomplete}
                    onChange={(e) => {
                      setSearch(e?.target?.value);
                      setSearchTemp(true);
                    }}
                  />
                </div>
              } */}
              {(Boolean(activeTab === "home" && countHome === 0)
                || Boolean(activeTab === "about" && countAbout === 0)
                || Boolean(activeTab === "help" && countHelp === 0)) &&
                <div onClick={() => addModalOpenFun()} className="cursor-pointer">
                  <img src="/images/svg/add.svg" className="add-btn" alt="" />
                </div>}
            </div>
          </Col>
        </div>
        <div className="tab-content mt-2" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            {Boolean(activeTab === "home") && <HomeManage temp={temp} search={search} searchTemp={searchTemp} setCountHome={setCountHome} />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-about"
            role="tabpanel"
            aria-labelledby="pills-about-tab"
          >
            {Boolean(activeTab === "about") && <AboutUsManagement temp={temp} search={search} searchTemp={searchTemp} setCountAbout={setCountAbout} />}
          </div>
          <div
            className="tab-pane fade show "
            id="pills-help"
            role="tabpanel"
            aria-labelledby="pills-help-tab"
          >
            {Boolean(activeTab === "help") && <HelpSupportManagement temp={temp} search={search} searchTemp={searchTemp} setCountHelp={setCountHelp} />}
          </div>

        </div>
      </Main>

      {/* Add modal */}
      <Modal
        size="lg"
        show={homeModal}
        onHide={() => addModalCloseFun()}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Home Management</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">

          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

            <Col xs={12} lg={12} xl={12} className="pt-2 cst-card" id="home_page_image">
              {Boolean(state?.home_page_image?.length) &&
                <div className="preview-images mb-4 p-3 gap-3">
                  {state?.home_page_image?.map((image, index) => (
                    <div key={index} className="home-preview">
                      <img src={URL.createObjectURL(image)} alt={`Selected Preview image ${index}`} />
                      <button onClick={() => removeSelectedImages(index)} className="d-flex justify-content-center">
                        <img src="/images/svg/closeImg.svg" style={{ width: '8px', height: '16px' }} />
                      </button>
                    </div>
                  ))}
                </div>
              }
              <div className="upload__btn d-flex align-items-center justify-content-center py-2">
                <label className="d-flex align-items-center gap-1">
                  {!state?.home_page_image?.length && <span>+</span>}
                  <p className="mb-0" style={{ color: Boolean(state?.home_page_image?.length) && "#000" }}>
                    {Boolean(state?.home_page_image?.length)
                      ? state?.home_page_image?.length + " Images selected"
                      : "Upload Images"}
                  </p>
                  <input
                    multiple
                    type="file"
                    className="upload_inputfile"
                    name="home_page_image"
                    onChange={handleImagesChange}
                  />
                </label>
              </div>

              {Boolean(errors?.home_page_image) &&
                <SingleError error={errors?.home_page_image} mb={false} />
              }
            </Col>
          </Row>
          <div className="d-flex  justify-content-center mt-3">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addHomeManagement();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>


      {/* add about us */}
      <Modal
        size="lg"
        show={aboutUsModal}
        onHide={addModalCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add About Us</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter how it work description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                addAboutUs();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>


      {/* Add modal */}
      <Modal
        size="lg"
        show={helpSupportModal}
        onHide={() => addModalCloseFun()}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Help & Support</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">

          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

          </Row>
          <p className="help-label">Address</p>
          <Row>
            <Col md={12}>
              <label>
                Title
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="address_title"
                // value={state?.address_title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError />
            </Col>
            <Col md={12}>
              <label>
                Description
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="address_description"
                // value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError />
            </Col>
            <Col md={12}>
              <label>
                Contact No.
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="address_contact"
                // value={state?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.contact} mb={false} />
            </Col>
          </Row>

          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addHelpSupportManagement();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UIPagesManagement