import { getServerURL } from "../../helper/envConfig";

class CustomUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then(file => new Promise((resolve, reject) => {
            // Check if the input is a YouTube URL
            const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
            if (youtubeUrlRegex.test(file)) {
                // Handle YouTube URL
                resolve({
                    default: file // Return the YouTube URL as the media source
                });
            } else {
                // Allowed image types
                const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

                // Check if the file type is allowed
                if (!allowedImageTypes.includes(file.type)) {
                    // Reject the promise if the file type is not allowed
                    alert(`Unsupported image type: ${file.type}`);
                    return reject(`Unsupported image type: ${file.type}`);
                }

                const formData = new FormData();
                formData.append('chapter_images', file);

                // Use getServerURL function to dynamically set the URL
                const uploadURL = `${getServerURL()}upload-image`;

                fetch(uploadURL, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }
                })
                    .then(response => response.json())
                    .then(result => {
                        resolve({
                            default: result.url // Assuming the server responds with the URL of the uploaded image
                        });
                    })
                    .catch(reject);
            }
        }));
    }

    abort() {
        // Implementation for abort if necessary
    }
}


export default CustomUploadAdapter;
