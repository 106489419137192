import { ActionTypes } from "../ActionType";

const initialState = {}

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
      case ActionTypes.SET_LOGIN:
        return { ...action.payload };

      case ActionTypes.SET_LOGOUT:
        return { }
  
      default:
        return state;
    }
  }