import React, { useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";

const VirtualFitnessTable = () => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Image/Video</th>
              <th>Name</th>
              <th className="w-25">Descriptions</th>
              <th>Durations</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {[...Array(10)]?.map((item, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>
                  <img src="/images/png/square.png" />
                </td>
                <td>Deno James</td>
                <td>
                  <div className="wordBreak1 text-center align-middle">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit....
                  </div>
                </td>
                <td>15-05-2023</td>
                <td>15-05-2023</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <button
                      className="border-0 bg-transparent"
                      onClick={viewModelOpenFun}
                    >
                      <img src="/images/svg/show.svg" title="View" />
                    </button>
                    <button
                      className="border-0 bg-transparent"
                      onClick={editModelOpenFun}
                    >
                      <img src="/images/svg/edit.svg" title="Edit" />
                    </button>
                    <button
                      className="border-0 bg-transparent"
                      onClick={deleteModelOpenFun}
                    >
                      <img src="/images/svg/delete.svg" title="Delete" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          <TablePagination />
        </div>
      </Col>

      {/* View user modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            View Virtual Fitness
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="d-flex justify-content-center">
              <div>
                <Row>
                  <div className="position-relative my-4">
                    <div className="d-flex justify-content-center align-items-center m-auto ">
                      <img
                        src="/images/png/image12.png"
                        className="cursor-pointer editprofileImage"
                      />
                    </div>
                  </div>
                </Row>
                <div className="mb-3">
                  <tr>
                    <td className="userDetailsLabel">Name:</td>
                    &nbsp;
                    <td className="userDetailsText">John Leo</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Descriptions:</td>
                    &nbsp;
                    <td className="userDetailsText">
                      Lorem ipsum dolor sit amet, consectetur
                    </td>
                  </tr>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Edit Virtual Fitness
          </Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto">
                  <img
                    src="/images/png/image12.png"
                    className="cursor-pointer editprofileImage"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>

            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  defaultValue={"John leo"}
                />
              </Col>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control px-3 mb-3 fs-20 text-black"
                  placeholder="Description"
                  type="text"
                  name="Description"
                  defaultValue={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"
                  }
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Delete Virtual Fitness
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VirtualFitnessTable;
