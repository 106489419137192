import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { getServerURL } from "../helper/envConfig";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
const requireField = ["videoUrl"];

const SubscriptionTable = ({ temp, search, searchTemp }) => {

  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);


  const [deleteVideoID, setDeleteVideoID] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalPages, SetTotalPages] = useState();

  const [videoList, setVideoList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editVideoLink, setEditVideoLink] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true)

  // useEffect(() => {
  //   getVideoLinkList();
  // }, [page]);

  // useEffect(() => {
  //   if (temp) {
  //     getVideoLinkList();
  //   }
  // }, [temp]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editVideoLink,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditVideoLink((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const getVideoLinkList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}subscription-plans-manage`,
          { action: "list" }
        )
        .then(async (res) => {
          // console.log(res);
          setMainLoader(false)
          if (res?.data?.success === true) {
            setVideoList(res?.data?.data);
            // setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          setMainLoader(false)
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setVideoList([])
          }
          // errorResponse(e);
        });
    } catch (error) {
      // console.log(error);
    }
  };


  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th> Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {[...Array(10)]?.map((item, i) => (
              <tr key={i + "subscriptionTable"}>
                <td>1</td>
                <td>Deno James</td>
                <td>denoj</td>
                <td>
                  <div className="form-switch userTable pt-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked
                    />
                  </div>
                </td>
                <td>15-05-2023</td>
                <td>15-05-2023</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <button
                      className="border-0 bg-transparent"
                      onClick={viewModelOpenFun}
                    >
                      <img src="/images/svg/show.svg" title="View" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          <TablePagination />
        </div>
      </Col>

      {/* View modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Subscription Details
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="d-flex justify-content-center">
              <div>
                <div className="mb-3">
                  <tr>
                    <td className="userDetailsLabel">Name:</td>
                    &nbsp;
                    <td className="userDetailsText">John Leo</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Type:</td>
                    &nbsp;
                    <td className="userDetailsText">johnleo</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Status:</td>
                    &nbsp;
                    <td className="userDetailsText">On</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Start Date</td>
                    &nbsp;
                    <td className="userDetailsText">15-05-2023</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">End Date</td>
                    &nbsp;
                    <td className="userDetailsText">15-05-2023</td>
                  </tr>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Subscription</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  defaultValue={"joghn leo"}
                />
              </Col>
              <Col md={12}>
                <label>Type</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Type"
                  type="text"
                  name="name"
                  defaultValue={"john"}
                />
              </Col>
              <Col md={12}>
                <label>Date</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="date"
                  type="date"
                  name="date"
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Save
              </button>
            </div>
          </Form>
          {/* <div className="mx-md-4 border-0 bg-transparent mt-2 mb-3">
            <div className="d-flex justify-content-center text-center mt-4">
              <Button className="modelBtn">Save</Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Subscription</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            {/* <button
              className="modalCancelBtn px-5 border-0"
              onClick={() => deleteModelCloseFun}
            >
              Cancel
            </button> */}
            <button className="modalDeleteBtn border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionTable;
