import { ActionTypes } from "../ActionType";

export const setLogin = (payload) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.SET_LOGIN,
            payload
        });
    }

    export const setLogout = () =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.SET_LOGOUT,
        });
    }