import { ActionTypes } from "../ActionType";

export const setChapterIndex = (payload) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CHAPTER_INDEX,
    payload,
  });
};

export const setLectureIndex = (payload) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LECTURE_INDEX,
    payload,
  });
};
