import React, { useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";

const RevenueMonthlyTable = () => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Name</th>
              <th>Plan</th>
              <th>Subscription Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {[...Array(10)]?.map((item, i) => (
              <tr key={i}>
                <td>1</td>
                <td>Deno James</td>
                <td>loreum ipsum</td>
                <td>₹ 6000/-</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <button
                      className="border-0 bg-transparent"
                      onClick={viewModelOpenFun}
                    >
                      <img src="/images/svg/show.svg" title="View" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          <TablePagination />
        </div>
      </Col>

      {/* View user modal */}
      {/* <Modal
                size="lg"
                show={viewModel}
                onHide={viewModelCloseFun}
                centered
                className="modal-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    <Modal.Title className="text-center">Content Details</Modal.Title>
                    <div className="d-flex justify-content-center text-center mt-2">
                        <img src="/images/svg/line.svg" />
                    </div>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0cmt-3">

                    <Row>
                        <div className="position-relative">
                            <div className="d-flex justify-content-center m-auto edit-user">
                                <img
                                    src="/images/png/image12.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row className="px-5 pb-3">
                        <div className="d-flex justify-content-center">
                            <div>
                                <tr>
                                    <td className="userDetailsLabel">Name:</td>
                                    &nbsp;
                                    <td className="userDetailsText">John Leo</td>
                                </tr>
                                <tr>
                                    <td className="userDetailsLabel">Email:</td>
                                    &nbsp;
                                    <td className="userDetailsText">johnleo@gmail.com</td>
                                </tr>
                                <tr>
                                    <td className="userDetailsLabel">Description:</td>
                                    &nbsp;
                                    <td className="userDetailsText">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    </td>
                                </tr>
                            </div>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal> */}
    </>
  );
};

export default RevenueMonthlyTable;
