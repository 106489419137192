import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";

const UserTable = () => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <Col className="ctmTable mt-1">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>User Name</th>
              <th>Email ID</th>
              <th>Status</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            <tr>
              <td>1</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>

              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" title="View" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" title="Edit" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" title="Delete" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Alisa Dunk</td>
              <td>adunk@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" title="View" alt="edit" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" title="Edit" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" title="Delete" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Alisa Dunk</td>
              <td>adunk@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Alisa Dunk</td>
              <td>adunk@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Alisa Dunk</td>
              <td>adunk@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Alisa Dunk</td>
              <td>adunk@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>Alisa Dunk</td>
              <td>adunk@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>Deno James</td>
              <td>denojames@gmail.com</td>
              <td>
                <div className="form-switch userTable pt-1">
                  <input className="form-check-input" type="checkbox" checked />
                </div>
              </td>
              <td>15-05-2023</td>
              <td>
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <button
                    className="border-0 bg-transparent"
                    onClick={viewModelOpenFun}
                  >
                    <img src="/images/svg/show.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={editModelOpenFun}
                  >
                    <img src="/images/svg/edit.svg" />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={deleteModelOpenFun}
                  >
                    <img src="/images/svg/delete.svg" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          <TablePagination />
        </div>
      </Col>

      {/* View user modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">User Details</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="d-flex justify-content-center">
              <div>
                <Row>
                  <div className="position-relative my-4">
                    <div className="d-flex justify-content-center align-items-center m-auto ">
                      <img
                        src="/images/png/image12.png"
                        className="editprofileImage cursor-pointer"
                      />
                    </div>
                  </div>
                </Row>
                <div className="mb-3">
                  <tr>
                    <td className="userDetailsLabel">Name:</td>
                    &nbsp;
                    <td className="userDetailsText">John Leo</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Email:</td>
                    &nbsp;
                    <td className="userDetailsText">johnleo@gmail.com</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Status:</td>
                    &nbsp;
                    <td className="userDetailsText">Block</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Contact No:</td>
                    &nbsp;
                    <td className="userDetailsText">1234567890</td>
                  </tr>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit User</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto">
                  <img
                    src="/images/png/image12.png"
                    className="editprofileImage"
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  defaultValue={"joghn leo"}
                />
              </Col>
              <Col md={12}>
                <label>Email</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Email"
                  type="text"
                  name="name"
                  defaultValue={"john52@gmail.com"}
                />
              </Col>
              <Col md={12}>
                <label>Contact No</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Contact"
                  type="text"
                  name="contact"
                  defaultValue={"1234569870"}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Save
              </button>
            </div>
          </Form>
          {/* <div className="mx-md-4 border-0 bg-transparent mt-2 mb-3">
            <div className="d-flex justify-content-center text-center mt-4">
              <Button className="modelBtn">Save</Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete User</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            {/* <button
              className="modalCancelBtn px-5 border-0"
              onClick={() => deleteModelCloseFun}
            >
              Cancel
            </button> */}
            <button className="modalDeleteBtn border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserTable;
