import { Suspense, useState } from "react";
import "./App.css";
import "./assets/font/font.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import route from "./helper/route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/custom-toast.css";
import TrackingCategory from "./pages/TrackingCategory";
import TrackingSubCategory from "./pages/TrackingSubCategory";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Suspense fallback={<div></div>}>
          <Routes>
            {route?.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              );
            })}

            {/* <Route path="/product/:id" render={(props) => <Product {...props} products={products} />} /> */}
            <Route path="/tracking/:slug" element={<TrackingCategory />} />
            <Route path="/tracking/:data/:slug" element={<TrackingSubCategory />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
