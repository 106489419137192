import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { useNavigate } from "react-router-dom";

const LegsList = () => {
  const navigate = useNavigate();

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <div className="card mt-3 px-3 py-2">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image1.png" className="foodImg" />
                  <span className="userText">Crunches</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image2.png" className="foodImg" />
                  <span className="userText">V-Ups</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image3.png" className="foodImg" />
                  <span className="userText">Plank</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image4.png" className="foodImg" />
                  <span className="userText">Sit Ups</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image5.png" className="foodImg" />
                  <span className="userText">Bicycle Crunches</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image6.png" className="foodImg" />
                  <span className="userText">Hemp Seeds</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image7.png" className="foodImg" />
                  <span className="userText">Russian Twists</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image8.png" className="foodImg" />
                  <span className="userText">Flutter Kicks</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image9.png" className="foodImg" />
                  <span className="userText">leg Raises</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image10.png" className="foodImg" />
                  <span className="userText">Crunches</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image11.png" className="foodImg" />
                  <span className="userText">Mountain Climbers</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2">
            <div className="foodLayout p-2">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div>
                  <img src="/images/abs/image12.png" className="foodImg" />
                  <span className="userText">Bicycle Crunches</span>
                </div>
                <button
                  onClick={deleteModelOpenFun}
                  className="border-0 bg-transparent"
                >
                  <img src="/images/svg/delete.svg" title="Delete" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-flex justify-content-between align-items-center mt-4">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() => navigate("/bodyexercise")}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        <TablePagination />
      </div>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Legs</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto">
                  <img
                    src="/images/png/image12.png"
                    className="editprofileImage cursor-pointer"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="position-absolute top-50 start-50 translate-middle cursor-pointer"
                  alt=""
                />
              </div>
            </Row>

            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  defaultValue={"John leo"}
                />
              </Col>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control px-3 mb-3 fs-20 text-black"
                  placeholder="Description"
                  type="text"
                  name="Description"
                  defaultValue={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"
                  }
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Legs </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            {/* <button
              className="modalCancelBtn px-5 border-0"
              onClick={() => deleteModelCloseFun}
            >
              Cancel
            </button> */}
            <button className="modalDeleteBtn border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LegsList;
