import React, { useRef, useState,useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Main from "../hoc/Main";
import MessageContent from "../components/MessageContent";
import ChannelContent from "../components/ChannelContent";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import { errorResponse } from "../helper/error";
import ErrorFilter from "../helper/errorFilter";
import { toast } from "react-toastify";
import api from "../helper/api";

const requireField = ["name", "description"];

const Community = () => {
  const [addModal, setAddModal] = useState(false);
  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const fileInputRef = useRef(null);
  const url = getImageURL();
  const serverURL = getServerURL();
  const [refreshData, setRefreshData] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (submitCount > 0) {
      const validationErrors = ErrorFilter(
        ValidateFields({ ...state, [name]: value }),
        requireField
      );
      setErrors(validationErrors);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);

      setState((prevState) => ({
        ...prevState,
        image: file,
      }));
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
      Object.entries(validationErrors).forEach(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
      return; // Stop submission if there are validation errors
    }

    setLoading(true);

    // Retrieve token from localStorage
    const token = localStorage.getItem('token');

    if (!token) {
      setLoading(false);
      toast.error("Authentication token is missing");
      return;
    }

    const formData = new FormData();
    formData.append('name', state.name);
    formData.append('description', state.description);
    if (state.image) {
      formData.append('image', state.image);
    }

    api
      .postWithToken(`${serverURL}admin-create-channels`, formData, token, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async (res) => {
        setLoading(false);
        if (res?.data?.success === true) {
          setAddModal(false);
          setTemp(true);
          
          setRefreshData(!refreshData);
          toast.success(res?.data?.data?.message || "Channel  created successfully");
        } else {
          toast.error(res?.data?.message || "Something went wrong");
        }
      })
      .catch((e) => {
        setLoading(false);
        errorResponse(e);
      });
  };
  const [data ,setData]=useState([])
  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const response = await api.getWithToken(`${serverURL}admin-list-channels`);
        if (response?.data?.success) {
          setData(response?.data?.data?.records || []);
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [refreshData]);
  return (
    <>
      <Main name="Community">
        <div className="communityPage d-sm-block d-md-flex d-lg-flex justify-content-between mt-3">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-channels-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-channels"
                type="button"
                role="tab"
                aria-controls="pills-channels"
                aria-selected="true"
              >
                Channels
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-message-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-message"
                type="button"
                role="tab"
                aria-controls="pills-message"
                aria-selected="false"
              >
                Messages
              </button>
            </li>
          </ul>
          <Col md={4} lg={4} xl={4} sm={12} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Search"
                  type="text"
                />
              </div>
              <div
                onClick={() => setAddModal(true)}
                className="cursor-pointer"
              >
                <img
                  src="/images/svg/add.svg"
                  className="add-btn"
                  alt="Add"
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="tab-content mt-2" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-channels"
            role="tabpanel"
            aria-labelledby="pills-channels-tab"
          >
            <ChannelContent refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp} setSearch={setSearch} setSearchTemp={setSearchTemp}/>
          </div>
          <div
            className="tab-pane fade"
            id="pills-message"
            role="tabpanel"
            aria-labelledby="pills-message-tab"
          >
            <MessageContent />
          </div>
        </div>
      </Main>

      {/* Add modal */}
      <Modal
        size="lg"
        show={addModal}
        onHide={() => setAddModal(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Channel</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="Divider" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="position-relative my-4">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : state?.image
                      ? url + state?.image
                      : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className="editprofileImage cursor-pointer"
                  alt="Profile"
                />
              </div>

              <input
                type="file"
                accept="image/*"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt="Camera Icon"
              />
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <label>Name</label>
              <input
                className="form-control px-3 mb-3"
                placeholder="Name"
                type="text"
                name="name"
                value={state.name || ''}
                onChange={handleChange}
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </Col>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={3}
                className="form-control px-3 mb-3"
                placeholder="Description"
                name="description"
                value={state.description || ''}
                onChange={handleChange}
              />
              {errors.description && (
                <div className="text-danger">{errors.description}</div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center border-0">
          <button
            className="btn mx-2 bg-yellow border-0  py-2 px-4"
            onClick={() => {
                handleSubmit();
                setSubmitCount(1);
              }}
            
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Community;
