import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "./TablePagination";
import api from "../helper/api";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import Loader from "./loader/Loader";
import slugify from '../helper/slugify'
import SingleError from "../helper/SingleError";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { debounce } from "lodash";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapter from "../components/adapterclass/CustomUploadAdapter";

const requireField = ["image", "title", "duration", "content"];

const TrackingSubCategoryContent = ({ temp, search, searchTemp, trackingCategory, tracking }) => {

  const category_id = trackingCategory?._id
  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);

  const [viewModel, setViewModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalPages, SetTotalPages] = useState();

  const [trackingList, setTrackingList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editTrackingData, setEditTrackingData] = useState({});
  const [deleteId, setDeleteID] = useState('')
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);
  const [editData, setEditData] = useState({})

  // useEffect(() => {
  //   getTrackingList();
  // }, []);

  // useEffect(() => {
  //   getTrackingList();
  // }, [page]);

  // useEffect(() => {
  //   if (temp || Boolean(search)) {
  //     getTrackingList();
  //   }
  //   if (searchTemp && !search) {
  //     getTrackingList();
  //   }
  // }, [temp, search]);

  // useEffect(() => {
  //   getTrackingList();
  // }, []);

  useEffect(() => {
    getTrackingList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getTrackingList();
    }
    if (searchTemp && !search) {
      getTrackingList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editTrackingData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditTrackingData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editTrackingData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditTrackingData({
        ...editTrackingData,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const getTrackingList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}sub-category-manage?page=${page}&limit=${limit}&search=${search}`,
          { action: 'list', category_id }
        )
        .then(async (res) => {
          console.log(res);
          setMainLoader(false);
          if (res?.data?.success === true) {
            setTrackingList(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          // console.log(e);
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setTrackingList([]);
          }
        });
    } catch (error) {
      setMainLoader(false);
      // console.log(error);
    }
  };

  const deleteTracking = () => {
    try {
      api
        .postWithToken(
          `${serverURL}sub-category-manage?subcategory_id=${deleteId}`,
          { action: "delete" }
        )
        .then(async (res) => {
          // console.log(res);
          setDeleteDataID("");
          getTrackingList();
          if (trackingList?.length == 1) {
            setTrackingList([]);
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Tracking deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const appendObjectToFormData = (obj, formData, prefix = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === "object") {
          appendObjectToFormData(obj[key], formData, propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  };

  const editSubTracking = () => {
    const updatedValues = { ...editTrackingData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (editTrackingData?.description && editTrackingData?.description?.trim()?.length === 0) {
      validationErrors.description="Description cannot be contain only spaces.";
    }

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("image", editTrackingData?.image);
      formData.append("subCategoryId", editTrackingData?._id);
      for (const key in editTrackingData) {
        if (editTrackingData.hasOwnProperty(key)) {
          if (typeof editTrackingData[key] === "object") {
            appendObjectToFormData(editTrackingData[key], formData, key);
          } else {
            formData.append(key, editTrackingData[key]);
          }
        }
      }
      try {
        api
          .postWithToken(
            `${serverURL}sub-category-manage`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            // console.log(res);
            if (res?.data?.success === true) {
              getTrackingList();
              editModelCloseFun();
              setSelectedImage("");
              if (res?.data?.success === true) {
                if (typeof res?.data?.message === "string")
                  toast.success(res?.data?.message);
                else toast?.success("Lecture Updated successfully");
              }
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  const finalContent = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
     
      <style>
      .custom-html body {
          padding: 20px;
      }
      .custom-html figure img {
          width: 100%;
          height: 100%;
      }
      .custom-html p {
          word-break: break-all;
          font-size: 18.300px;
          color: #555;
          font-weight: 500;
      }
      .custom-html ul {
          padding: 0px;
      }
      .custom-html ul {
        background-color: rgba(0, 182, 182, 0.04);
        border: 1px solid rgba(0, 182, 182, 1);
        border-radius: 8px;
        padding: 15px;
      }
      .custom-html blockquote {
        border-left: 5px solid #ccc;
        font-style: italic;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
      .custom-html strong {
        font-weight: 900;
      }
       .custom-html h1,
       .custom-html h2,
       .custom-html h3,
       .custom-html h4,
       .custom-html h5,
       .custom-html h6 {
          margin-top: 15px;
      }
      .custom-html ul li {
        font-size: 18.300px;
        color: #555;
        font-weight: 500;
        margin-left: 1.5rem !important;
      }
      .custom-html body::-webkit-scrollbar{
          display:none;
      }
  </style>
  </head>
  <body>
  ${editData?.content}
  </body>
  </html>
  `;

  function handleNavigate(title, item) {
    navigate(`/tracking/${slugify(tracking?.title)}`, {
      state: { title: tracking?.title, item: tracking },
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      editSubTracking();
    }
  };

  const handleEditorChange = debounce((event, editor) => {
    const data = editor.getData();
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editTrackingData, content: data });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors["content"];
      }
    }
    setEditTrackingData({ ...editTrackingData, content: data });
  }, 300);

  const editorConfiguration = {
    extraPlugins: [CustomAdapterPlugin],
    mediaEmbed: {
      previewsInData: true,
    },

    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        // Ensure "link" is not included here
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "|",
        "imageUpload",
        "|",
        "mediaEmbed",
      ],
    },

    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
      resizeUnit: "px",
    },
  };

  function CustomAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
    };
  }

  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) : Boolean(trackingList?.length) ?
        <>
          <div className="card mt-3 px-3 py-2">
            <div className="row">
              {Array.isArray(trackingList) &&
                Boolean(trackingList?.length) &&
                trackingList?.map((item, index) =>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 py-2 cursor-pointer" key={index + "subItem"} onClick={() => { setEditData(item); viewModelOpenFun() }}>
                    <div className="foodLayout p-2">
                      <div className="d-flex align-items-center">
                        <img src={url + item?.image} className="foodImg" />
                        {/* <div className="w-100"> */}
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <p className="subCategoryTitle text-capitalize wordBreak1 pb-0 mb-0" title={item?.title}>
                              {item?.title}
                            </p>
                            <div className="lectureIcon">
                              <button
                                className="border-0 bg-transparent"
                                // onClick={editModelOpenFun}
                                onClick={(e) => { e.stopPropagation(); editModelOpenFun(); setEditTrackingData(item) }}
                              >
                                <img src="/images/svg/edit.svg" title="Edit" />
                              </button>
                              <button
                                onClick={(e) => { e.stopPropagation(); deleteModelOpenFun(); setDeleteID(item?._id) }}
                                className="border-0 bg-transparent"
                              >
                                <img src="/images/svg/delete.svg" title="Delete" />
                              </button>
                            </div>
                          {/* </div> */}
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-between align-items-center gap-1">
                        <div>
                          <img src={url + item?.image} className="foodImg" />
                          <span className="userText text-capitalize">{item?.title}</span>
                        </div>
                        <div>
                          <button
                            className="border-0 bg-transparent"
                            // onClick={editModelOpenFun}
                            onClick={(e) => { e.stopPropagation(); editModelOpenFun(); setEditTrackingData(item) }}

                          >
                            <img src="/images/svg/edit.svg" title="Edit" />
                          </button>
                          <button
                            onClick={(e) => { e.stopPropagation(); deleteModelOpenFun(); setDeleteID(item?._id) }}
                            className="border-0 bg-transparent"
                          >
                            <img src="/images/svg/delete.svg" title="Delete" />
                          </button>
                        </div>

                      </div> */}
                    </div>
                  </div>)}
            </div>
          </div>

        </>
        : (
          <div className="mt-5 w-100 p-5 d-flex justify-content-center">
            <h4 className="test-center">No Records Found.</h4>
          </div>
        )}

      <div className="d-md-flex justify-content-between align-items-center mt-4 mb-3">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() => handleNavigate()}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        {Boolean(trackingList?.length) && paginationData?.totalPages > 1 && (
          <TablePagination
            currentPage={paginationData?.currentPage}
            totalPages={paginationData?.totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        )}
      </div>

      {/* View  modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal pl-0"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View {trackingCategory?.title}</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="s-img" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="lecture_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={url + editData?.image}
                  className={`editprofileImage`}
                />
              </div>
            </div>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-11 px-0">
              <div className="d-flex justify-content-center">
                <div className="my-4">
                  <tr>
                    <td className="userDetailsLabel">Title :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {editData?.title || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Description :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {editData?.description || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Time Duration :</td>
                    &nbsp;
                    <td className="userDetailsText one-line-text">
                      {editData?.duration || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Content :</td>
                  </tr>
                  <div
                    className="custom-html"
                    dangerouslySetInnerHTML={{ __html: finalContent }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit {trackingCategory?.title}</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : editTrackingData?.image
                        ? url + editTrackingData?.image
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : editTrackingData?.image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                // className="editprofileImage cursor-pointer"
                />
              </div>

              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter sub tracking category title here"
                type="text"
                name="title"
                value={editTrackingData?.title || ""}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col>

            <Col md={12}>
              <label id="description">
                Description
              </label>
              <textarea
                className="form-control px-3"
                placeholder="Enter sub tracking category description here"
                type="text"
                row={3}
                name="description"
                value={editTrackingData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

            <Col md={12}>
              <label id="duration">
                Time <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter sub tracking category time duration here (e.g., 10 min)"
                type="text"
                name="duration"
                value={editTrackingData?.duration || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.duration} />
            </Col>

            <Col md={12}>
              <label id="content">
                Content <span className="star">*</span>
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={editTrackingData?.content || ""}
                onChange={handleEditorChange}
                config={editorConfiguration}
              />
              <SingleError error={errors?.content} mb={false} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                editSubTracking();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Update"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete ABS </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn border-0" onClick={() => { deleteTracking(deleteId); deleteModelCloseFun() }}>Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrackingSubCategoryContent;
