import React from "react";
import VirtualFitnessTable from "../components/VirtualFitnessTable";
import { Col } from "react-bootstrap";
import Search from "../components/Search";
import Main from "../hoc/Main";

const VirtualFitness = () => {
  return (
    <>
      <Main name="Virtual Fitness">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Virtual Fitness</h1>
          </Col>
          <Search />
        </div>
        <VirtualFitnessTable />
      </Main>
    </>
  );
};

export default VirtualFitness;
