import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { encryptAndStoreLoc } from "../helper/localStorage";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const serverURL = getServerURL();

  useEffect(() => {
    localStorage.removeItem("otpTimer");
    localStorage.removeItem("resetpass");
    localStorage.removeItem("verify");
  }, [])

  const handelSubmit = async (e) => {
    localStorage.removeItem("otpTimer");
    // localStorage.removeItem("otpTimerStart");
    // localStorage.removeItem("resetpass");
    // localStorage.removeItem("verify");
    try {
      if (!email.trim()) {
        toast.error("Please enter email");
        return;
      }

      if (!validator.isEmail(email.trim())) {
        toast.error("Please enter valid email address");
        return;
      }
      setLoading(true);
      api
        .post(`${serverURL}resendotp`, { email })
        .then(async (res) => {
          if (res?.data?.success === true) {
            encryptAndStoreLoc("email", email);
            encryptAndStoreLoc("resetpass", "True");
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast.success("OTP send successfully!");
            navigate("/verification");
          } else if (res?.data?.success === false) {
            if (typeof res?.data?.message === "string")
              toast.error(res?.data?.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          if (e?.response?.data?.success === false) {
            if (typeof e?.response?.data?.message === "string")
              toast.error(e?.response?.data?.message);
          } else if (e?.code === "ERR_NETWORK") {
            if (typeof e?.message === "string") toast.error(e?.message);
          } else toast.error("Something Wrong! Please try again!");
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handelSubmit(e);
    }
  };

  return (
    <>
      <section className="d-flex justify-content-center align-items-center vh-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col className="col-12 col-md-7 col-lg-6 col-xl-5">
              <Card className="p-5">
                <Card.Img
                  variant="top"
                  className="login-logo m-auto"
                  src="/images/logo/logo.png"
                />
                <p className="login-title">Forgot Password?</p>
                <Card.Body className="px-0 px-md-3">
                  <div className="form-control d-flex align-items-center px-3 mb-3 input-bg ctmInput">
                    <img
                      src="/images/svg/sms.svg"
                      className="imput-img"
                      alt="sms.svg"
                    />
                    <input
                      className="form-control login-input border-0"
                      placeholder="Enter your email here"
                      type="text"
                      name="email"
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setEmail(e?.target.value)}
                    />
                  </div>
                  <button
                    disabled={loading}
                    className={`btn-login bg-main text-uppercase border-0 w-100 mt-4 ${Boolean(loading) && "btnDisable"
                      }`}
                    onClick={() => handelSubmit()}
                  >
                    {Boolean(loading) ? "Loading... " : "Send OTP"}
                  </button>
                  <div className="d-flex justify-content-center mt-4 align-items-center gap-2">
                    <Link to="/login" className="text-decoration-none back-login">Back to Login </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgotPassword;
