import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "../TablePagination";
import { getImageURL, getServerURL } from "../../helper/envConfig";
import ErrorFilter from "../../helper/errorFilter";
import { ValidateFields } from "../validate/ValidateFields";
import SingleError from "../../helper/SingleError";
import api from "../../helper/api";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
const requireField = ["recipes_images", "title"];

const GetPlantBaseRecipes = ({ temp, search, searchTemp, setCountRecipes }) => {

  const serverURL = getServerURL();
  const imageURL = getImageURL();
  const fileInputRef = useRef(null);

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const editModelOpenFun = () => {
    setSelectedImage(null);
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const [paginationData, setPaginationData] = useState({});
  const [editRecipeData, setEditRecipeData] = useState({});
  const [recipeData, setRecipeData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getRecipeList();
  }, []);

  useEffect(() => {
    getRecipeList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getRecipeList();
    }
    if (searchTemp && !search) {
      getRecipeList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editRecipeData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditRecipeData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editRecipeData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditRecipeData({
        ...editRecipeData,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      editRecipe();
    }
  };

  const getRecipeList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}landing-page-manage?page=${page}&limit=${limit}&search=${search}`,
          { action: "recipes_get" }
        )
        .then(async (res) => {
          setMainLoader(false);
          // console.log(res?.data?.data?.length === 4);
          if (res?.data?.success === true) {
            if (Boolean(res?.data?.data?.length === 4)) setCountRecipes(1)
            setRecipeData(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setRecipeData([]);
            if (Boolean(page != 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
      setMainLoader(false);
    }
  };

  const editRecipe = () => {
    const updatedValues = { ...editRecipeData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", editRecipeData?._id);
      formData.append("action", "recipes_update");
      formData.append("title", editRecipeData?.title);
      formData.append("recipes_images", editRecipeData?.recipes_images);
      try {
        api
          .postWithToken(
            `${serverURL}landing-page-manage`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              getRecipeList();
              editModelCloseFun();
              setSelectedImage("");
              if (res?.data?.success === true) {
                if (typeof res?.data?.message === "string")
                  toast?.success(res?.data?.message);
                else toast?.success("Recipes Updated successfully");
              }
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const deleteRecipe = () => {
    try {
      api
        .postWithToken(
          `${serverURL}landing-page-manage`,
          { action: "recipes_delete", id: deleteDataID }
        )
        .then(async (res) => {
          setDeleteDataID("");
          getRecipeList();
          setCountRecipes(0)
          if (recipeData?.length == 1) {
            setRecipeData([])
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Review deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };


  return (
    <>
      <Col className="ctmTable my-3">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th className="w-15">Image</th>
              <th className="w-50">Title</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {Boolean(mainLoader) ?
              <Loader /> :
              Boolean(recipeData?.length) ?
                recipeData?.map((item, i) => (
                  <tr key={i + "contentTable"}>
                    <td>{i + 1}</td>
                    <td>
                      <img src={imageURL + item?.recipes_images} className="recipes-img" />
                    </td>
                    <td className="fullTextShow text-center px-2">{item?.title || "-"}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => { viewModelOpenFun(); setEditRecipeData(item) }}
                        >
                          <img src="/images/svg/show.svg" title="View" />
                        </button>
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => { editModelOpenFun(); setEditRecipeData(item) }}
                        >
                          <img src="/images/svg/edit.svg" title="Edit" />
                        </button>
                        <button className="border-0 bg-transparent"
                          onClick={() => { deleteModelOpenFun(item?._id); setDeleteDataID(item?._id) }}
                        >
                          <img src="/images/svg/delete.svg" title="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={5} className="p-5">
                    No Records Founds.
                  </td>
                </tr>
            }
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          {Boolean(recipeData?.length) && paginationData?.totalPages > 1 && (
            <TablePagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </div>
      </Col>

      {/* View modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Recipes Details</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0cmt-3">
          <Row>
            <div className="position-relative">
              <div className="d-flex justify-content-center m-auto edit-user">
                <img src={editRecipeData?.recipes_images && imageURL + editRecipeData?.recipes_images} className="cursor-pointer" />
              </div>
            </div>
          </Row>
          <Row className="px-5 pb-3 mt-3">
            <div className="d-flex justify-content-center">
              <div>
                <tr>
                  <td className="userDetailsLabel">Title:</td>
                  &nbsp;
                  <td className="userDetailsText fullTextShow"><div>{editRecipeData?.title}</div></td>
                </tr>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Recipes</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="recipes_images">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    editRecipeData?.recipes_images
                      ? typeof editRecipeData?.recipes_images === "string"
                        ? imageURL + editRecipeData?.recipes_images
                        : editRecipeData?.recipes_images?.type
                          ? URL.createObjectURL(editRecipeData?.recipes_images)
                          : "/images/png/duser.png"
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : editRecipeData?.recipes_images
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                />
              </div>
              <input
                type="file"
                // accept="image/*"
                accept=".jpg, .jpeg, .png"
                name="recipes_images"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.recipe_image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.recipe_image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter chapter title here"
                type="text"
                name="title"
                value={editRecipeData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                editRecipe();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Recipes</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28  fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn px-5 border-0" onClick={() => deleteRecipe()}>Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GetPlantBaseRecipes;
