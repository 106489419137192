import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import SingleError from "../../helper/SingleError";
import api from "../../helper/api";
import { getImageURL, getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";

const Faqs = () => {
    const serverURL = getServerURL();
    const imageURL = getImageURL();
    const [faqList, setFaqList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shoModal, setShowModal] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [errors, setErrors] = useState({});
    const [editData, setEditData] = useState();
    const [disabled, setDisabled] = useState(true);
    const [newImage, setNewImage] = useState();

    const handleShowModel = (faq, mode) => {
        if (mode === "edit") {
            setDisabled(false);
        }

        setShowModal(true);
        setEditData(faq);
    };

    useEffect(() => {
        getFaqList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFaqList = () => {
        try {
            api.postWithToken(`${serverURL}faq-page-manage`, {
                action: "faq-list",
            })
                .then(async (res) => {
                    setLoading(false);
                    if (res?.data?.success === true) {
                        setFaqList(res.data.data);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const onEditDataChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const closeDeleteModal = () => {
        setShowDeleteModel(false);
        setDeleteId("");
    };

    const onDeleteButtonClick = (id) => {
        setShowDeleteModel(true);
        setDeleteId(id);
    };

    const onSubmitDelete = (e) => {
        try {
            api.postWithToken(`${serverURL}faq-page-manage?id=${deleteId}`, {
                action: "faq-delete",
            })
                .then(async (res) => {
                    setLoading(false);
                    if (res?.data?.success === true) {
                        closeDeleteModal();
                        getFaqList();
                    }
                })
                .catch((e) => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const removeImageFromEditData = () => {
        setEditData({ ...editData, image: "" });
        setNewImage();
    };

    const handleImagesChange = (e) => {
        setNewImage(e.target.files[0]);
    };

    const handleEditSubmit = () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("title", editData.title);
            formData.append("description", editData.description);
            if (newImage) {
                formData.append("image", newImage);
            }
            formData.append("action", "faq-update");
            api.postWithToken(
                `${serverURL}faq-page-manage?id=${editData._id}`,
                formData
            )
                .then(async (res) => {
                    setLoading(false);
                    setNewImage();
                    setEditData();
                    if (res?.data?.success === true) {
                        setShowModal(false);
                        toast.success(res?.data?.message);
                        getFaqList();
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    setErrors(e.response.data.errors);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            {faqList && faqList.length > 0 ? (
                faqList?.map((faq, i) => {
                    return (
                        <div className="faq-box" key={i}>
                            <div className="d-flex align-items-center gap-3">
                                <div className="image-box">
                                    <img
                                        src={imageURL + faq.image[0]}
                                        alt="img"
                                    />
                                </div>
                                <h3>{faq.title}</h3>
                            </div>

                            <div className="d-flex justify-content-right align-items-center gap-1">
                                <button
                                    className="border-0 bg-transparent"
                                    onClick={() => handleShowModel(faq, "view")}
                                >
                                    <img
                                        src="/images/svg/show.svg"
                                        title="View"
                                        alt=""
                                    />
                                </button>
                                <button
                                    className="border-0 bg-transparent"
                                    onClick={() => handleShowModel(faq, "edit")}
                                >
                                    <img
                                        src="/images/svg/edit.svg"
                                        title="Edit"
                                        alt=""
                                    />
                                </button>
                                <button
                                    className="border-0 bg-transparent"
                                    onClick={() => onDeleteButtonClick(faq._id)}
                                >
                                    <img
                                        src="/images/svg/delete.svg"
                                        title="Delete"
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                    );
                })
            ) : (
                <>
                    <h4 className="text-center p-5">No Records Founds.</h4>
                </>
            )}

            <Modal
                size="lg"
                show={shoModal}
                onHide={() => {
                    setShowModal(false);
                    setDisabled(true);
                }}
                centered
                className="modal-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    <Modal.Title className="text-center">
                        {disabled ? "View Faq" : "Edit Faq"}
                    </Modal.Title>
                    <div className="d-flex justify-content-center text-center">
                        <img
                            src="/images/svg/line.svg"
                            className="mt-2"
                            alt=""
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
                    <Row>
                        <Col md={12}>
                            <label id="title">
                                Title <span className="star">*</span>
                            </label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter home title here"
                                type="text"
                                name="title"
                                value={editData?.title || ""}
                                onChange={onEditDataChange}
                                disabled={disabled}
                                // onKeyPress={handleKeyPress}
                            />
                            <SingleError error={errors?.title} />
                        </Col>
                        <Col md={12}>
                            <label id="description">
                                Description <span className="star">*</span>
                            </label>
                            <textarea
                                rows={3}
                                className="form-control px-3"
                                placeholder="Enter home description here"
                                type="text"
                                name="description"
                                value={editData?.description || ""}
                                onChange={onEditDataChange}
                                disabled={disabled}
                                // onKeyPress={handleKeyPress}
                            />
                            <SingleError error={errors?.description} />
                        </Col>

                        <Col
                            xs={12}
                            lg={12}
                            xl={12}
                            className="pt-2 cst-card"
                            id="image"
                        >
                            {newImage && (
                                <div className="preview-images mb-4 p-3 gap-3">
                                    <div className="home-preview">
                                        <img
                                            src={URL.createObjectURL(newImage)}
                                            alt=""
                                        />

                                        {!disabled && (
                                            <button
                                                onClick={() =>
                                                    removeImageFromEditData()
                                                }
                                                className="d-flex justify-content-center"
                                            >
                                                <img
                                                    src="/images/svg/closeImg.svg"
                                                    style={{
                                                        width: "8px",
                                                        height: "16px",
                                                    }}
                                                    alt=""
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {editData?.image && (
                                <div className="preview-images mb-4 p-3 gap-3">
                                    <div className="home-preview">
                                        <img
                                            src={imageURL + editData?.image[0]}
                                            alt=""
                                        />

                                        {!disabled && (
                                            <button
                                                onClick={() =>
                                                    removeImageFromEditData()
                                                }
                                                className="d-flex justify-content-center"
                                            >
                                                <img
                                                    src="/images/svg/closeImg.svg"
                                                    style={{
                                                        width: "8px",
                                                        height: "16px",
                                                    }}
                                                    alt=""
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {!disabled && (
                                <div className="upload__btn d-flex align-items-center justify-content-center py-2">
                                    <label className="d-flex align-items-center gap-1">
                                        <p
                                            className="mb-0"
                                            style={{
                                                color: "#000",
                                            }}
                                        >
                                            {Boolean(editData?.image[0])
                                                ? " Change Image"
                                                : "Upload Image"}
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="file"
                                            className="upload_inputfile"
                                            name="image"
                                            disabled={disabled}
                                            onChange={handleImagesChange}
                                        />
                                    </label>
                                </div>
                            )}

                            {Boolean(errors?.image) && (
                                <SingleError error={errors?.image} mb={false} />
                            )}
                        </Col>
                    </Row>
                    {!disabled && (
                        <div className="d-flex  justify-content-center mt-3">
                            <button
                                className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${
                                    Boolean(loading) && "btnDisable"
                                }`}
                                disabled={Boolean(loading)}
                                onClick={() => {
                                    handleEditSubmit();
                                    // setSubmitCount(1);
                                }}
                            >
                                {Boolean(loading) ? "Loading..." : "Save"}
                            </button>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* Delete modal */}
            <Modal
                size="md"
                show={showDeleteModel}
                onHide={closeDeleteModal}
                centered
                className="modal-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    <Modal.Title className="text-center">
                        Delete Faq{" "}
                    </Modal.Title>
                    <div className="d-flex justify-content-center text-center mt-2">
                        <img src="/images/svg/line.svg" alt="" />
                    </div>
                </Modal.Header>
                <Modal.Body className="mx-4 border-0">
                    <p className="fs-28  fw-500">
                        Are you sure you want to delete?
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex m-auto gap-3 pb-3 px-4">
                        <button
                            className="modalDeleteBtn px-5 border-0"
                            disabled={Boolean(loading)}
                            onClick={() => onSubmitDelete()}
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Faqs;
