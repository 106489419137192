import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { logout } from "../helper/auth";
import { errorResponse } from "../helper/error";

const ChangePassword = () => {
  const navigate = useNavigate();
  const serverURL = getServerURL();
  const [loginData, setLoginData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!loginData?.oldPassword.trim() || !loginData?.newPassword.trim()) {
        toast.error("Please fill the fields.");
        return;
      }
      // if (!validator.isLength(loginData?.oldPassword.trim(), { min: 8 })) {
      //   toast.error("Password must be at least 8 characters long.");
      //   return;
      // }
      if (!validator.isLength(loginData?.newPassword.trim(), { min: 8 })) {
        toast.error("New password must be at least 8 characters long.");
        return;
      }
      if (loginData?.oldPassword === loginData?.newPassword) {
        toast.error("Old password and new password cannot be the same.");
        return;
      }
      setLoading(true);
      api
        .postWithToken(`${serverURL}reset-password`, loginData)
        .then(async (res) => {
          // localStorage.removeItem("verify");
          // localStorage.removeItem("email");
          // localStorage.removeItem("resetpass");
          // logout();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast.success("Password changed successfully!");
            navigate("/login");
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          // console.log(e);
          errorResponse(e);
          // localStorage.removeItem("verify");
          // localStorage.removeItem("email");
          localStorage.removeItem("resetpass");
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handelSubmit(e)
    }
  };

  return (
    <>
      <section className="d-flex justify-content-center align-items-center vh-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col className="col-12 col-md-7 col-lg-6 col-xl-5">
              <Card className="p-5">
                <Card.Img
                  variant="top"
                  className="login-logo m-auto"
                  src="/images/logo/logo.png"
                />
                <p className="login-title">Change password</p>
                <Card.Body className="px-0 px-md-3">
                  <div className="form-control d-flex align-items-center px-3 mb-3 input-bg ctmInput">
                    <img
                      src="/images/svg/lock.svg"
                      className="imput-password-img"
                      alt="password.svg"
                    />
                    <input
                      className="form-control login-input border-0"
                      placeholder="Enter old password here"
                      type={showPassword ? "text" : "password"}
                      name="oldPassword"
                      onKeyPress={handleKeyPress}
                      onChange={(e) =>
                        setLoginData({ ...loginData, oldPassword: e.target.value })
                      }
                    />
                    <img
                      src={
                        showPassword
                          ? "/images/svg/open-eye.svg"
                          : "/images/svg/close-eye.svg"
                      }
                      className="close-eye-img cursor-pointer"
                      alt={showPassword ? "open-eye.svg" : "close-eye.svg"}
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  <div className="form-control d-flex align-items-center px-3 mb-3 input-bg ctmInput">
                    <img
                      src="/images/svg/lock.svg"
                      className="imput-password-img"
                      alt="password.svg"
                    />
                    <input
                      className="form-control login-input border-0"
                      placeholder="Enter new password here"
                      type={showPassword1 ? "text" : "password"}
                      name="newPassword"
                      onKeyPress={handleKeyPress}
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          newPassword: e.target.value,
                        })
                      }
                    />
                    <img
                      src={
                        showPassword1
                          ? "/images/svg/open-eye.svg"
                          : "/images/svg/close-eye.svg"
                      }
                      className="close-eye-img cursor-pointer"
                      alt={showPassword1 ? "open-eye.svg" : "close-eye.svg"}
                      onClick={togglePasswordVisibility1}
                    />
                  </div>
                  <button
                    type="button"
                    disabled={loading}
                    className={`btn-login bg-main text-uppercase border-0 w-100 mt-4 ${Boolean(loading) && "btnDisable"
                      }`}
                    onClick={(e) => handelSubmit(e)}
                  >
                    {Boolean(loading) ? "Loading... " : "Submit"}
                  </button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ChangePassword;
