import React, { useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
const ContentTable = () => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);
  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Image/Video</th>
              <th>Name</th>
              <th className="w-25">Descriptions</th>
              <th>Durations</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {[...Array(6)]?.map((item, i) => (
              <tr key={i+"contentTable"}>
                <td>{i + 1}</td>
                <td>
                  <img src="/images/png/rectangle.png" />
                </td>
                <td>Deno James</td>
                <td>
                  <div className="wordBreak text-center align-middle">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit....
                  </div>
                </td>
                <td>15-05-2023</td>
                <td>15-05-2023</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <button
                      className="border-0 bg-transparent"
                      onClick={viewModelOpenFun}
                    >
                      <img src="/images/svg/show.svg" title="View" />
                    </button>
                    <button
                      className="border-0 bg-transparent"
                      onClick={editModelOpenFun}
                    >
                      <img src="/images/svg/edit.svg" title="Edit" />
                    </button>
                    <button
                      className="border-0 bg-transparent"
                      onClick={deleteModelOpenFun} 
                    >
                      <img src="/images/svg/delete.svg" title="Delete" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          <TablePagination />
        </div>
      </Col>

      {/* View modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Content Details</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0cmt-3">
          <Row>
            <div className="position-relative">
              <div className="d-flex justify-content-center m-auto edit-user">
                <img src="/images/png/image12.png" className="cursor-pointer" />
              </div>
            </div>
          </Row>
          <Row className="px-5 pb-3">
            <div className="d-flex justify-content-center">
              <div>
                <tr>
                  <td className="userDetailsLabel">Name:</td>
                  &nbsp;
                  <td className="userDetailsText">John Leo</td>
                </tr>
                <tr>
                  <td className="userDetailsLabel">Email:</td>
                  &nbsp;
                  <td className="userDetailsText">johnleo@gmail.com</td>
                </tr>
                <tr>
                  <td className="userDetailsLabel">Description:</td>
                  &nbsp;
                  <td className="userDetailsText one-line-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris
                  </td>
                </tr>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Content</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form className="mt-4">
            <Row>
              <div className="position-relative">
                <div className="d-flex justify-content-center m-auto edit-user">
                  <img
                    src="/images/png/image13.png"
                    className="cursor-pointer"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>

            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                />
              </Col>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control px-3 mb-3"
                  placeholder="Description"
                  type="text"
                  name="Description"
                />
              </Col>
              <Col md={12}>
                <label>Durations</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Durations"
                  type="text"
                  name="Durations"
                />
              </Col>
            </Row>
            <div className="d-flex  justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 mt-3">
                Save
              </button>
            </div>
            {/* <div className="d-flex justify-content-center gap-3 mt-3">
              <button className="modalCancelBtn text-uppercase border-0 w-25 mt-3" onClick={() => editModelCloseFun}>
                Cancel
              </button>
              <button className="modalDeleteBtn text-uppercase border-0 w-25 my-3">
                Save
              </button>
            </div> */}
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete User</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28  fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalCancelBtn px-5 border-0"
              onClick={() => deleteModelCloseFun}
            >
              Cancel
            </button>
            <button className="modalDeleteBtn px-5 border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContentTable;
