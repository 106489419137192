import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TablePagination from "../components/TablePagination";

const NutrientsRichFoodsContent = () => {
  const navigate = useNavigate();

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <div className="card mt-3 px-4 pb-4 pt-2">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div className="card lectureView border-0">
              <img
                src="/images/nutrientsrichfoods/image1.png"
                alt="img"
                height={"345.512px"}
              />
              <div className="lecture-box">
                <div className="lectureBtn">
                  <div className="lectureText">Foods for Protein</div>
                  <div className="lectureIcon pt-2">
                    <img
                      src="/images/svg/editWhite.svg" title="Edit"
                      className="px-2"
                      onClick={editModelOpenFun}
                    />
                    <img
                      src="/images/svg/deleteWhite.svg" title="Delete"
                      className="px-2"
                      onClick={deleteModelOpenFun}
                    />
                    <img
                      src="/images/svg/addWhite.svg"
                      className="pl-2"
                      onClick={() => navigate("/foodsproteins")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div className="card lectureView border-0">
              <img
                src="/images/nutrientsrichfoods/image6.png"
                alt=""
                height={"345.512px"}
              />
              <div className="lecture-box">
                <div className="lectureBtn">
                  <div className="lectureText">Foods for Calcium</div>
                  <div className="lectureIcon pt-2">
                    <img
                      src="/images/svg/editWhite.svg" title="Edit"
                      className="px-2"
                      onClick={editModelOpenFun}
                    />
                    <img
                      src="/images/svg/deleteWhite.svg" title="Delete"
                      className="px-2"
                      onClick={deleteModelOpenFun}
                    />
                    <img
                      src="/images/svg/addWhite.svg"
                      onClick={() => navigate("/foodscalcium")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div className="card lectureView border-0">
              <img
                src="/images/nutrientsrichfoods/image3.png"
                alt=""
                height={"345.512px"}
              />
              <div className="lecture-box">
                <div className="lectureBtn">
                  <div className="lectureText">Foods for B12</div>
                  <div className="lectureIcon pt-2">
                    <img
                      src="/images/svg/editWhite.svg" title="Edit"
                      className="px-2"
                      onClick={editModelOpenFun}
                    />
                    <img
                      src="/images/svg/deleteWhite.svg" title="Delete"
                      className="px-2"
                      onClick={deleteModelOpenFun}
                    />
                    <img
                      src="/images/svg/addWhite.svg"
                      className="pl-2"
                      onClick={() => navigate("/foodsb12")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div className="card lectureView border-0">
              <img
                src="/images/nutrientsrichfoods/image4.png"
                alt=""
                height={"345.512px"}
              />
              <div className="lecture-box">
                <div className="lectureBtn">
                  <div className="lectureText">Foods for Iron</div>
                  <div className="lectureIcon pt-2">
                    <img
                      src="/images/svg/editWhite.svg" title="Edit"
                      className="px-2"
                      onClick={editModelOpenFun}
                    />
                    <img
                      src="/images/svg/deleteWhite.svg" title="Delete"
                      className="px-2"
                      onClick={deleteModelOpenFun}
                    />
                    <img
                      src="/images/svg/addWhite.svg"
                      className="pl-2"
                      onClick={() => navigate("/foodsiron")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div className="card lectureView border-0">
              <img
                src="/images/nutrientsrichfoods/image5.png"
                alt=""
                height={"345.512px"}
              />
              <div className="lecture-box">
                <div className="lectureBtn">
                  <div className="lectureText">Foods for Magnesium</div>
                  <div className="lectureIcon pt-2">
                    <img
                      src="/images/svg/editWhite.svg" title="Edit"
                      className="px-2"
                      onClick={editModelOpenFun}
                    />
                    <img
                      src="/images/svg/deleteWhite.svg" title="Delete"
                      className="px-2"
                      onClick={deleteModelOpenFun}
                    />
                    <img
                      src="/images/svg/addWhite.svg"
                      className="pl-2"
                      onClick={() => navigate("/foodsmagnesium")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div className="card lectureView border-0">
              <img
                src="/images/nutrientsrichfoods/image6.png"
                alt=""
                height={"345.512px"}
              />
              <div className="lecture-box">
                <div className="lectureBtn">
                  <div className="lectureText">Foods for Vitamin D</div>
                  <div className="lectureIcon pt-2">
                    <img
                      src="/images/svg/editWhite.svg" title="Edit"
                      className="px-2"
                      onClick={editModelOpenFun}
                    />
                    <img
                      src="/images/svg/deleteWhite.svg" title="Delete"
                      className="px-2"
                      onClick={deleteModelOpenFun}
                    />
                    <img
                      src="/images/svg/addWhite.svg"
                      className="pl-2"
                      onClick={() => navigate("/foodsvitamind")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-flex justify-content-between align-items-center mt-4">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() => navigate("/tracking-management")}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        <TablePagination />
      </div>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Edit Foods For Protien{" "}
          </Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto">
                  <img
                    src="/images/png/image12.png"
                    className="editprofileImage cursor-pointer"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>

            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  defaultValue={"John leo"}
                />
              </Col>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control px-3 mb-3"
                  placeholder="Description"
                  type="text"
                  name="Description"
                  defaultValue={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"
                  }
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Delete Foods For Protien{" "}
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28  fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalCancelBtn px-5 border-0"
              onClick={() => deleteModelCloseFun}
            >
              Cancel
            </button>
            <button className="modalDeleteBtn px-5 border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NutrientsRichFoodsContent;
