import React, { useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { useNavigate } from "react-router-dom";

const StressLevelTable = () => {
  const navigate =useNavigate();
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center mb-0">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Icon</th>
              <th>Name</th>
              <th className="w-25">Descriptions</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {[...Array(10)]?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <img src="/images/png/square.png" />
                </td>
                <td>Deno James</td>
                <td>
                  <div className="wordBreak1 text-center align-middle">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit....
                  </div>
                </td>
                <td>15-05-2023</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <button
                      className="border-0 bg-transparent"
                      onClick={viewModelOpenFun}
                    >
                      <img src="/images/svg/show.svg" />
                    </button>
                    {/* <button
                        className="border-0 bg-transparent"
                        onClick={editModelOpenFun}
                      >
                        <img src="/images/svg/edit.svg" />
                      </button> */}
                    <button
                      className="border-0 bg-transparent"
                      onClick={deleteModelOpenFun}
                    >
                      <img src="/images/svg/delete.svg" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <div className="d-md-flex justify-content-between align-items-center mt-4">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() => navigate("/tracking-management")}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        <TablePagination />
      </div>

      {/* View user modal */}
      <Modal
        size="md"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Details</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="d-flex justify-content-center">
              <div>
                <tr>
                  <td className="userDetailsLabel">Name:</td>
                  &nbsp;
                  <td className="userDetailsText">John Leo</td>
                </tr>
                <tr>
                  <td className="userDetailsLabel">Check In:</td>
                  &nbsp;
                  <td className="userDetailsText">8</td>
                </tr>
                <tr>
                  <td className="userDetailsLabel">Date:</td>
                  &nbsp;
                  <td className="userDetailsText">23rd Dec</td>
                </tr>
                <tr>
                  <td className="userDetailsLabel">Total Average:</td>
                  &nbsp;
                  <td className="userDetailsText">35%</td>
                </tr>
                <tr>
                  <td className="userDetailsLabel">
                    User to complete challenge:
                  </td>
                  &nbsp;
                  <td className="userDetailsText">Shreya singh</td>
                </tr>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form className="mt-4">
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                />
              </Col>
              <Col md={12}>
                <label>Email</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Email"
                  type="text"
                  name="name"
                />
              </Col>
            </Row>
            <div className="d-flex  justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 mt-3">
                Save
              </button>
            </div>
          </Form>
          {/* <div className="mx-md-4 border-0 bg-transparent mt-2 mb-3">
            <div className="d-flex justify-content-center text-center mt-4">
              <Button className="modelBtn">Save</Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn border-0">Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StressLevelTable;
