import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "../TablePagination";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import api from "../../helper/api";
import SingleError from "../../helper/SingleError";
import { getServerURL } from "../../helper/envConfig";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
const requireField = ["description"];

const AboutUsManagement = ({ temp, search, searchTemp, setCountAbout }) => {

  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [loading, setLoading] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)
  const [errors, setErrors] = useState({})
  const [mainLoader, setMainLoader] = useState(true);

  const [aboutData, setAboutData] = useState()
  const [editData, setEditData] = useState({})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getAboutList();
  }, []);

  useEffect(() => {
    getAboutList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getAboutList();

    }
    if (searchTemp && !search) {
      getAboutList();

    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const getAboutList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}ui-page-manage`,
          { action: "about-us-list" }
        )
        .then(async (res) => {
          // console.log(res);
          if (res?.data && Object.keys(res?.data?.data)) setCountAbout(1)
          setMainLoader(false);
          if (res?.data?.success === true) {
            setAboutData(res?.data?.data);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setAboutData([]);
            if (Boolean(page != 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
      setMainLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editData, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    setEditData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    // setTemp(false);
    const updatedValues = { ...editData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors)?.length === 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", editData?._id);
      formData.append("action", "about-us-update");
      formData.append("description", editData?.description);

      try {
        api
          .postWithToken(
            `${serverURL}ui-page-manage`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              editModelCloseFun();
              getAboutList()
              setEditData({})
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("About Us detail updated successfully!");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {Boolean(mainLoader) ?
        <Loader /> :
        Boolean(Object.keys(aboutData)?.length) ?
          <div className="card p-3 my-3">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="explore-head col-10">
                About Us Description
              </div>
              <button
                className="border-0 bg-transparent"
                onClick={() => { editModelOpenFun(); setEditData(aboutData) }}
              >
                <img src="/images/svg/edit.svg" title="Edit" />
              </button>
            </div>
            <div className="explore-description col-10">
              {aboutData?.description ? aboutData?.description : "-"}
            </div>

          </div>
          :
          <h4 className="text-center p-5">No Records Founds.</h4>
      }


      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Working</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>

            <Col md={12} id="description">
              <label>Description</label>
              <textarea
                rows={8}
                className="form-control px-3"
                placeholder="Enter description here"
                type="text"
                name="description"
                value={editData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                handleSubmit();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default AboutUsManagement;
