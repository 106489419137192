import React, { useState } from "react";
import AbsList from "../components/AbsList";
import { Col, Form, Modal, Row } from "react-bootstrap";
import Main from "../hoc/Main";

const Abs = () => {
  const [viewModel, setViewModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);

  return (
    <>
      <Main name="Abs Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Abs</h1>
          </Col>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <Form>
              <div className="d-flex align-items-center gap-3">
                <div className="form-control d-flex align-items-center px-3">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                      fill="black"
                    />
                  </svg>
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Search"
                    type="text"
                  />
                </div>
                <div
                  onClick={() => addModelOpenFun()}
                  className="cursor-pointer"
                >
                  <img
                    src="/images/svg/add.svg"
                    className="add-btn"
                    alt="" title="Add"
                  />
                </div>
              </div>
            </Form>
          </Col>
        </div>
        <AbsList />
      </Main>

      {/* Add Model  */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => setAddModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Abs</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                  <img
                    src="/images/svg/edituser.svg"
                    className="profileImageSize cursor-pointer"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                />
              </Col>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control px-3 mb-3"
                  placeholder="Description"
                  type="text"
                  name="Description"
                />
              </Col>
            </Row>
            <div className="d-flex  justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Add
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Abs;
