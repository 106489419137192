import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import QuoteTable from "../components/QuoteTable";
import Main from "../hoc/Main";
import { useNavigate } from "react-router-dom";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import SingleError from "../helper/SingleError";
import VideoLinkTable from "../components/VideoLinkTable";
const requireField = ["videoUrl"];

export const VideoLink = () => {
  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({});
    setAddModel(true);
  };
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("");
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const addVideoLink = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);
    // console.log(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      // const formData = new FormData();
      // formData.append("action", "create");
      // formData.append("title", state?.title);
      // formData.append("description", state?.description);
      // formData.append("date", state?.date);
      // formData.append("lecture_image", state?.lecture_image);
      try {
        api
          .postWithToken(`${serverURL}video-playlist-manage`, {
            action: "create",
            videoUrl: state?.videoUrl || "",
          })
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModelCloseFun();
              setTemp(true);
              setState({});
              if (typeof res?.data?.data?.message === "string")
                toast.success(res?.data?.data?.message);
              else toast.success("Video link created successfully");
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  return (
    <>
      <Main name="Videos Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Videos Link Management</h1>
          </Col>
          <div className="d-flex align-items-center justify-content-end gap-3">
            <div onClick={() => addModelOpenFun()} className="cursor-pointer">
              <img
                src="/images/svg/add.svg"
                className="add-btn"
                title="Add"
                alt=""
              />
            </div>
          </div>
        </div>
        <VideoLinkTable temp={temp} search={search} searchTemp={searchTemp} />
      </Main>

      {/* Add user modal */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => addModelCloseFun()}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Video Link</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent ctmInput">
          <Row>
            <Col md={12}>
              <label id="description">
                Video Link <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter video link here"
                type="text"
                name="videoUrl"
                value={state?.videoUrl || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.videoUrl} />
            </Col>
          </Row>
          <div className="d-flex  justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addVideoLink();
                setSubmitCount(1);
              }}
            >
              Add
              {/* {Boolean(loading) ? "Loading..." : "Add"} */}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
