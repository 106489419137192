import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import Search from "../components/Search";
import { useNavigate } from "react-router-dom";
import TablePagination from "../components/TablePagination";
import Main from "../hoc/Main";

const TrackingManagement2 = () => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <Main>
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Tracking Management</h1>
          </Col>
          <Search />
        </div>
        <div className="card mt-3 px-4 pb-4 pt-2 vh-75">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
              <div className="card lectureView border-0">
                <img
                  src="/images/png/image14.png"
                  alt="img"
                  height={"345.512px"}
                />
                <div className="lecture-box">
                  <div className="lectureBtn">
                    <div className="lectureText">Weight (KGs)</div>
                    <div className="lectureIcon pt-2">
                      <img
                        src="/images/svg/editWhite.svg"
                        className="pr-2"
                        onClick={editModelOpenFun}
                      />
                      <img
                        src="/images/svg/deleteWhite.svg"
                        className="px-2"
                        onClick={deleteModelOpenFun}
                      />
                      <img
                        src="/images/svg/addWhite.svg"
                        className="pl-2"
                        onClick={() => addModelOpenFun()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TablePagination />
      </Main>
      {/* Add Model  */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => setAddModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Tracking</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                  <img
                    src="/images/svg/edituser.svg"
                    className="cursor-pointer profileImageSize"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                />
              </Col>
              <Col md={12}>
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control px-3 mb-3"
                  placeholder="Description"
                  type="text"
                  name="Description"
                />
              </Col>
            </Row>
            {/* <Col xs={12} lg={12} xl={12} className="pt-2">
              <div class="upload__btn d-flex align-items-center justify-content-center py-2">
                <label class="d-flex align-items-center gap-1">
                  <span>+</span>
                  <p className="mb-0">Upload Image</p>
                  <input
                    type="file"
                    data-max_length="20"
                    class="upload_inputfile mb-3"
                    name="sub_images"
                  />
                </label>
              </div>
            </Col> */}
            <div className="d-flex  justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Add
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrackingManagement2;
