import { ActionTypes } from "../ActionType";

const initialState = {}

export default function LectureReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LECTURE_INDEX:
      return [...action.payload];
    default:
      return state;
  }
}


export function ChapterReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_CHAPTER_INDEX:
      return [...action.payload];

    default:
      return state;
  }
}