import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import {
  decryptFromStoreLoc,
  encryptAndStoreLoc,
} from "../helper/localStorage";

const Verification = () => {
  const navigate = useNavigate();
  const serverURL = getServerURL();
  const email = decryptFromStoreLoc("email");

  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reSendOtpLoading, setReSendOtpLoading] = useState(false);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      if (index < inputRefs.length - 1 && value.length === 1) {
        inputRefs[index + 1].current.focus();
      }
      setErrorMessage("");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (index > 0 && otp[index] === "") {
        inputRefs[index - 1].current.focus();
      } else {
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    }
  };

  const handelSubmit = async (e) => {
    // e.preventDefault();
    try {
      if (otp.some((value) => value === null || value === "")) {
        toast.error("Please enter OTP");
        return;
      }
      const otpValue = otp.join("");
      if (!/^\d{4}$/.test(otpValue)) {
        setErrorMessage("Invalid OTP. Please enter a valid OTP.");
        return;
      }
      setLoading(true);
      var myotp = otp[0] + otp[1] + otp[2] + otp[3];

      api
        .post(`${serverURL}verifyotp`, { email, otp: myotp })
        .then(async (res) => {
          if (res?.status === 200) {
            const token = res?.data?.token;
            localStorage.setItem("token", token);
            encryptAndStoreLoc("verify", "True");
            localStorage.removeItem("otpTimer");
            setErrorMessage("");
            toast.success("OTP verified successfully");
            navigate("/reset-password");
            setLoading(false);
            // localStorage.removeItem("count");
            // localStorage.removeItem("otpTimerStart");
            localStorage.removeItem("resetpass");
          } else {
            setLoading(false);
            setErrorMessage(
              "Invalid verification code. Please enter the correct OTP."
            );
          }
        })
        .catch((e) => {
          setOtp(["", "", "", ""]);
          setLoading(false);
          if (typeof e?.response?.data?.message)
            toast.error(e?.response?.data?.message);
          else toast.error("Error verifying OTP. Please try again.");
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const reSendOtp = async (e) => {
    setReSendOtpLoading(true);
    localStorage.removeItem(localStorageKey);
    try {
      localStorage.removeItem("count");
      setReSendOtpLoading(true);
      // clearInterval(countdown);
      api
        .post(`${serverURL}resendotp`, { email })
        .then(async (res) => {
          setReSendOtpLoading(false);
          if (res?.data?.success === true) {
            // clearInterval(countdown)
            setTimer(180)
            encryptAndStoreLoc("email", email);
            encryptAndStoreLoc("resetpass", "True");
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast.success("OTP send successfully!");
          } else if (res?.data?.success === false) {
            setReSendOtpLoading(false);
            if (typeof res?.data?.message === "string")
              toast.error(res?.data?.message);
          }
          setReSendOtpLoading(false);

          return () => {
            // clearInterval(countdown);
          };
        })
        .catch((e) => {
          if (e?.response?.data?.success === false) {
            if (typeof e?.response?.data?.message === "string")
              toast.error(e?.response?.data?.message);
          } else if (e?.code === "ERR_NETWORK") {
            if (typeof e?.message === "string") toast.error(e?.message);
          } else toast.error("Something Wrong! Please try again!");
          setReSendOtpLoading(false);
        });
    } catch (error) {
      setReSendOtpLoading(false);
      // console.log(error);
    }
  };

  const localStorageKey = 'otpTimer';
  const [timer, setTimer] = useState(() => {
    const storedTimer = decryptFromStoreLoc(localStorageKey);
    return storedTimer ? parseInt(storedTimer, 10) : 180; // 3 minutes in seconds
  });

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(countdown);
            // Handle timer expiration, e.g., resend OTP or show a message
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => {
        clearInterval(countdown);
        localStorage.removeItem(localStorageKey); // Clear timer on component unmount
      };
    } else {
      return undefined; // Don't start the timer if it's already 0
    }
  }, [timer]);

  useEffect(() => {
    encryptAndStoreLoc(localStorageKey, timer.toString());
  }, [timer]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handelSubmit(e);
    }
  };

  return (
    <>
      <section className="d-flex justify-content-center align-items-center vh-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col className="col-12 col-md-7 col-lg-6 col-xl-5">
              <Card className="p-5">
                <Card.Img
                  variant="top"
                  className="login-logo m-auto"
                  src="/images/logo/logo.png"
                />
                {/* <OtpPage/> */}
                <p className="login-title">OTP Verification</p>
                <p className="otpTimer mb-0">
                  {Math.floor(timer / 60) < 10
                    ? "0" + Math.floor(timer / 60)
                    : Math.floor(timer / 60)}
                  :{timer % 60 < 10 ? "0" + (timer % 60) : timer % 60}
                </p>

                <Card.Body className="px-0 px-md-3">
                  <div className="input-main-auth verify-code d-flex align-items-center gap-3 justify-content-center">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={value}
                        className="otp-input"
                        onChange={(e) => handleOtpChange(e, index)}
                        ref={inputRefs[index]}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onKeyPress={handleKeyPress}
                      />
                    ))}
                  </div>
                  {errorMessage && (
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  )}
                  <p className="pt-4 text-center">
                    Haven't received the OTP?
                    <button
                      onClick={() => {
                        reSendOtp();
                        setReSendOtpLoading(true);
                      }}
                      disabled={timer !== 0 || reSendOtpLoading}
                      style={{
                        cursor: Boolean(timer !== 0 || reSendOtpLoading)
                          ? "wait"
                          : "pointer",
                      }}
                      className={`border-0 reSendOtp ${Boolean(timer !== 0 || Boolean(reSendOtpLoading)) &&
                        "btnDisable"
                        } ${Boolean(timer === 0) && "cursor-pointer"}`}
                    >
                      {Boolean(loading) ? "Loading... " : "Send it again"}
                    </button>
                  </p>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      onClick={() => handelSubmit()}
                      disabled={loading}
                      className={`btn-login bg-main text-uppercase border-0 w-100 mt-3 ${Boolean(loading) && "btnDisable"
                        }`}
                    >
                      {Boolean(loading) ? "Loading... " : "Verify"}
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Verification;
