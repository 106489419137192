import React, { useState, useEffect,useRef } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import api from "../helper/api";
import { getServerURL,getImageURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields"
import ErrorFilter from "../helper/errorFilter";
import SingleError from "../helper/SingleError";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapter from "../components/adapterclass/CustomUploadAdapter";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
const WomenTable = ({ temp, search, searchTemp }) => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menDataList, setMenDataList] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [genderFilter, setGenderFilter] = useState(""); 
  const serverURL = getServerURL();
  const imagepath = "http://157.245.107.181:3146/";
  const [selectedImage, setSelectedImage] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [state, setState] = useState({
    title: "",
    description: "",
    duration: "",
    week: "",
    day: "",
    category: "",
    gender: "",
    content: "",
    image: null, // Changed to handle file input
  });
  const imageUrl = getImageURL();
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState({});
  const requiredFields = ["image", "title", "gender", "category", "duration", "week", "day",'content'];
  
  
  const viewModelOpenFun = (item) => {
    setSelectedItem(item);
    setViewModel(true);
  };
  
  const viewModelCloseFun = () => setViewModel(false);
  const editModelOpenFun = (item) => {
    setSelectedItem(item);
    setId(item._id); // Set the correct ID for the selected item
  
    const updatedState = {
      title: item.title || "",
      description: item.description || "",
      duration: item.duration || "",
      week: item.week || "",
      day: item.day || "",
      category: item.category || "",
      gender: item.gender || "",
      content: item.content || "",
      image: item.image || ""
    };
  
    setState(updatedState);
    setEditModel(true);
  };
  
  const [loading, setLoading] = useState(false);
  const editModelCloseFun = () => setEditModel(false);
  const deleteModelOpenFun = (item) => {
    setSelectedItem(item);
    setDeleteModel(true);
  };
  
  const deleteModelCloseFun = () => setDeleteModel(false);
  const [id, setId] = useState(null);
  const getProducts = async () => {
    setMainLoader(true);
    try {
      const response = await api.getWithToken(`${serverURL}get-regimen-manage?page=${page}&limit=${limit}&gender=female&search=${search}`);
      if (response?.data?.success) {
      setMenDataList(response.data.data || []);
      setPaginationData(response.data.pagination || {});
      if (response.data.data.length > 0) {
        setId(response.data.data[0]._id); 
      }
    }
    else {
      setMenDataList([]);
    }

    } catch (error) {
      console.error("Error fetching products:", error.response ? error.response.data : error.message);
    } finally {
      setMainLoader(false);
    }
  };
  
  useEffect(() => {
    getProducts();

  }, [page, limit,refreshData,temp, search, searchTemp]);
  const deleteItem = async (itemId) => {
    try {
      const response= await api.deleteWithToken(`${serverURL}delete-regimen-manage?id=${itemId}`);
      getProducts();
      deleteModelCloseFun();    
      const successMessage = typeof response?.data?.data?.message === "string"
      ? response?.data?.data?.message
      : "Regimen Deleted successfully";
    toast.success(successMessage);

    } catch (error) {
      console.error("Error deleting item:", error.response || error.message);
    }
  };  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };
  const validateFields = (updatedValues) => {
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requiredFields);

    if (updatedValues?.description?.trim().length === 0) {
      validationErrors.description = "Description field is required.";
    }

    setErrors(validationErrors);
    return validationErrors;
  };
  const fileInputRef = useRef(null);
  const handleImageClick = () => fileInputRef.current.click();
  const updateState = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
      setState((prev) => ({ ...prev, image: file })); // Update state with the selected file
    }
  };
  function CustomAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader); 
    };
  }
  const editorConfiguration = {
    extraPlugins: [CustomAdapterPlugin],
    mediaEmbed: { previewsInData: true },
    toolbar: {
      items: [
        "undo", "redo", "|", "heading", "|", "bold", "italic", "|",
        "bulletedList", "numberedList", "|", "blockQuote", "|", "imageUpload", "|", "mediaEmbed"
      ]
    },
    image: {
      toolbar: ["imageStyle:full", "imageStyle:side", "|", "imageTextAlternative"],
      resizeUnit: "px"
    }
  };
  const handleEditorChange = debounce((event, editor) => {
    const data = editor.getData();
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, content: data });
      validationErrors = ErrorFilter(validationErrors, requiredFields);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors["content"];
      }
    }
    setState({ ...state, content: data });
  }, 300);


  const handleFormSubmit = async () => {
    setSubmitCount((count) => count + 1);
    const validationErrors = validateFields(state);

    if (Object.keys(validationErrors).length === 0) {
        await submitRegimen();  // Ensure submitRegimen is awaited to handle asynchronous submission
    } else {
        const firstErrorKey = Object.keys(validationErrors)[0];
        document.getElementById(firstErrorKey)?.scrollIntoView({ behavior: "smooth" });
    }
};

const submitRegimen = async () => {
  
    setLoading(true);
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
        formData.append(key, state[key]);
    });

    try {
        const response = await api.patchWithToken(`${serverURL}edit-regimen-manage?id=${id}`, formData);
        if (response?.data?.success) {
            handleSuccess(response);
        }
    } catch (error) {
        errorResponse(error);  // Assuming errorResponse is your error handling function
    } finally {
        setLoading(false);
    }
};
  const handleSuccess = (response) => {
    editModelCloseFun();
    setRefreshData(!refreshData);
   
    const successMessage = typeof response?.data?.data?.message === "string"
      ? response?.data?.data?.message
      : "Regimen Updated successfully";
    toast.success(successMessage);
  };
  const resetForm = () => {
    setSubmitCount(0);
    setErrors({});
    setState({ description: "" });
    setSelectedImage(null);
  };
  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Image</th>
              <th>Title</th>
              <th className="w-25">Descriptions</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {menDataList.length > 0 ? (
              menDataList?.map((item, index) => (
                <tr key={item?._id}>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>
                    <img src={`${imageUrl}${item?.image}`} alt={item?.title} style={{ width: '100px', height: 'auto' }} />
                  </td>
                  <td>{item?.title}</td>
                  <td>
                    <div className="wordBreak1 text-center align-middle">
                      {item?.description}
                    </div>
                  </td>
                  <td>{format(new Date(item?.created_at), 'dd/MM/yyyy')}</td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center gap-1">
                      <button className="border-0 bg-transparent" onClick={() => viewModelOpenFun(item)}>
                        <img src="/images/svg/show.svg" title="View" />
                      </button>
                      <button className="border-0 bg-transparent" onClick={() => editModelOpenFun(item)}>
                        <img src="/images/svg/edit.svg" title="Edit" />
                      </button>
                      <button className="border-0 bg-transparent" onClick={() => deleteModelOpenFun(item)}>
                        <img src="/images/svg/delete.svg" title="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          {Boolean(menDataList?.length) && paginationData?.totalPages > 1 && (
            <TablePagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </div>
      </Col>

      {/* View item modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View Regimen</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          {selectedItem && (
            <Row>
              <div className="d-flex justify-content-center">
                <div>
                  <Row>
                    <div className="position-relative my-4">
                      <div className="d-flex justify-content-center align-items-center m-auto ">
                        <img
                          src={`${imagepath}${selectedItem.image}`}
                          className="editprofileImage cursor-pointer"
                        />
                      </div>
                    </div>
                  </Row>
                  <div className="mb-3">
                    <tr>
                      <td className="userDetailsLabel">Title:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.title}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Description:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.description}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Duration:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.duration}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Category:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.category}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Gender:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.gender}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Day:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.day}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Week:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.week}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Created Date:</td>
                      &nbsp;
                      <td className="userDetailsText">{new Date(selectedItem.created_at).toLocaleDateString()}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Content:</td>
                      &nbsp;
                      <td className="userDetailsText" dangerouslySetInnerHTML={{ __html: selectedItem.content }} />
                    </tr>

                  </div>
                </div>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={editModel}
        onHide={editModelCloseFun} centered className="modal-main info-modal">
        <Modal.Header className="d-block position-relative border-0 pt-4 px-4" closeButton>
          <Modal.Title className="text-center">Edit My Regimen</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
              <img
              src={selectedImage || (state?.image && imageUrl + state?.image) || "/images/png/duser.png"}
              onClick={handleImageClick}
              className={`cursor-pointer ${selectedImage || state?.image ? "editprofileImage" : "profileImageSize"}`}
              alt="Profile"
            />
              </div>
              <input
            type="file"
            accept=".jpg, .jpeg, .png"
            name="image"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <img
            onClick={handleImageClick}
            src="/images/svg/camera.svg"
            className="cursor-pointer position-absolute top-50 start-50 translate-middle"
            alt="Upload"
          />
            </div>
            {errors?.image && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors.image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="title">
                <Form.Label>Title <span className="star">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter tracking title here"
                  name="title"
                  value={state?.title || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.title} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="description">
                <Form.Label>Description <span className="star">*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter tracking description here"
                  name="description"
                  value={state?.description || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.description} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="duration">
                <Form.Label>Duration <span className="star">*</span></Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter tracking duration"
                  name="duration"
                  value={state?.duration || ""}
                  onChange={handleChange}
                  min="1"
                />
                <SingleError error={errors?.duration} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="week">
                <Form.Label>Week <span className="star">*</span></Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter tracking week"
                  name="week"
                  value={state?.week || ""}
                  onChange={handleChange}
                  min="1"
                />
                <SingleError error={errors?.week} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="day">
                <Form.Label>Day <span className="star">*</span></Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter tracking day here"
                  name="day"
                  value={state?.day || ""}
                  onChange={handleChange}
                  min="1"
                />
                <SingleError error={errors?.day} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="category">
                <Form.Label>Category <span className="star">*</span></Form.Label>
                <Form.Select
                  name="category"
                  value={state?.category || ""}
                  onChange={handleChange} style={{color:'black'}}
                >
                  <option value="" disabled>Select category</option>
                  <option value="fullBody">Full Body</option>
                  <option value="lowerBody">Lower Body</option>
                </Form.Select>
                <SingleError error={errors?.category} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="gender">
                <Form.Label>Gender <span className="star">*</span></Form.Label>
                <Form.Select
                  name="gender"
                  value={state?.gender || ""}
                  onChange={handleChange} style={{color:'black'}}
                >
                  <option value="" disabled>Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
                <SingleError error={errors?.gender} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="content">
                <Form.Label>Content <span className="star">*</span></Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={state?.content || ""}
                  onChange={handleEditorChange}
                  config={editorConfiguration}
                />
                <SingleError error={errors?.content} mb={false} />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${loading ? "btnDisable" : ""}`}
              disabled={loading}
              onClick={handleFormSubmit}
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete My Regimen</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn border-0"   onClick={() => deleteItem(selectedItem?._id)}>Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WomenTable;
