import React from "react";
import { Col, Row } from "react-bootstrap";

const MessageContent = () => {
  return (
    <>
      <Row>
        <Col md={6} sm={6} lg={4}>
          <div className="card p-2">
            <div className="form-control d-flex align-items-center px-3 mb-3 input-bg">
              <img
                src="/images/svg/search.svg"
                className="imput-img"
                alt="search.svg"
              />
              <input
                className="form-control login-input border-0"
                placeholder="Search"
                type="text"
                name="search"
              />
            </div>

            <div className="card p-2 mb-2">
              <div class="user-chat-box d-flex align-items-center gap-3">
                <img src="/images/jpeg/image1.jpeg" alt="" className="ml-2" />
                <div style={{ width: "80%" }}>
                  <div class="d-flex align-content-center justify-content-between gap-3">
                    <h5>Sophia Pelekos </h5>
                    <span>02/02/2024, 09:48 AM</span>
                  </div>
                  <p className="mb-0">
                    Hello I’m Sophia It’s Great To Be Here.
                  </p>
                </div>
              </div>
            </div>

            <div className="card p-2 mb-2">
              <div class="user-chat-box d-flex align-items-center gap-3">
                <img src="/images/jpeg/image1.jpeg" alt="" className="ml-2" />
                <div style={{ width: "80%" }}>
                  <div class="d-flex align-content-center justify-content-between gap-3">
                    <h5>Sophia Pelekos </h5>
                    <span>02/02/2024, 09:48 AM</span>
                  </div>
                  <p className="mb-0">
                    Hello I’m Sophia It’s Great To Be Here.
                  </p>
                </div>
              </div>
            </div>

            <div className="card p-2 mb-2">
              <div class="user-chat-box d-flex align-items-center gap-3">
                <img src="/images/jpeg/image1.jpeg" alt="" className="ml-2" />
                <div style={{ width: "80%" }}>
                  <div class="d-flex align-content-center justify-content-between gap-3">
                    <h5>Sophia Pelekos </h5>
                    <span>02/02/2024, 09:48 AM</span>
                  </div>
                  <p className="mb-0">
                    Hello I’m Sophia It’s Great To Be Here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={8} className="mt-4 mt-md-0">
          <div className="borderMsg">
            {/* <div className="d-flex align-items-center p-3 gap-2 msgBox">
                            <div class="user-chat-box d-flex align-items-center gap-3 mb-1">
                                <img src="/images/jpeg/image1.jpeg" alt="" className='ml-2' />
                                <div style={{ width: "80%" }}>
                                    <div class="d-flex align-content-center justify-content-between gap-3">
                                        <h5>Sophia Pelekos </h5>
                                    </div>
                                    <p className='mb-0'>Last Seen at 01/29/2024, 05:01 PM</p>
                                </div>
                            </div>
                        </div> */}
            <div class=" p-3 p-sm-3 notify-tab-header">
              <div class="user-chat-box d-flex align-items-center gap-3">
                <img src="images/jpeg/image1.jpeg" alt="" />
                <div>
                  <h5>Sophia Pelekos</h5>
                  <p className="mb-0">Last Seen at 01/29/2024, 05:01 PM</p>
                </div>
              </div>
            </div>

            <div
              class="p-3 p-sm-3 chat-area"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <ul>
                <li class="receiver-msg">
                  <div class="msg d-flex align-items-start gap-3">
                    <img src="/images/jpeg/image1.jpeg" alt="" />
                    <div>
                      <p>Hello</p>
                    </div>
                  </div>
                  <span class="msg-time" style={{ marginLeft: "52px" }}>
                    02/02/2024, 11:00 AM
                  </span>
                </li>
                <li class="send-msg ms-auto mt-3">
                  <div
                    class="msg d-flex align-items-start gap-3"
                    style={{ justifyContent: "end" }}
                  >
                    <div class="send-msg-text">
                      <p>hello</p>
                    </div>
                  </div>
                  <span class="msg-time text-end w-100 d-inline-block">
                    02/02/2024, 11:20 AM
                  </span>
                </li>

              </ul>
            </div>


            <div class="py-1 px-2">
              <div className="d-flex justify-content-between msgView1 px-3 mb-2 ">
                <div className="d-flex align-items-center gap-2">
                  <img src="/images/svg/pin.svg" />
                </div>
                <input
                  className="p-1 w-100 text-white fs-16 fw-500 bgMsg border-0"
                  type="text"
                  placeholder="Message"
                  name="channel" />

                <div className="d-flex align-items-center gap-3">
                  <span className="text-white fs-22 fw-500">@</span>
                  <img src="/images/svg/send.svg" />
                </div>
              </div>
              {/* <div class="type-msg">
                <input type="text" placeholder="Type here..." value="" />
                <button type="button" class="btn btn-primary">
                  <img src="/images/png/send.png" alt="" />
                </button>
              </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MessageContent;
