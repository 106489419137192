import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import api from "../../helper/api";
import SingleError from "../../helper/SingleError";
import { getServerURL } from "../../helper/envConfig";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
const requireField = ["title", "descriptions", "contact", "email"];

const HelpAndSupport = ({ countHelpSupport }) => {
    const serverURL = getServerURL();

    const [editModel, setEditModel] = useState(false);

    const editModelOpenFun = () => {
        setErrors({});
        setSubmitCount(0);
        setEditModel(true);
    };
    const editModelCloseFun = () => setEditModel(false);

    const [loading, setLoading] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const [errors, setErrors] = useState({});
    const [mainLoader, setMainLoader] = useState(true);

    const [helpSupportData, setHelpSupportData] = useState({});
    const [editData, setEditData] = useState({});
    const [page, setPage] = useState(1);

    useEffect(() => {
        getHelpSupportData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getHelpSupportData = () => {
        try {
            api.postWithToken(`${serverURL}help-support`, {
                action: "help-support-list",
            })
                .then(async (res) => {
                    setMainLoader(false);
                    if (res?.data?.success === true) {
                        if (res?.data?.data) {
                            countHelpSupport(1);
                            setHelpSupportData(res?.data?.data);
                        }
                    }
                })
                .catch((e) => {
                    setMainLoader(false);
                    if (
                        e?.response?.status === 404 ||
                        e?.response?.statusText === "Not Found"
                    ) {
                        setHelpSupportData([]);
                        if (Boolean(page !== 1)) {
                            setPage(page - 1);
                        }
                    }
                });
        } catch (error) {
            setMainLoader(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (submitCount > 0) {
            let validationErrors = ValidateFields({
                ...editData,
                [name]: value,
            });
            validationErrors = ErrorFilter(validationErrors, requireField);
            setErrors(validationErrors);

            if (Object.keys(validationErrors)?.length === 0) {
                delete errors[name];
            }
        }

        setEditData((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            setSubmitCount(1);
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setSubmitCount(1);
        const updatedValues = { ...editData };
        let validationErrors = {};

        if (!updatedValues?.email) {
            validationErrors.email = "Email field is required.";
        } else if (!/\S+@\S+\.\S+/.test(updatedValues?.email)) {
            validationErrors.email =
                "Invalid email format. Please provide a valid email address.";
        }

        if (!updatedValues?.title) {
            validationErrors.title = "Title field is required.";
        } else if (updatedValues?.title?.trim()?.length === 0) {
            validationErrors.title =
                "Title cannot be empty or contain only spaces.";
        } else if (updatedValues?.title?.length < 2) {
            validationErrors.title = "Title should be minimum 2 characters.";
        }

        // Validate description field
        if (!updatedValues?.description) {
            validationErrors.description = "Description field is required.";
        } else if (updatedValues?.description?.trim()?.length === 0) {
            validationErrors.description =
                "Description cannot be empty or contain only spaces.";
        } else if (updatedValues?.description?.trim()?.length < 2) {
            validationErrors.description =
                "Description should be minimum 2 characters.";
        }

        setErrors(validationErrors);

        if (editData?.contact) {
            if (!/^\+?\d+$/.test(editData?.contact)) {
                setErrors((prevValues) => ({
                    ...prevValues,
                    contact: "Please valid contact number",
                }));
            }
        }

        if (Object.keys(validationErrors)?.length) {
            // eslint-disable-next-line array-callback-return
            Object.entries(validationErrors)?.map(([key], i) => {
                if (i === 0)
                    document
                        .getElementById(key)
                        ?.scrollIntoView({ behavior: "smooth" });
            });
        }

        if (
            Object.keys(validationErrors)?.length === 0 &&
            Object.keys(errors)?.length === 0
        ) {
            setLoading(true);

            try {
                api.postWithToken(`${serverURL}help-support`, {
                    ...editData,
                    action: "help-support-create",
                })
                    .then(async (res) => {
                        setLoading(false);
                        if (res?.data?.success === true) {
                            editModelCloseFun();
                            getHelpSupportData();
                            setEditData({});
                            if (typeof res?.data?.message === "string") {
                                toast.success(res?.data?.message);
                            } else {
                                toast.success(
                                    "Help & support detail updated successfully!"
                                );
                            }
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        errorResponse(e);
                    });
            } catch (error) {
                setLoading(false);
            }
        }
    };

    return (
        <>
            {Boolean(mainLoader) ? (
                <Loader />
            ) : Object.keys(helpSupportData)?.length > 0 ? (
                <div className="card p-3 my-3 help-support">
                    <div className="d-flex justify-content-between align-items-center gap-2 border-bottom pb-3 mb-4">
                        <h5 className="explore-head col-10">Help & Support </h5>
                        <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                                editModelOpenFun();
                                setEditData(helpSupportData);
                            }}
                        >
                            <img
                                src="/images/svg/edit.svg"
                                title="Edit"
                                alt=""
                            />
                        </button>
                    </div>
                    <div className="d-flex">
                        <div className="">
                            <tr>
                                <td className="help-label">Title :</td>
                                &nbsp;
                                <td className="explore-description fullTextShow">
                                    {helpSupportData?.title}
                                </td>
                            </tr>
                            <tr>
                                <td className="help-label">Description :</td>
                                &nbsp;
                                <td className="explore-description fullTextShow">
                                    {helpSupportData?.description}
                                </td>
                            </tr>
                            <tr>
                                <td className="help-label">Contact No :</td>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <td className="explore-description fullTextShow">
                                    {helpSupportData?.contact}
                                </td>
                            </tr>
                            <tr>
                                <td className="help-label">Email :</td>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <td className="explore-description fullTextShow">
                                    {helpSupportData?.email}
                                </td>
                            </tr>
                        </div>
                    </div>
                </div>
            ) : (
                <h4 className="text-center p-5">No Records Founds.</h4>
            )}

            {/* Edit modal */}
            <Modal
                size="lg"
                show={editModel}
                onHide={editModelCloseFun}
                centered
                className="modal-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    <Modal.Title className="text-center">
                        Edit Help & Support
                    </Modal.Title>
                    <div className="d-flex justify-content-center text-center mt-2">
                        <img src="/images/svg/line.svg" alt="" />
                    </div>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
                    <Row>
                        <Col md={12}>
                            <label>Title</label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter home title here"
                                type="text"
                                name="title"
                                value={editData?.title || ""}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                            <SingleError error={errors?.title} />
                        </Col>
                        <Col md={12}>
                            <label>Description</label>
                            <textarea
                                rows={3}
                                className="form-control px-3"
                                placeholder="Enter home description here"
                                type="text"
                                name="description"
                                value={editData?.description || ""}
                                onChange={handleChange}
                            />
                            <SingleError error={errors?.description} />
                        </Col>
                        <Col md={12}>
                            <label>Contact No</label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter home title here"
                                type="number"
                                name="contact"
                                value={editData?.contact || ""}
                                onKeyDown={(e) =>
                                    ["e", "E", "+", "-", "."].includes(e.key) &&
                                    e.preventDefault()
                                }
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                            <SingleError error={errors?.contact} />
                        </Col>
                        <Col md={12}>
                            <label>Email</label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter email address"
                                type="text"
                                name="email"
                                value={editData?.email || ""}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                            <SingleError error={errors?.email} />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center ">
                        <button
                            className={`btn-login bg-main text-uppercase border-0 w-75 my-4
              ${Boolean(loading) && "btnDisable"}`}
                            disabled={Boolean(loading)}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {Boolean(loading) ? "Loading..." : "Save"}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default HelpAndSupport;
