// src/pages/MyRegimen.js
import React, { useState, useRef,useEffect } from "react";
import WomenTable from "../components/WomenTable";
import MenTable from "../components/MenTable";
import Main from "../hoc/Main";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { getImageURL, getServerURL } from "../helper/envConfig";
import SingleError from "../helper/SingleError";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { debounce } from "lodash";
import CustomUploadAdapter from "../components/adapterclass/CustomUploadAdapter";

const MyRegimen = () => {
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);
  const [mainLoader, setMainLoader] = useState(true);
  const [menDataList, setMenDataList] = useState([]);
  const fileInputRef = useRef(null);
  const imageUrl = getImageURL();
  const serverURL = getServerURL();

  const requiredFields = ["image", "title", "gender", "category", "duration", "week", "day","content"];

  const openModal = () => {
    resetForm();
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const resetForm = () => {
    setSubmitCount(0);
    setErrors({});
    setState({ description: "" });
    setSelectedImage(null);
  };

  const handleImageClick = () => fileInputRef.current.click();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
      updateState(e.target.name, file);
    }
  };

  const updateState = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState(name, value);
  };

  const validateFields = (updatedValues) => {
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requiredFields);

    if (updatedValues?.description?.trim().length === 0) {
      validationErrors.description = "Description field is required.";
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  const handleEditorChange = debounce((event, editor) => {
    const data = editor.getData();
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, content: data });
      validationErrors = ErrorFilter(validationErrors, requiredFields);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors["content"];
      }
    }
    setState({ ...state, content: data });
  }, 300);

  const handleFormSubmit = () => {
    
    setSubmitCount(1);
    const validationErrors = validateFields(state);

    if (Object.keys(validationErrors).length === 0) {
      submitRegimen();
    } else {
      const firstErrorKey = Object.keys(validationErrors)[0];
      document.getElementById(firstErrorKey)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitRegimen = async () => {
    setTemp(false);
    setLoading(true);
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
      formData.append(key, state[key]);
    });

    try {
      const response = await api.postWithToken(`${serverURL}add-regimen-manage`, formData);
      if (response?.data?.success) {
        setTemp(true);
        handleSuccess(response);
      }
    } catch (error) {
      errorResponse(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = (response) => {
    closeModal();
    setRefreshData(!refreshData);
    resetForm();
    const successMessage = typeof response?.data?.data?.message === "string"
      ? response?.data?.data?.message
      : "Regimen created successfully";
    toast.success(successMessage);
  };

  const editorConfiguration = {
    extraPlugins: [CustomAdapterPlugin],
    mediaEmbed: {
      previewsInData: true,
    },

    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "|",
        "imageUpload",
        "|",
        "mediaEmbed",
        "|",
        "imageTextAlternative",
        // ... any other toolbar items except 'insertTable'
      ],
    },

    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
      resizeUnit: "px",
    },
  };

  function CustomAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader); // Ensure this matches your import
    };
  }

  const getProducts = async () => {
    setMainLoader(true);
    try {
      const response = await api.getWithToken(`${serverURL}get-regimen-manage`);
      if (response?.data?.success) {
      setMenDataList(response.data.data || []);
    }
    else {
      setMenDataList([]);
    }

    } catch (error) {
      console.error("Error fetching products:", error.response ? error.response.data : error.message);
    } finally {
      setMainLoader(false);
    }
  };
  
  useEffect(() => {
    getProducts();

  }, [refreshData]);
  
 
  return (
    <>
      <Main name="My Regimen">
        <div className="communityPage d-sm-block d-md-flex d-lg-flex justify-content-between mt-3">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-men-tab" data-bs-toggle="pill" data-bs-target="#pills-men" type="button">
                Men
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-women-tab" data-bs-toggle="pill" data-bs-target="#pills-women" type="button">
                Women
              </button>
            </li>
          </ul>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Search by title"
                  type="text"
                  name="search"
                  autoComplete="off"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setSearchTemp(true);
                  }}
                />
              </div>
              <div onClick={openModal} className="cursor-pointer">
                <img src="/images/svg/add.svg" className="add-btn" alt="Add" />
              </div>
            </div>
          </Col>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-men" role="tabpanel">
            <MenTable refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp} />
          </div>
          <div className="tab-pane fade" id="pills-women" role="tabpanel">
            <WomenTable refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp}/>
          </div>
        </div>
      </Main>
      <Modal size="lg" show={isModalOpen} onHide={closeModal} centered className="modal-main info-modal">
        <Modal.Header className="d-block position-relative border-0 pt-4 px-4" closeButton>
          <Modal.Title className="text-center">Add My Regimen</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={selectedImage || (state?.image && imageUrl + state?.image) || "/images/png/duser.png"}
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage || state?.image ? "editprofileImage" : "profileImageSize"}`}
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt="Upload"
              />
            </div>
            {errors?.image && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors.image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="title">
                <Form.Label>Title <span className="star">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter my regimen title here"
                  name="title"
                  value={state?.title || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.title} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="description">
                <Form.Label>Description <span className="star">*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter my regimen description here"
                  name="description"
                  value={state?.description || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.description} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="duration">
                <Form.Label>Duration <span className="star">*</span></Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter my regimen duration"
                  name="duration"
                  value={state?.duration || ""}
                  onChange={handleChange}
                  min="1"
                />
                <SingleError error={errors?.duration} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="week">
                <Form.Label>Week <span className="star">*</span></Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter my regimen week"
                  name="week"
                  value={state?.week || ""}
                  onChange={handleChange}
                  min="1"
                />
                <SingleError error={errors?.week} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="day">
                <Form.Label>Day <span className="star">*</span></Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter my regimen day here"
                  name="day"
                  value={state?.day || ""}
                  onChange={handleChange}
                  min="1"
                />
                <SingleError error={errors?.day} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="category">
                <Form.Label>Category <span className="star">*</span></Form.Label>
                <Form.Select
                  name="category"
                  value={state?.category || ""}
                  onChange={handleChange} style={{color:'black'}}
                >
                  <option value="" disabled>Select category</option>
                  <option value="fullBody">Full Body</option>
                  <option value="lowerBody">Lower Body</option>
                </Form.Select>
                <SingleError error={errors?.category} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="gender">
                <Form.Label>Gender <span className="star">*</span></Form.Label>
                <Form.Select
                  name="gender"
                  value={state?.gender || ""}
                  onChange={handleChange} style={{color:'black'}}
                >
                  <option value="" disabled>Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
                <SingleError error={errors?.gender} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="content">
                <Form.Label>Content <span className="star">*</span></Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={state?.content || ""}
                  onChange={handleEditorChange}
                  config={editorConfiguration}
                />
                <SingleError error={errors?.content} mb={false} />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${loading ? "btnDisable" : ""}`}
              disabled={loading}
              onClick={handleFormSubmit}
            >
              {loading ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyRegimen;
