import React from "react";
import RevenueYearlyTable from "../components/RevenueYearlyTable";
import RevenueMonthlyTable from "../components/RevenueMonthlyTable";
import { useNavigate } from "react-router-dom";
import Main from "../hoc/Main";

const Revenue = () => {
  const navigate = useNavigate();
  return (
    <>
      <Main name="Revenue">
        <div className="communityPage d-flex justify-content-between mt-3">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#monthly"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Monthly
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#yearly"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Yearly
              </button>
            </li>
          </ul>
          <button
            className="showgraph border-0"
            onClick={() => navigate("/showgraph")}
          >
            Show Graph
          </button>
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="monthly"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <RevenueMonthlyTable />
          </div>
          <div
            class="tab-pane fade"
            id="yearly"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <RevenueYearlyTable />
          </div>
        </div>
      </Main>
    </>
  );
};

export default Revenue;
