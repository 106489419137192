import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Search from "../components/Search";
import StressLevelTable from "../components/StressLevelTable";
import Main from "../hoc/Main";

const StressLevelManagement = () => {
  const [viewModel, setViewModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  return (
    <>
      <Main name="Stress Level Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Stress Level Management</h1>
          </Col>
          <Search />
        </div>
        <StressLevelTable />
      </Main>
    </>
  );
};

export default StressLevelManagement;
