import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import LectureReducer, { ChapterReducer } from "./IndexReducer";

export const reducers = combineReducers({
    login: LoginReducer,
    lecture:LectureReducer,
    chapter:ChapterReducer

})

