import React, { useRef, useState } from "react";
import Main from "../hoc/Main";
import { Col, Modal, Row } from "react-bootstrap";
import ReviewManagement from "../components/landing/ReviewManagement";
import ContactManagement from "../components/landing/ContactManagement";
import ExploreManagement from "../components/landing/ExploreManagement";
import BestForYou from "../components/landing/BestForYou";
import Working from "../components/landing/HowItWork";
import GetPlantBaseRecipes from "../components/landing/GetPlantBaseRecipes";
import SingleError from "../helper/SingleError";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getServerURL } from "../helper/envConfig";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import api from "../helper/api";
const reviewRequireField = ["review_image", "name", "description"];
const exploreRequireField = ["title", "description"];
const workingRequireField = ["description"];
const recipeRequireField = ["recipes_images", "title"];
const forYouRequireField = ["best_for_you_image", "description"];
const searchHideFiled = ["explore", "work", "foryou"]
let activeRequire;

const LandingManagement = () => {
  const serverURL = getServerURL();
  const fileInputRef = useRef(null);

  const [reviewModal, setReviewModal] = useState(false);
  const [exploreModal, setExploreModal] = useState(false);
  const [workModal, setWorkModal] = useState(false);
  const [recipesModal, setRecipesModal] = useState(false);
  const [forYouModal, setForYouModal] = useState(false);

  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const [activeTab, setActiveTab] = useState("explore");

  const [countExplore, setCountExplore] = useState(0)
  const [countWork, setCountWork] = useState(0)
  const [countForYou, setCountForYou] = useState(0)
  const [countRecipes, setCountRecipes] = useState(0)
  const [countReview, setCountReview] = useState(0)

  const handleTabChange = (tabName) => {
    setSearch("");
    setSearchTemp("")
    setTemp();
    setState({})
    setActiveTab(tabName);
  };

  const addModalOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({});
    setSelectedImage(null);
    if (activeTab === "review") {
      setReviewModal(true);
      activeRequire = reviewRequireField;
    } else if (activeTab === "explore") {
      setExploreModal(true);
      activeRequire = exploreRequireField;
    } else if (activeTab === "work") {
      setWorkModal(true);
      activeRequire = workingRequireField
    } else if (activeTab === "recipes") {
      setRecipesModal(true);
      activeRequire = recipeRequireField;
    } else if (activeTab === "foryou") {
      setForYouModal(true);
      activeRequire = forYouRequireField;
    }
  };

  const addModalCloseFun = () => {
    if (activeTab === "review") {
      setReviewModal(false);
    } else if (activeTab === "explore") {
      setExploreModal(false);
    } else if (activeTab === "work") {
      setWorkModal(false);
    } else if (activeTab === "recipes") {
      setRecipesModal(false);
    } else if (activeTab === "foryou") {
      setForYouModal(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // if Enter key is pressed, submit the form
      setSubmitCount(1);

      if (activeTab === "review") {
        addReview();
      } else if (activeTab === "explore") {
        addExplore();
      } else if (activeTab === "work") {
        addWorking()
      } else if (activeTab === "recipes") {
        addRecipes();
      } else if (activeTab === "foryou") {
        addBestForYou();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, activeRequire);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, activeRequire);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const disableAutocomplete = (e) => {
    e.target.setAttribute("autocomplete", "off");
  };

  const addReview = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, reviewRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "review_create");
      formData.append("name", state?.name);
      formData.append("description", state?.description);
      formData.append("review_image", state?.review_image);
      try {
        api
          .postWithToken(`${serverURL}landing-review-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Review created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const addExplore = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, exploreRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "explore_create");
      formData.append("title", state?.title);
      formData.append("description", state?.description);
      try {
        api
          .postWithToken(`${serverURL}landing-review-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Review created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const addWorking = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, workingRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "working_create");
      formData.append("description", state?.description);
      try {
        api
          .postWithToken(`${serverURL}landing-page-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Review created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const addRecipes = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, recipeRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "recipes_create");
      formData.append("title", state?.title);
      formData.append("recipes_images", state?.recipes_images);
      try {
        api
          .postWithToken(`${serverURL}landing-page-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Recipe created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const addBestForYou = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, forYouRequireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "for_you_create");
      // formData.append("title", state?.title);
      formData.append("description", state?.description);
      formData.append("best_for_you_image", state?.best_for_you_image);
      try {
        api
          .postWithToken(`${serverURL}landing-page-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModalCloseFun();
              setTemp(true);
              setSelectedImage("");
              setState({});
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("Best For You created successfully");
            }
          })
          .catch((e) => {
            if (e?.response?.data?.status === 0) {
              addModalCloseFun()
            }
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Main name="Landing Management">
        <div className="communityPage d-sm-block d-md-flex d-lg-flex justify-content-between mt-3">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">

            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-explore-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-explore"
                type="button"
                role="tab"
                aria-controls="pills-explore"
                aria-selected="false"
                onClick={() => handleTabChange("explore")}
              >
                Explore
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-how-work-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-how-work"
                type="button"
                role="tab"
                aria-controls="pills-how-work"
                aria-selected="false"
                onClick={() => handleTabChange("work")}
              >
                How it work
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-get-plant-base-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-get-plant-base"
                type="button"
                role="tab"
                aria-controls="pills-get-plant-base"
                aria-selected="false"
                onClick={() => handleTabChange("recipes")}
              >
                Recipes
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-best-for-you-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-best-for-you"
                type="button"
                role="tab"
                aria-controls="pills-best-for-you"
                aria-selected="false"
                onClick={() => handleTabChange("foryou")}
              >
                For You
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-review-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-review"
                type="button"
                role="tab"
                aria-controls="pills-review"
                aria-selected="true"
                onClick={() => handleTabChange("review")}
              >
                Review Management
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                onClick={() => handleTabChange("contact")}
              >
                Contact Management
              </button>
            </li>

          </ul>

          <Col md={4} lg={4} xl={4} sm={12} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3 justify-content-end">
              {!Boolean(searchHideFiled?.includes(activeTab)) &&
                <div className="form-control d-flex align-items-center px-3">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                      fill="black"
                    />
                  </svg>
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Enter search text here"
                    type="text"
                    name="search"
                    value={search || ""}
                    autoComplete="off"
                    onFocus={disableAutocomplete}
                    onChange={(e) => {
                      setSearch(e?.target?.value);
                      setSearchTemp(true);
                    }}
                  />
                </div>
              }
              {Boolean(activeTab !== "contact") &&
                (Boolean(activeTab === "foryou" && countForYou === 0) ||
                  Boolean(activeTab === "work" && countWork === 0) ||
                  Boolean(activeTab === "explore" && countExplore === 0) ||
                  Boolean(activeTab === "review" && countReview === 0) ||
                  Boolean(activeTab === "recipes" && countRecipes === 0)) &&
                <div onClick={() => addModalOpenFun()} className="cursor-pointer">
                  <img src="/images/svg/add.svg" className="add-btn" alt="" />
                </div>}
            </div>
          </Col>
        </div>
        <div className="tab-content mt-2" id="pills-tabContent">
          <div
            className="tab-pane fade show"
            id="pills-review"
            role="tabpanel"
            aria-labelledby="pills-review-tab"
          >
            {Boolean(activeTab === "review") && <ReviewManagement temp={temp} search={search} searchTemp={searchTemp} />}
          </div>
          <div
            className="tab-pane fade show"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            {Boolean(activeTab === "contact") && <ContactManagement temp={temp} search={search} searchTemp={searchTemp} />}
          </div>
          <div
            className="tab-pane fade show active"
            id="pills-explore"
            role="tabpanel"
            aria-labelledby="pills-explore-tab"
          >
            {Boolean(activeTab === "explore") && <ExploreManagement temp={temp} search={search} searchTemp={searchTemp} setCountExplore={setCountExplore} />}
          </div>
          <div
            className="tab-pane fade show "
            id="pills-how-work"
            role="tabpanel"
            aria-labelledby="pills-how-work-tab"
          >
            {Boolean(activeTab === "work") && <Working temp={temp} search={search} searchTemp={searchTemp} setCountWork={setCountWork} />}
          </div>
          <div
            className="tab-pane fade show "
            id="pills-get-plant-base"
            role="tabpanel"
            aria-labelledby="pills-get-plant-base-tab"
          >
            {Boolean(activeTab === "recipes") && <GetPlantBaseRecipes temp={temp} search={search} searchTemp={searchTemp} setCountRecipes={setCountRecipes} />}
          </div>
          <div
            className="tab-pane fade show "
            id="pills-best-for-you"
            role="tabpanel"
            aria-labelledby="pills-best-for-you-tab"
          >
            {Boolean(activeTab === "foryou") && <BestForYou temp={temp} search={search} searchTemp={searchTemp} setCountForYou={setCountForYou} />}
          </div>
        </div>
      </Main>

      {/* add review modal */}
      <Modal
        size="lg"
        show={reviewModal}
        onHide={addModalCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Add Review Management
          </Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>

        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="review_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    state?.review_image
                      ? URL.createObjectURL(state?.review_image)
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  alt="review_image"
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : state?.review_image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                />
              </div>

              <input
                type="file"
                // accept="image/*"
                accept=".jpg, .jpeg, .png"
                name="review_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.review_image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.review_image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="name">
                Name <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter review title here"
                type="text"
                name="name"
                value={state?.name || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.name} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter review description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              // onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex  justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addReview();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* add explore modal */}
      <Modal
        size="lg"
        show={exploreModal}
        onHide={addModalCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Explore</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label>
                Explore Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter explore title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label>Explore Description <span className="star">*</span></label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter explore description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                addExplore();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* add how it work modal */}
      <Modal
        size="lg"
        show={workModal}
        onHide={addModalCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Working</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter how it work description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                addWorking();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* add recipes modal */}
      <Modal
        size="lg"
        show={recipesModal}
        onHide={addModalCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Add Recipes
          </Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>

        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="recipes_images">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    state?.recipes_images
                      ? URL.createObjectURL(state?.recipes_images)
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  alt="recipes_images"
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : state?.recipes_images
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                />
              </div>

              <input
                type="file"
                // accept="image/*"
                accept=".jpg, .jpeg, .png"
                name="recipes_images"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.recipes_images) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.recipes_images} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter recipes title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>

          </Row>
          <div className="d-flex  justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addRecipes();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* add for you modal */}
      <Modal
        size="lg"
        show={forYouModal}
        onHide={addModalCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add For You</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>

        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="best_for_you_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    state?.best_for_you_image
                      ? URL.createObjectURL(state?.best_for_you_image)
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  alt="for_you_image"
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : state?.best_for_you_image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                />
              </div>

              <input
                type="file"
                // accept="image/*"
                accept=".jpg, .jpeg, .png"
                name="best_for_you_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.best_for_you_image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.best_for_you_image} />
              </div>
            )}
          </Row>
          <Row>
            {/* <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter best for you title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col> */}
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter best for you description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex  justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addBestForYou();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LandingManagement;
