import React from "react";
import Main from "../hoc/Main";

const Dashboard = () => {

  return (
    <>
      <Main name="Dashboard">
        <div className="pb-3">
          <h1 className="title mb-0">Dashboard</h1>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div
              className="content d-flex flex-column p-3"
              style={{ backgroundColor: "#E6B8DF" }}
            >
              <span className="firsttext">Total user</span>
              <span className="secondtext">200</span>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div
              className="content d-flex flex-column p-3"
              style={{ backgroundColor: "#94E0E0" }}
            >
              <span className="firsttext">Total Learning</span>
              <span className="secondtext">1203</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div
              className="content d-flex flex-column p-3"
              style={{ backgroundColor: "#EECFAA" }}
            >
              <span className="firsttext">Total Income</span>
              <span className="secondtext">890562/-</span>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
            <div
              className="content d-flex flex-column p-3"
              style={{ backgroundColor: "#EFADAD" }}
            >
              <span className="firsttext">Total Active user</span>
              <span className="secondtext">200</span>
            </div>
          </div>
        </div>

        <div className="card mt-3 p-3">
          <p className="cardHeading pb-0">Latest Learning</p>

          <div className="row ">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
              <div className="card latest-learning border-0">
                <img src="/images/png/image1.png" alt="" />
                <div className="text-box">
                  <h3 className="text-truncate">The Plant Based Switch</h3>
                  <p className="text-truncate">Go Plant-Based in 6 Weeks</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
              <div className="card latest-learning border-0">
                <img src="/images/png/image2.png" alt="" />
                <div className="text-box">
                  <h3 className="text-truncate">The Plant Based Switch</h3>
                  <p className="text-truncate">Go Plant-Based in 6 Weeks</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
              <div className="card latest-learning border-0">
                <img src="/images/png/image3.png" alt="" />
                <div className="text-box">
                  <h3>The Plant Based Switch</h3>
                  <p>Go Plant-Based in 6 Weeks</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3">
              <div className="card latest-learning border-0">
                <img src="/images/png/image4.png" alt="" />
                <div className="text-box">
                  <h3>The Plant Based Switch</h3>
                  <p>Go Plant-Based in 6 Weeks</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 p-3 mb-4">
          <p className="cardHeading pb-0">Recent Join User</p>

          <div className="row ">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3">
              <div className="user-layout p-3">
                <img src="/images/png/user.png" className="recent-user" />
                <span className="userText">Sophia Pelekos</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3">
              <div className="user-layout p-3">
                <img src="/images/png/user.png" className="recent-user" />
                <span className="userText">Sophia Pelekos</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3">
              <div className="user-layout p-3">
                <img src="/images/png/user.png" className="recent-user" />
                <span className="userText">Sophia Pelekos</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3">
              <div className="user-layout p-3">
                <img src="/images/png/user.png" className="recent-user" />
                <span className="userText">Sophia Pelekos</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3">
              <div className="user-layout p-3">
                <img src="/images/png/user.png" className="recent-user" />
                <span className="userText">Sophia Pelekos</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4 mt-3">
              <div className="user-layout p-3">
                <img src="/images/png/user.png" className="recent-user" />
                <span className="userText">Sophia Pelekos</span>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};

export default Dashboard;
